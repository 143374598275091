import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
    const {permission_deny} = props;
    if(permission_deny){
        return (
            <div>
                <section className="mt-5 hero-section ptb-100 h-100" >
                    <div className="container">
                        <div className="row align-items-center justify-content-center mt-5 pt-5 ">
                            <div className="col-md-9 col-lg-7">
                                <div className="error-content text-center text-black ">
                                    {/* <div className="notfound-404"><h1 className="text-danger font-weight-bolder ">404</h1></div> */}
                                    <FontAwesomeIcon icon={faLock} size="3x" className='mb-3'/>
                                    <h2 className="font-weight-bolder text-black">Permission Denied.</h2>
                                    <p>You don't have permmision to access this page contact your admin. </p>
                                    <Link to='/dashboard'  className="btn btn-primary btn-lg">Go to Dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }else{
        return (
            <div>
                <section className="mt-5 hero-section ptb-100 h-100" >
                    <div className="container">
                        <div className="row align-items-center justify-content-center mt-5 pt-5 ">
                            <div className="col-md-9 col-lg-7">
                                <div className="error-content text-center text-black ">
                                    <div className="notfound-404"><h1 className="text-danger font-weight-bolder ">404</h1></div>
                                    <h3 className="text-black">Sorry, something went wrong</h3>
                                    <p>The page you are looking for might have been removed, had its name changed or is temporarily
                                        unavailable.</p>
                                        <Link to='/'  className="btn btn-primary btn-lg">Go to Homepage</Link>
                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    
}