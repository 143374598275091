import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {
  CandidateMenu,
  MentorMenu,
  CompanyMenu,
  UniversityMenu,
  ManagerMenu,
  StaffMenu,
  SchoolMenu
} from "./navigationMenu";
import { connect } from "react-redux";
import SubMenuLinks from "./SubMenu";
import { getImage } from "../../config/functions";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { userSignOut } from "../../actions/auth";

const SideBar = ({
  isOpen,
  toggle,
  authenticated,
  candidate,
  coach,
  company,
  location,
  history
}) => {
  const [hovered, setHovered] = React.useState(false);
  const toggleHover = () => setHovered(!hovered);

  var dispatch = useDispatch();
  var userMenu = [];

  var logoLink = "/";
  var userImg = "";
  var userName = "";
  var userPosition = "";

  if (authenticated && candidate) {
    logoLink = "/candidate/dashboard";
    userMenu = CandidateMenu;
    if (candidate && candidate.total_meetings < 1) {
      userMenu = userMenu.filter(o => o.target != "/candidate/meetings");
    }
    if (candidate && candidate.classes && candidate.classes.length < 1) {
      userMenu = userMenu.filter(o => o.target != "/candidate/cohorts");
    }
    if (candidate && candidate.total_notes < 1) {
      userMenu = userMenu.filter(o => o.target != "/candidate/notes");
    }
    if (candidate && candidate.total_feedbacks < 1) {
      userMenu = userMenu.filter(o => o.target != "/candidate/feedback");
    }

    userImg = getImage(candidate.picture, "user");
    userName = candidate.first_name + " " + candidate.last_name;
    userPosition = candidate.position;
  } else if (authenticated && coach) {
    logoLink = "/mentor/dashboard";
    userImg = getImage(coach.picture, "user");
    userName = coach.first_name + " " + coach.last_name;
    userPosition = coach.position;
    if (coach && coach.is_manager) {
      if (coach && coach.type == "university") {
        userMenu = StaffMenu;
      } else {
        userMenu = ManagerMenu;
      }
    } else {
      userMenu = MentorMenu;
    }
  } else if (authenticated && company) {
    logoLink = "/company/dashboard";
    userMenu = company.type == "university" ? UniversityMenu : CompanyMenu;
    userImg = getImage(company.picture, "company");
    userName = company.name;
    userPosition = company.location;
    // userType = user && user.type?user.type:'Company';
    console.log(company);
    if (company && company.type == "university") {
      userName = company.role == "admin" ? company.name : company.coach_name;
      userMenu = company.is_manager ? StaffMenu : UniversityMenu;
    } else if (company && company.type == "school") {
      userName = company.name;
      userMenu = SchoolMenu;
    } else {
      userName = company.role == "admin" ? company.name : company.coach_name;
      userMenu = company.is_manager ? ManagerMenu : CompanyMenu;
    }
  }

  var excludePaths = [
    "/",
    "/for_mentors",
    "/for_companies",
    "/what_is_shadowing",
    "/how_shadowing_works",
    "/privacy_policy",
    "/terms",
    "/what_is_shadowing",
    "/signup",
    "/login",
    "/about-us"
  ];
  if (excludePaths.includes(location.pathname)) {
    return null;
  }

  const logout = () => {
    dispatch(
      userSignOut(function(err, res) {
        history.push("/login");
      })
    );
  };
  console.log(location.pathname);
  var includeRoute = ["/candidate/", "/candidate/dashboard"];
  return (
    <BrowserView>
      <ProSidebar
        // collapsed={includeRoute.includes(location.pathname)?false:!hovered}
        collapsed={!hovered}
        // toggled
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className={hovered ? "hovered" : ""}
        onOpenChange={d => {
          console.log(d);
        }}
      >
        <SidebarHeader>
          <div className="user-block">
            <div className="avatar">
              {userImg ? (
                <a href={"/candidate/profile"}>
                  <img
                    src={userImg}
                    alt="Shadowing Logo"
                    className="avatar-img"
                  />
                </a>
              ) : null}
            </div>
            {hovered ? (
              <div className="user-info">
                <div className="user-name">
                  <a href={"/candidate/profile"} style={{ color: "blue" }}>
                    {userName}
                  </a>
                </div>
                <div className="user-position">{userPosition}</div>
              </div>
            ) : null}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square" popperArrow>
            {userMenu &&
              userMenu.map((menu, i) => {
                if (menu && menu.subMenu) {
                  return (
                    <SubMenuLinks
                      title={menu.title}
                      icon={menu.icon ? menu.icon : faHome}
                      items={menu.subMenu}
                      location={location}
                    />
                  );
                } else {
                  var active = location.pathname == menu.target;
                  if (menu.target.includes("/dashboard")) {
                    var pathname = location.pathname
                      .replace("/candidate", "")
                      .replace("/coach", "")
                      .replace("/company", "");
                    // console.log(pathname);
                    // console.log('pathname');
                    if (
                      pathname == "/" ||
                      pathname == "/dashboard" ||
                      pathname == ""
                    ) {
                      active = true;
                    }
                  }
                  return (
                    <MenuItem
                      active={active}
                      title={menu.title}
                      icon={
                        <FontAwesomeIcon
                          icon={menu.icon ? menu.icon : faBriefcase}
                        />
                      }
                    >
                      {menu.title}
                      <Link to={menu.target} />
                    </MenuItem>
                  );
                }
              })}
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px"
            }}
          >
            <Button block className="sidebar-btn" onClick={logout}>
              <FontAwesomeIcon icon={faPowerOff} />
              <span>Logout</span>
            </Button>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </BrowserView>
  );
};

const mapStateToProps = state => {
  return {
    candidate: state.Auth.candidate,
    coach: state.Auth.coach,
    company: state.Auth.company,
    authenticated: state.Auth.authenticated
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideBar)
);
