import {
  faEvernote,
  faLinkedinIn,
  faLinkedin,
  faMeetup
} from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faStickyNote,
  faBuilding,
  faChalkboardTeacher,
  faCommentDots,
  faEnvelope,
  faFileAlt,
  faFilePdf,
  faPeopleArrows,
  faRunning,
  faShoePrints,
  faSignInAlt,
  faStar,
  faTachometerAlt,
  faUserAlt,
  faUserCircle,
  faUsers,
  faUserTie,
  faVials,
  faVideo,
  faInbox,
  faUserTag,
  faGraduationCap,
  faChalkboard,
  faClipboardCheck,
  faClipboardList,
  faCheckDouble,
  faSatelliteDish,
  faStreetView,
  faUserCog,
  faFile,
  faQuestionCircle,
  faChartLine,
  faListAlt,
  faList,
  faEllipsisH,
  faChartBar,
  faChartPie,
  faPlay,
  faDolly,
  faUserCheck,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";

export const CandidateMenu = [
  {
    title: "Dashboard",
    target: "/candidate/dashboard",
    icon: faTachometerAlt
  },

  ...(isMobile
    ? [
        {
          title: "Profile",
          target: "/candidate/profile",
          icon: faUser
        }
      ]
    : [
        {
          title: "Career Paths",
          target: "/candidate/career_paths",
          icon: faChartLine
        },
        {
          title: "Role Discovery",
          target: "/candidate/role_discovery",
          icon: faUserCheck
        },
        {
          title: "Resume Scorecard",
          target: "/candidate/resume_scorecard",
          icon: faStar
        },
        {
          title: "Recruiter Chat",
          target: "/candidate/recruiter_chat",
          icon: faEllipsisH
        },
        {
          title: "Start Practice",
          target: "/interview",
          icon: faPlay
        },
        {
          title: "Interview Analytics",
          target: "/candidate/interview_analytics",
          icon: faChartPie
        },
        {
          title: "Question Bank",
          target: "/interview/question_bank",
          icon: faQuestionCircle
        },
        {
          title: "BYOQ",
          target: "/question-practice",
          icon: faDolly
        }
      ])
  // {
  //     title:"Aptitude Test",
  //     target:"/candidate/aptitude_test",
  //     icon:faList
  // },
  // {
  //     title:"Key Values",
  //     target:"/candidate/companies_key_values",
  //     icon:faKey
  // }

  // {
  //     title:"AI Coach",
  //     // target:"/candidate/recruiter_chat",
  //     icon:faRunning,
  //     subMenu:[
  //         {
  //             title:"AI Recruiter",
  //             target:"/candidate/recruiter_chat",
  //             icon:faCommentDots
  //         },
  //         {
  //             title:"Role Discovery",
  //             target:"/candidate/role_discovery",
  //             icon:faBuilding
  //         },
  //         {
  //             title:"Career Pathway",
  //             target:"/flowchart/flow-chart",
  //             icon:faBuilding
  //         },

  //     ]
  // },
  // {
  //     title:"Recommended Jobs",
  //     target:"/candidate/recommended_jobs",
  //     icon:faBuilding
  // },
  // {
  //     title:"Resume",
  //     target:"/candidate/resume_scorecard",
  //     icon: faRunning
  // },

  // {
  //     title:"Resume AI",
  //     // target:"/candidate/resume_scorecard",
  //     icon:faRunning,
  //     subMenu:[
  //         {
  //             title:"Analyze Resume",
  //             target:"/candidate/resume_scorecard",
  //             icon:faCommentDots
  //         },
  //         {
  //             title:"Create Resume",
  //             target:"/candidate/create_resume",
  //             icon:faFile
  //         },
  //     ]
  // },
  // {
  //     title:"Resume AI",
  //     target:"/candidate/resume_scorecard",
  //     icon: faFile
  // },
  // ,
  // {
  //     title:"Cover Letter AI",
  //     target:"/candidate/cover-letter",
  //     icon: faEnvelope
  // }
  // ,
  // {
  //     title:"LinkedIn AI",
  //     // target:"/candidate/recruiter_chat",
  //     icon:faRunning,
  //     subMenu:[
  //         {
  //             title:"Linkedin Headline",
  //             target:"/candidate/linkedin_headline",
  //             icon: faLinkedinIn
  //         },
  //         {
  //             title:"Linkedin Summary",
  //             target:"/candidate/linkedin_summary",
  //             icon: faLinkedin
  //         },
  //     ]
  // },
  // ,

  // {
  //     title:"Linkedin Headline",
  //     target:"/candidate/linkedin_headline",
  //     icon: faLinkedinIn
  // },
  // {
  //     title:"Linkedin Summary",
  //     target:"/candidate/linkedin_summary",
  //     icon: faLinkedin
  // },
  //     title:"Tests",
  //     target:"/candidate/tests",
  //     icon:faVials
  // },
  // {
  //     title:"Connections",
  //     target:"/candidate/connections",
  //     icon:faEnvelope
  // },
  // {
  //     title:"Meetings",
  //     target:"/candidate/meetings",
  //     icon:faMeetup
  // },
  // {
  //     title:"Mentors",
  //     target:"/candidate/mentors",
  //     icon:faChalkboardTeacher
  // },
  // {
  //     title:"Companies",
  //     target:"/candidate/companies",
  //     icon:faBuilding
  // },
  // {
  //     title:"Communities",
  //     target:"/candidate/communities",
  //     icon:faUsers
  // },
  // {
  //     title:"Suggested AI Practices",
  //     target:"/candidate/recommended_jobs",
  //     icon:faBuilding
  // },

  // {
  //     // title:"Jobs",
  //     title:"Shared Practices",
  //     target:"/candidate/hirings",
  //     icon: faRunning
  // },
  // {
  //     title:"My Courses",
  //     target:"/candidate/courses",
  //     icon:faGraduationCap
  // },

  // {
  //     title:"Messages",
  //     target:"/candidate/messages",
  //     icon: faInbox
  // }
  // {
  //     title:"Meetings",
  //     target:"/candidate/meetings",
  //     icon:faMeetup
  // },
  // {
  //     title:"Cohorts",
  //     target:"/candidate/cohorts",
  //     icon:faPeopleArrows
  // },
  // {
  //     title:"Notes",
  //     target:"/candidate/notes",
  //     icon:faStickyNote
  // },
  // {
  //     title:"Feedback",
  //     target:"/candidate/feedback",
  //     icon:faStar // },
];

export const MentorMenu = [
  {
    title: "Dashboard",
    target: "/mentor/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Courses",
    target: "/mentor/courses",
    icon: faTachometerAlt
  },
  {
    title: "Mirror Rooms",
    target: "/mentor/mirror-rooms",
    icon: faVideo
  },
  {
    title: "Trainings",
    target: "/mentor/trainings",
    icon: faUserCog
  },
  // {
  //     title:"Connections",
  //     target:"/mentor/connections",
  //     icon:faEnvelope
  // },
  {
    title: "Tests",
    target: "/mentor/tests",
    icon: faVials
  },
  {
    title: "Candidates",
    target: "/mentor/candidates",
    icon: faUsers
  },
  // {
  //     title:"Meetings",
  //     target:"/mentor/meetings",
  //     icon:faMeetup
  // },
  // {
  //     title:"Cohorts",
  //     target:"/mentor/cohorts",
  //     icon:faPeopleArrows
  // },
  {
    title: "Notes",
    target: "/mentor/notes",
    icon: faStickyNote
  },
  {
    title: "Feedback",
    target: "/mentor/feedback",
    icon: faStar
  },
  {
    title: "Messages",
    target: "/mentor/messages",
    icon: faInbox
  }
];
export const ManagerMenu = [
  {
    title: "Dashboard",
    target: "/company/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Interview Rooms",
    target: "/company/mirror-rooms",
    icon: faVideo
  },
  {
    title: "Courses",
    target: "/company/courses",
    icon: faGraduationCap
  },
  {
    title: "Rooms",
    target: "/company/rooms",
    icon: faVideo
  },
  {
    title: "Candidates",
    target: "/company/candidates",
    icon: faVials
  },
  {
    title: "Hirings",
    target: "/company/hirings",
    icon: faRunning,
    subMenu: [
      {
        title: "Managers",
        target: "/company/managers",
        icon: faVials
      },
      {
        title: "Departments",
        target: "/company/departments",
        icon: faBuilding
      },
      {
        title: "Positions",
        target: "/company/hirings",
        icon: faRunning
      },
      {
        title: "Tests",
        target: "/company/tests",
        icon: faVials
      }
    ]
  },
  {
    title: "Pre-Joining",
    target: "/company/prejoin/candidates",
    icon: faUserCircle,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/prejoin/candidates",
        icon: faUserCircle
      }
    ]
  },
  {
    title: "Post-Joining",
    target: "/company/onboarding/candidates",
    icon: faSignInAlt,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/onboarding/candidates",
        icon: faUserAlt
      },
      {
        title: "Steps",
        target: "/company/onboarding/steps",
        icon: faShoePrints
      },
      {
        title: "Forms",
        target: "/company/onboarding/forms",
        icon: faChalkboardTeacher
      }
      // {
      //     title:"Documents",
      //     target:"/company/onboarding/documents",
      //     icon:faFileAlt
      // },
    ]
  },
  {
    title: "Documents",
    target: "/company/onboarding/documents",
    icon: faFileAlt
  },
  {
    title: "Predefined Tests",
    target: "/company/practice-tests",
    icon: faBuilding
  }
];

export const CompanyMenu = [
  {
    title: "Dashboard",
    target: "/company/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Interview Rooms",
    target: "/company/mirror-rooms",
    icon: faVideo
  },
  {
    title: "Trainings",
    target: "/company/trainings",
    icon: faUserCog
  },

  // {
  //     title:"One to One Rooms",
  //     target:"/company/rooms",
  //     icon:faVideo
  // },
  // {
  //     title:"Candidates",
  //     target:"/company/candidates",
  //     icon:faVials
  // },
  {
    title: "Leaderboard",
    target: "/company/hiring-leaderboard",
    icon: faVials
  },
  {
    title: "Hirings",
    target: "/company/hirings",
    icon: faRunning,
    subMenu: [
      {
        title: "Managers",
        target: "/company/managers",
        icon: faVials
      },
      {
        title: "Departments",
        target: "/company/departments",
        icon: faBuilding
      },
      {
        title: "Positions",
        target: "/company/hirings",
        icon: faRunning
      },
      {
        title: "Tests",
        target: "/company/tests",
        icon: faVials
      }
    ]
  },
  {
    title: "Pre-Joining",
    target: "/company/prejoin/candidates",
    icon: faUserCircle,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/prejoin/candidates",
        icon: faUserCircle
      }
    ]
  },
  {
    title: "Post-Joining",
    target: "/company/onboarding/candidates",
    icon: faSignInAlt,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/onboarding/candidates",
        icon: faUserAlt
      },
      {
        title: "Steps",
        target: "/company/onboarding/steps",
        icon: faShoePrints
      },
      {
        title: "Forms",
        target: "/company/onboarding/forms",
        icon: faChalkboardTeacher
      }
      // {
      //     title:"Documents",
      //     target:"/company/onboarding/documents",
      //     icon:faFileAlt
      // },
    ]
  },
  {
    title: "Documents",
    target: "/company/onboarding/documents",
    icon: faFileAlt
  },
  {
    title: "Company Map",
    target: "/company/mapping",
    icon: faBuilding
  },
  {
    title: "Predefined Tests",
    target: "/company/practice-tests",
    icon: faBuilding
  },
  {
    title: "Messages",
    target: "/company/messages",
    icon: faInbox
  },
  {
    title: "Widget",
    target: "/company/widget",
    icon: faSatelliteDish
  }
];

export const UniversityMenu = [
  {
    title: "Dashboard",
    target: "/company/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Departments",
    target: "/company/departments",
    icon: faBuilding
  },
  {
    title: "Staffs",
    target: "/company/staffs",
    icon: faUserTag
  },
  // {
  //     title:"Trainings",
  //     target:"/company/trainings",
  //     icon:faUserCog
  // },
  {
    title: "Students",
    target: "/company/candidates",
    icon: faGraduationCap
  },
  // {
  //     title:"Meetings",
  //     target:"/company/meetings",
  //     icon:faMeetup
  // },
  {
    title: "Job Board",
    target: "/company/job_board",
    icon: faChalkboard
  },
  // {
  //     title:"Tests",
  //     target:"/company/tests",
  //     icon:faCheckDouble
  // },
  {
    title: "AI Practices",
    target: "/company/mirror-rooms",
    icon: faVideo
  },
  // {
  //     title:"Live sessions",
  //     target:"/company/events",
  //     icon:faSatelliteDish
  // },
  {
    title: "Resume",
    target: "/company/resume",
    icon: faFile
  },
  {
    title: "Cover Letter",
    target: "/company/cover-letter",
    icon: faEnvelope
  },
  {
    title: "Messages",
    target: "/company/messages",
    icon: faInbox
  },
  {
    title: "Reports",
    target: "/company/candidates/reports",
    icon: faVials
  }
  // {
  //     title:"Documents",
  //     target:"/company/onboarding/documents",
  //     icon:faFileAlt
  // },
  // {
  //     title:"Pre-Joining",
  //     target:"/company/prejoin/candidates",
  //     icon:faUserCircle,
  //     subMenu:[
  //         {
  //             title:"Candidates",
  //             target:"/company/prejoin/candidates",
  //             icon:faUserCircle,
  //         },
  //     ]
  // },
  // {
  //     title:"Post-Joining",
  //     target:"/company/onboarding/candidates",
  //     icon:faSignInAlt,
  //     subMenu:[
  //         {
  //             title:"Candidates",
  //             target:"/company/onboarding/candidates",
  //             icon:faUserAlt,
  //         },
  //         {
  //             title:"Steps",
  //             target:"/company/onboarding/steps",
  //             icon:faShoePrints
  //         },
  //         {
  //             title:"Forms",
  //             target:"/company/onboarding/forms",
  //             icon:faChalkboardTeacher
  //         },
  //         // {
  //         //     title:"Documents",
  //         //     target:"/company/onboarding/documents",
  //         //     icon:faFileAlt
  //         // },
  //     ]
  // },
  // {
  //     title:"University Map",
  //     target:"/company/mapping",
  //     icon:faStreetView
  // },
  // {
  //     title:"Predefined Tests",
  //     target:"/company/practice-tests",
  //     icon:faBuilding
  // },
];
export const StaffMenu = [
  {
    title: "Dashboard",
    target: "/company/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Departments",
    target: "/company/departments",
    icon: faBuilding
  },
  {
    title: "Staffs",
    target: "/company/staffs",
    icon: faUserTag
  },
  {
    title: "Students",
    target: "/company/candidates",
    icon: faGraduationCap
  },
  {
    title: "Meetings",
    target: "/company/meetings",
    icon: faMeetup
  },
  {
    title: "Job Board",
    target: "/company/job_board",
    icon: faChalkboard
  },
  {
    title: "Tests",
    target: "/company/tests",
    icon: faCheckDouble
  },
  {
    title: "Mirror Rooms",
    target: "/company/mirror-rooms",
    icon: faVideo
  },
  {
    title: "Live sessions",
    target: "/company/events",
    icon: faSatelliteDish
  },
  {
    title: "Messages",
    target: "/company/messages",
    icon: faInbox
  },
  {
    title: "Documents",
    target: "/company/onboarding/documents",
    icon: faFileAlt
  },
  {
    title: "Pre-Joining",
    target: "/company/prejoin/candidates",
    icon: faUserCircle,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/prejoin/candidates",
        icon: faUserCircle
      }
    ]
  },
  {
    title: "Post-Joining",
    target: "/company/onboarding/candidates",
    icon: faSignInAlt,
    subMenu: [
      {
        title: "Candidates",
        target: "/company/onboarding/candidates",
        icon: faUserAlt
      },
      {
        title: "Steps",
        target: "/company/onboarding/steps",
        icon: faShoePrints
      },
      {
        title: "Forms",
        target: "/company/onboarding/forms",
        icon: faChalkboardTeacher
      }
      // {
      //     title:"Documents",
      //     target:"/company/onboarding/documents",
      //     icon:faFileAlt
      // },
    ]
  },
  {
    title: "Predefined Tests",
    target: "/company/practice-tests",
    icon: faBuilding
  }
];
export const SchoolMenu = [
  {
    title: "Dashboard",
    target: "/company/dashboard",
    icon: faTachometerAlt
  },
  {
    title: "Departments",
    target: "/company/departments",
    icon: faBuilding
  },
  {
    title: "Staffs",
    target: "/company/staffs",
    icon: faUserTag
  },
  {
    title: "Students",
    target: "/company/candidates",
    icon: faGraduationCap
  }
];
