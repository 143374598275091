import * as CommonAction  from '../constants/types.js';
import cookie from 'react-cookies';
const Auth = (state={}, action) => {
    switch (action.type) {
        case CommonAction.GET_COACH_INVITATIONS: {
            return {
                ...state, invitations : action.payload,
            };
        }
        case CommonAction.GET_COACH_MEMBERS: {
            return {
                ...state, members : action.payload,
            };
        }
        case CommonAction.GET_COACH_MEMBER_INFO: {
            return {
                ...state, member_info : action.payload,
            };
        }
        case CommonAction.GET_COACH_MEMBER_TASKS: {
            return {
                ...state, member_tasks : action.payload,
            };
        }
        case CommonAction.GET_COACH_MEETING_SCHEDULE: {
            return {
                ...state, meeting_schedule : action.payload,
            };
        }
        case CommonAction.GET_COACH_CLASSES: {
            return {
                ...state, coach_classes : action.payload,
            };
        }
        case CommonAction.GET_COACH_CLASS_INFO: {
            return {
                ...state, class_info : action.payload,
            };
        }
        case CommonAction.GET_COACH_CLASS_TASKS: {
            return {
                ...state, class_tasks : action.payload,
            };
        }
        case CommonAction.GET_COACH_TASK_INFO: {
            return {
                ...state, task_info : action.payload,
            };
        }
        
        case CommonAction.GET_COACH_CLASS_SCHEDULE: {
            return {
                ...state, class_schedule : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATES: {
            return {
                ...state, candidates : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_COMPANY: {
            return {
                ...state, companies : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_SKILLS: {
            return {
                ...state, skills : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_POSITION: {
            return {
                ...state, positions : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_LANGUAGE: {
            return {
                ...state, languages : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_COLLAGE:{
            return {
                ...state, collages : action.payload,
            };
        }
        case CommonAction.GET_COACH_CANDIDATE_DEGREE:{
            return {
                ...state, degrees : action.payload,
            };
        }
        case CommonAction.GET_COACH_HIRINGS: {
            return {
                ...state, hirings : action.payload,
            };
        }
        case CommonAction.GET_COACH_PACKAGES:{
            return {
                ...state,packages:action.payload,
            }
        }
        case CommonAction.GET_COACH_FEEDBAKFORMS:{
            return {
                ...state, feedback_forms :action.payload,
            }
        }
        case CommonAction.GET_CLASS_FEEDBACK_FORMS:{
            return {
                ...state, class_feedback_forms :action.payload,
            }
        }
        case CommonAction.GET_CLASS_FEEDBAKCS:{
            return {
                ...state, class_feedbacks :action.payload,
            }
        }
        case CommonAction.GET_FEEDBACK_INFO:{
            return {
                ...state, feedback_info :action.payload,
            }
        }
        case CommonAction.GET_COACH_FFORMS:{
            return {
                ...state, coach_fform :action.payload,
            }
        }
        case CommonAction.TEST_FORM:{
            return {
                ...state, test_form : {...state.test_form,...action.payload},
            }
        }
        case CommonAction.GET_COACH_TEST:{
            return {
                ...state, coach_tests : action.payload,
            }
        }
        case CommonAction.GET_COACH_TEST_INFO:{
            return {
                ...state, coach_test_info : action.payload,
            }
        }
        case CommonAction.GET_TEST_USER_RESULT:{
            return {
                ...state, test_user_result : action.payload,
            }
        }
        case CommonAction.GET_CLASS_TESTS:{
            return {
                ...state, class_tests : action.payload,
            }
        }
        case CommonAction.HIRING_FORM:{
            return {
                ...state, hiring_form : action.payload,
            }
        }
        case CommonAction.GET_COACH_HIRING_INFO:{
            return {
                ...state, coach_hiring_info : action.payload,
            }
        }
        case CommonAction.GET_COACH_HIRING_CANDIDATES:{
            return {
                ...state, coach_hiring_candidates : action.payload,
            }
        }
        case CommonAction.GET_COACH_ROUND_HIRINGS:{
            return {
                ...state, coach_round_hirings : action.payload,
            }
        }
        case CommonAction.SET_TEST_PAGE:{
            return {
                ...state, test_page : action.payload,
            }
        }
        case CommonAction.SET_TEST_TYPE:{
            return {
                ...state, test_type : action.payload,
            }
        }
        case CommonAction.COACH_HIRINGS_ROUNDS:{
            return {
                ...state, hiring_rounds : action.payload,
            }
        }
        case CommonAction.GET_COMPANY_PREJOINING_CANDIDATES:{
            return {
                ...state, company_pj_candidates : action.payload,
            }
        }
        case CommonAction.GET_PREJOINING_CLASSES:{
            return {
                ...state, pj_classes : action.payload,
            }
        }
        case CommonAction.GET_COACH_DOCUMENTS:{
            return {
                ...state, pj_coach_documents : action.payload,
            }
        }
        case CommonAction.GET_COACH_DOCUMENT_GROUPS:{
            return {
                ...state, pj_coach_document_groups : action.payload,
            }
        }
        case CommonAction.GET_COMPANY_FORMS:{
            return {
                ...state, company_forms : action.payload,
            }
        }
        case CommonAction.GET_ONBOARDING_STEPS:{
            return {
                ...state, onboarding_steps : action.payload,
            }
        }
        case CommonAction.GET_COMPANY_FORM_INFO:{
            return {
                ...state, company_form_info : action.payload,
            }
        }
        case CommonAction.GET_ALL_GIVEN_FEEDBAKS:{
            return {
                ...state, all_given_feedbacks : action.payload,
            }
        }
        case CommonAction.GET_MENTOR_OPEN_ROOMS:{
            return {
                ...state, open_rooms : action.payload,
            }
        }
        case CommonAction.GET_COACH_MESSAGE_USER:{
            return {
                ...state, coach_msg_users : action.payload,
            }
        }
        case CommonAction.GET_USER_MESSAGE:{
            return {
                ...state, coach_user_msgs : action.payload,
            }
        }
        case CommonAction.SET_POSITION_FORM_TYPE:{
            return {
                ...state, get_form_type : action.payload,
            }
        }
        case CommonAction.GET_MIRROR_ROOM_QUESTION:{
            return {
                ...state, mirror_room_questions : action.payload,
            }
        }
        case CommonAction.GET_COMPANY_MIRROR_ROOMS:{
            return {
                ...state, company_mirror_rooms : action.payload,
            }
        }
        case CommonAction.GET_SUGGESTED_HIRING:{
            return {
                ...state, suggested_hirings : action.payload,
            }
        }
        case CommonAction.COACH_DEPARTMENTS:{
            return {
                ...state, coach_departments : action.payload,
            }
        }
        case CommonAction.COACH_STUDENTS:{
            return {
                ...state, coach_students : action.payload,
            }
        }
        case CommonAction.UNIVERSITY_HIRINGS:{
            return {
                ...state, university_hirings : action.payload,
            }
        }
        case CommonAction.UNIVERSITY_TESTS:{
            return {
                ...state, university_tests : action.payload,
            }
        }
        case CommonAction.GET_COACH_HIRING_ROOMS:{
            return {
                ...state, hiring_rooms : action.payload,
            }
        }
        case CommonAction.GET_ASSIGNED_HIRINGS:{
            return {
                ...state, assigned_hirings : action.payload,
            }
        }
        case CommonAction.GET_HIRINGS_COACH_MEETINGS:{
            return {
                ...state, hirings_meetings : action.payload,
            }
        }
        case CommonAction.GET_COACH_FILTERED_CANDIDATES:{
            return {
                ...state, filtered_candidates : action.payload,
            }
        }
        case CommonAction.GET_COMPANY_CANDIDATE_INFO:{
            return {
                ...state, c_candidate_info : action.payload,
            }
        }
        case CommonAction.GET_COACH_COURSES:{
            return {
                ...state, coach_courses : action.payload,
            }
        }
        case CommonAction.GET_COACH_MIRROR_ROOM_INFO:{
            return {
                ...state, coach_mirror_room_info : action.payload,
            }
        }
        case CommonAction.GET_COACH_ASSIGNED_COURSES:{
            return {
                ...state, assigned_courses : action.payload,
            }
        }
        case CommonAction.GET_COACH_ASSIGNED_COURSE_ITEM:{
            return {
                ...state, assigned_course_item : action.payload,
            }
        }
        case CommonAction.GET_COACH_MIRROR_ROOMS:{
            return {
                ...state, coach_practice_rooms : action.payload,
            }
        }
        case CommonAction.GET_COACH_TRAININGS:{
            return {
                ...state, coach_trainings : action.payload,
            }
        }
        case CommonAction.GET_COACH_TRAINING_INFO:{
            return {
                ...state, coach_training_info : action.payload,
            }
        }
        case CommonAction.GET_UNIVERSITY_CANDIDATES: {
            return {
                ...state, uni_candidates : action.payload,
            };
        }
        case CommonAction.GET_CANDIDATE_PRACTICE_ANALYSIS:{
            return {
                ...state, c_candidate_analysis : action.payload,
            }
        }

        default:
            return state;
    }

}

export default Auth;