import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userSignOut, getCandidateProfile } from "../../actions/auth";
import { withRouter } from "react-router-dom";
import { NavLink } from "reactstrap";
import {
  Alert,
  Row,
  Col,
  Nav,
  NavItem,
  Badge,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCaretDown,
  faUserPlus,
  faCheckCircle,
  faEye,
  faBars,
  faArrowDown,
  faSortDown,
  faUser,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { faHome, faBriefcase } from "@fortawesome/free-solid-svg-icons";

import { getHirings, onWebsiteAlerts } from "../../actions/auth";
import { API_URL } from "../../config/config";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  CandidateMenu,
  MentorMenu,
  CompanyMenu,
  UniversityMenu,
  ManagerMenu,
  StaffMenu
} from "../Sidebar/navigationMenu";
import SubMenu from "../Sidebar/SubMenu";
import { getImage } from "../../config/functions";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "",
      isOpen: false,
      searchBox: false,
      filter: {
        limit: 20
      },
      alert: null
    };
    this.onScroll = this.onScroll.bind(this);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    var _this = this;
    onWebsiteAlerts((err, data) => {
      if (data && data.length > 0) {
        _this.setState({ alert: data[0] });
      }
    });
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  logout() {
    var _this = this;
    this.props.userSignOut(function(err, res) {
      _this.props.history.push("/login");
    });
  }
  onScroll() {
    if (window.scrollY > 0) {
      this.setState({ scroll: " shadow " });
    } else {
      this.setState({ scroll: "" });
    }
  }

  renderNav() {
    return (
      <React.Fragment>
        {this.props.company_domain ? (
          <NavItem className="ml-0">
            <a
              target="_blank"
              className="drop-down nav-link"
              href="https://www.shadowai.cloud/courses"
            >
              Courses
            </a>
            <Link className="drop-down nav-link" to="/company-login">
              Login
            </Link>
          </NavItem>
        ) : (
          <React.Fragment>
            {this.renderOtherLinks()}
            <NavItem>
              <Link className="drop-down nav-link" to="/login">
                Login
              </Link>
              {/* <MobileView className="d-flex  justify-content-end" > */}
              {/* </MobileView> */}
            </NavItem>
          </React.Fragment>
        )

        // <UncontrolledDropdown
        // className="ml-0"
        // >
        // <DropdownToggle nav className="ml-0" >
        //   <FontAwesomeIcon className="fs-16" icon={faUser} />
        //   <FontAwesomeIcon className='ml-2 fs-16' icon={faCaretDown} />
        // </DropdownToggle>
        // <DropdownMenu right>
        //   <DropdownItem>
        //     <Link to="/login">Applicant</Link>
        //   </DropdownItem>

        //   <DropdownItem>
        //     <Link to="/company-login">Employee</Link>
        //   </DropdownItem>
        //   <DropdownItem>
        //     <Link to="/company-login">Company</Link>
        //   </DropdownItem>
        //   <DropdownItem>
        //     <Link to="/company-login">University</Link>
        //   </DropdownItem>
        // </DropdownMenu>
        // </UncontrolledDropdown>
        }

        {/* <NavItem >
          <Link  className="drop-down nav-link" to="/login">Login/Signup</Link>
        </NavItem> */}
        {/* <NavItem >
          <Link to="/signup" className="btn btn-primary">Sign up Free</Link>
        </NavItem>       */}
      </React.Fragment>
    );
  }

  renderMobileNav(user, page) {
    var location = this.props.location;
    var userMenu = [];
    var userName = "";
    var userType = page;
    var company_name = "";
    if (page == "candidate") {
      userMenu = CandidateMenu;
      userName = user.first_name;
      if (user && user.type == "university") {
        userType = "Student";
      }
    } else if (page == "coach") {
      userMenu = MentorMenu;
      userName = user.first_name;
      userType =
        user && user.is_manager
          ? user.type == "university"
            ? "Staff"
            : "Manager"
          : "Mentor";
      company_name = user.company_name;
    } else if (page == "company") {
      if (user && user.type == "university") {
        userType = user.role == "admin" ? "University" : "Staff";
        userName = user.role == "admin" ? user.name : user.coach_name;
        userMenu = user.is_manager ? StaffMenu : UniversityMenu;
      } else {
        userType = user.role == "admin" ? "Company" : "Employee";
        userName = user.role == "admin" ? user.name : user.coach_name;
        userMenu = user.is_manager ? ManagerMenu : CompanyMenu;
      }

      // userType = user && user.type?user.type:'Company'
      // if(user && user.type == 'university'){
      //   userMenu = UniversityMenu;
      //   userName = user.name;
      // }else{
      //   userMenu = CompanyMenu;
      //   userName = user.p_first_name+' '+user.p_last_name;
      // }
    }
    return (
      <React.Fragment>
        <UncontrolledDropdown className=" d-flex align-items-center">
          {this.renderOtherLinks()}
          <DropdownToggle nav>
            <div className="d-flex">
              <FontAwesomeIcon className="fs-18" icon={faUser} />
              <FontAwesomeIcon className="ml-2 fs-18" icon={faCaretDown} />
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem>
                <Link to={page=='coach'?"/mentor/dashboard":"/"+page+"/dashboard"}>Dashboard</Link>
              </DropdownItem> */}
            {userMenu &&
              userMenu.map((menu, i) => {
                if (menu && menu.subMenu) {
                  return (
                    <UncontrolledDropdown className="ml-4">
                      <DropdownToggle nav>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={menu.icon ? menu.icon : faBriefcase}
                          className="mr-4"
                        />
                        {menu.title}
                      </DropdownToggle>
                      <DropdownMenu>
                        {menu.subMenu.map(submenu => {
                          return (
                            <DropdownItem>
                              <NavLink tag={Link} to={submenu.target}>
                                <FontAwesomeIcon
                                  fixedWidth={true}
                                  icon={
                                    submenu.icon ? submenu.icon : faBriefcase
                                  }
                                  className="mr-4"
                                />
                                {submenu.title}
                              </NavLink>
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                      {/* <SubMenu title={menu.title} icon={menu.icon?menu.icon:faHome} items={menu.subMenu} location={location}/> */}
                      {/* </DropdownItem> */}
                    </UncontrolledDropdown>
                  );
                } else {
                  var active = location.pathname == menu.target;
                  if (menu.target.includes("/dashboard")) {
                    var pathname = location.pathname
                      .replace("/candidate", "")
                      .replace("/coach", "")
                      .replace("/company", "");
                    if (
                      pathname == "/" ||
                      pathname == "/dashboard" ||
                      pathname == ""
                    ) {
                      active = true;
                    }
                  }
                  return (
                    <DropdownItem>
                      <NavLink
                        tag={Link}
                        to={menu.target}
                        className={active ? "active" : ""}
                      >
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={menu.icon ? menu.icon : faBriefcase}
                          className="mr-4"
                        />
                        {menu.title}
                      </NavLink>
                    </DropdownItem>
                  );
                }
              })}
            <DropdownItem divider />
            <DropdownItem>
              <NavLink
                tag={Button}
                block
                color="link"
                onClick={() => this.logout()}
              >
                Logout
              </NavLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* <Nav vertical className="list-unstyled pb-3">
              <NavItem className="my-3">
                  {userName}
              </NavItem>
                {
                    userMenu && userMenu.map((menu,i)=>{
                        if(menu && menu.subMenu){
                            return(
                              <div>
                                <SubMenu title={menu.title} icon={menu.icon?menu.icon:faHome} items={menu.subMenu} location={location}/>
                              </div>
                            )
                        }else{
                            var active = location.pathname == menu.target
                            if(menu.target.includes("/dashboard")){
                                var pathname = location.pathname.replace('/candidate','').replace('/coach','').replace('/company','');
                                if(pathname == '/' || pathname == '/dashboard' || pathname == ''  ){
                                    active = true
                                }
                                
                            }
                            return(
                                <NavItem>
                                    <NavLink tag={Link} to={menu.target} className={active?'active':''}>
                                        <FontAwesomeIcon fixedWidth={true} icon={menu.icon?menu.icon:faBriefcase} className="mr-4" />
                                        {menu.title}
                                    </NavLink>
                                </NavItem>
                            )
                        }
                    })
                }
                <DropdownItem divider />
                <NavItem>
                  <NavLink tag={Button} block color='danger'  onClick={()=>this.logout()} >
                  Logout
                  </NavLink>
                </NavItem>
            </Nav> */}
      </React.Fragment>
    );
  }

  renderAuthNav(user, page) {
    if (isMobile) {
      return this.renderMobileNav(user, page);
    } else {
      var userType = page;
      var userName = "";
      var company_name = "";
      var userType = page;
      if (page == "candidate") {
        userName = user.first_name;
        if (user && user.type == "university") {
          userType = "Student";
        }
      } else if (page == "coach") {
        userName = user.first_name;
        userType =
          user && user.is_manager
            ? user.type == "university"
              ? "Staff"
              : "Manager"
            : "Mentor";
        company_name = user.company_name;
      } else if (page == "company") {
        // userType = user && user.type?user.type:'Company';
        if (user && user.type == "university") {
          userType = user.role == "admin" ? "University" : "Staff";
          userName = user.role == "admin" ? user.name : user.coach_name;
        } else {
          userType = user.role == "admin" ? "Company" : "Employee";
          userName = user.role == "admin" ? user.name : user.coach_name;
        }
      }
      userName = userName.substring(0, 20);
      return (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle nav>
              {/* {userName} */}
              <FontAwesomeIcon icon={faUser} />
              <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link
                  to={
                    page == "coach"
                      ? "/mentor/dashboard"
                      : "/" + page + "/dashboard"
                  }
                >
                  Dashboard
                </Link>
              </DropdownItem>
              {page == "candidate" ? (
                <React.Fragment>
                  <DropdownItem>
                    <Link to={"/" + page + "/connections"}>
                      <span>Connections</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/" + page + "/invite"}>
                      <span>Invite</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/" + page + "/communities"}>
                      <span>Communities</span>
                    </Link>
                  </DropdownItem>
                </React.Fragment>
              ) : null}

              <DropdownItem>
                <Link
                  to={
                    page == "coach"
                      ? "/mentor/profile"
                      : "/" + page + "/profile"
                  }
                >
                  Profile
                </Link>
              </DropdownItem>
              {page == "company" ? (
                <DropdownItem>
                  <Link to={"/company/view/" + user.key}>
                    {userType == "university"
                      ? "View University Page"
                      : "View Company Page"}
                  </Link>
                </DropdownItem>
              ) : null}

              <DropdownItem divider />
              <DropdownItem onClick={() => this.logout()}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {/* <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li className="drop-down font-weight-bolder ">
                  <Link to={"/"+page+"/profile"}>
                    <Badge color='primary' className='text-uppercase mr-2' >
                      {page=='coach'?user && user.is_manager?'Manager':
                      'Mentor':page}
                      </Badge>
                      {user.p_first_name?user.p_first_name+' '+user.p_last_name:user.first_name+' '+user.last_name}
                  </Link>
                  <ul>
                    <li><Link to={page=='coach'?"/mentor/profile":"/"+page+"/profile"}>Profile</Link></li>
                    {
                      page == 'company'?
                      <li><Link to={"/company/view/"+user.key}>View Company Page</Link></li>
                      :null
                    }
                    <li onClick={()=>this.logout()}><a>Logout</a></li>
                  </ul>
                </li>
              </ul>
            </nav> */}
          {/* <Link to="/signup"><a className="get-started-btn scrollto">Signup</a></Link> */}
        </React.Fragment>
      );
    }
  }

  renderCandidateHeader(user, page) {
    return (
      <div className="container">
        <Row>
          <Col lg={6} className="d-flex align-items-center">
            {user && user.picture ? (
              <img
                src={API_URL + "/images/" + user.picture}
                width="100px"
                className="rounded-circle mr-3"
              />
            ) : null}
            <h3>{user.first_name + " " + user.last_name}</h3>
          </Col>
          <Col lg={6} className="text-right d-flex justify-content-end">
            <div className="text-center mr-2">
              <h3 className="font-weight-bold">10</h3> Members
            </div>
            <div className="text-center ">
              <h3 className="font-weight-bold">2</h3> Invitations
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Nav className="auth-nav">
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/dashboard"}>Dashboard</Link>
            </NavItem>
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/invitation"}>
                <span>Invitations</span>
              </Link>
            </NavItem>
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/coaches"}>
                <span>Coaches</span>
              </Link>
            </NavItem>
          </Nav>
        </Row>
      </div>
    );
  }
  renderCoachHeader(user, page) {
    return (
      <div className="container">
        <Row>
          <Col lg={6} className="d-flex align-items-center">
            {user && user.picture ? (
              <img
                src={API_URL + "/images/" + user.picture}
                width="100px"
                className="rounded-circle mr-3"
              />
            ) : null}
            <h3>{user.first_name + " " + user.last_name}</h3>
          </Col>
          <Col lg={6} className="text-right d-flex justify-content-end">
            <div className="text-center mr-2">
              <h3 className="font-weight-bold">10</h3> Members
            </div>
            <div className="text-center ">
              <h3 className="font-weight-bold">2</h3> Invitations
            </div>
          </Col>
        </Row>
        <Row>
          <Nav className="auth-nav">
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/dashboard"}>Dashboard</Link>
            </NavItem>
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/invitation"}>
                <span>Invitations</span>
              </Link>
            </NavItem>
            <NavItem className="list-group-item">
              <Link to={"/" + page + "/members"}>
                <span>Members</span>
              </Link>
            </NavItem>
          </Nav>
        </Row>
      </div>
    );
  }

  renderCoachHeaderLinks(user, page) {
    var currentUrl = this.props.location.pathname;
    return (
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li
            className={currentUrl == "/" + page + "/dashboard" ? "active" : ""}
          >
            <Link to={"/" + page + "/dashboard"}>Dashboard</Link>
          </li>
          <li
            className={currentUrl == "/" + page + "/invitation" ? "active" : ""}
          >
            <Link to={"/" + page + "/invitation"}>
              <span>Invitations</span>
            </Link>
          </li>
          <li className={currentUrl == "/" + page + "/tests" ? "active" : ""}>
            <Link to={"/" + page + "/tests"}>
              <span>Tests</span>
            </Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/members" ||
              currentUrl.startsWith("/" + page + "/member")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/members"}>
              <span>Candidates</span>
            </Link>
          </li>
          {/* <li className={currentUrl == "/"+page+'/hirings' || currentUrl.startsWith("/"+page+'/hirings')?"active":""}>
            <Link to={"/"+page+'/hirings'} >
                <span>Open positions</span>
          </Link></li>           */}

          <li
            className={
              currentUrl == "/" + page + "/classes" ||
              currentUrl.startsWith("/" + page + "/class")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/classes"}>
              <span>Cohorts</span>
            </Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/prejoin/candidates"
                ? "drop-down  active"
                : " drop-down "
            }
          >
            <Link to={"/" + page + "/prejoin/candidates"}>Pre-joining</Link>
            <ul>
              <li
                className={
                  currentUrl == "/" + page + "/prejoin/candidates"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/prejoin/candidates"}>Candidates</Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/prejoin/documents"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/prejoin/documents"}>Documents</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
  renderManagerHeaderLinks(user, page) {
    var currentUrl = this.props.location.pathname;
    return (
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li
            className={currentUrl == "/" + page + "/dashboard" ? "active" : ""}
          >
            <Link to={"/" + page + "/dashboard"}>Dashboard</Link>
          </li>
          <li className={currentUrl == "/" + page + "/tests" ? "active" : ""}>
            <Link to={"/" + page + "/tests"}>
              <span>Tests</span>
            </Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/prejoin/candidates" ||
              currentUrl.startsWith("/" + page + "/prejoin/candidates")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/prejoin/candidates"}>
              <span>Pre joined candidates</span>
            </Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/hirings" ||
              currentUrl.startsWith("/" + page + "/hirings")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/hirings"}>
              <span>Positions(Hirings)</span>
            </Link>
          </li>

          <li
            className={
              currentUrl == "/" + page + "/classes" ||
              currentUrl.startsWith("/" + page + "/class")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/classes"}>
              <span>Cohorts</span>
            </Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/prejoin/documents" ||
              currentUrl.startsWith("/" + page + "/prejoin/documents")
                ? "active"
                : ""
            }
          >
            <Link to={"/" + page + "/prejoin/documents"}>
              <span>Documents</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
  renderCandidateHeaderLinks(user, page) {
    const { history } = this.props;
    var navItems = [
      {
        href: "/dashboard",
        title: "Dashboard"
      },
      {
        href: "/invitation",
        title: "Invitations"
      },
      {
        href: "/tests",
        title: "Tests"
      },
      {
        href: "/mentors",
        startWith: "/mentors",
        title: "Mentors"
      },
      {
        href: "/companies",
        startWith: "/companies",
        title: "Companies"
      },
      {
        href: "/hirings",
        startWith: "/hirings",
        title: "Open positions"
      },
      {
        href: "/classes",
        startWith: "/class",
        title: "Cohorts"
      }
    ];
    var currentUrl = this.props.location.pathname;
    return (
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          {navItems &&
            navItems.map((nav, i) => {
              var classActive = "";
              if (nav.startWith) {
                if (
                  currentUrl == "/" + page + nav.href ||
                  currentUrl.startsWith("/" + page + nav.startWith)
                ) {
                  classActive = "active";
                }
              } else if (currentUrl == "/" + page + nav.href) {
                classActive = "active";
              }

              return (
                <li className={classActive}>
                  <Link to={"/" + page + nav.href}>{nav.title}</Link>
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
  renderOtherLinks() {
    var currentUrl = this.props.location.pathname;
    // console.log(currentUrl);
    if (!this.props.company_domain) {
      return (
        <nav
          className={
            isMobile
              ? "w-100 live-room nav-menu d-sm-block d-lg-block d-flex  justify-content-end"
              : "w-100 live-room nav-menu d-sm-block d-lg-block"
          }
        >
          <ul className="">
            {/* {currentUrl !='/'? <li className="nav-item ml-sm-0 p-0 pr-2 ">
              <Link to="/interview" className="btn btn-primary text-white btn-rounded">Practice Now</Link>
            </li>:null} */}

            {/* <li className="nav-item ml-sm-0 p-0">
              <Link to="/question-practice" className="p-2">BYOQ</Link>
            </li> */}

            {/* <UncontrolledDropdown
              inNavbar
              nav
              className={
                isMobile
                  ? "mr-2 p-0 d-flex justify-content-between"
                  : "mr-2 p-0"
              }
            >
              <DropdownToggle nav>
                Practice
                <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
              </DropdownToggle>
              <DropdownMenu right={false}>
                <React.Fragment>
                  <DropdownItem>
                    <Link to={"/interview/job_hiring"}>
                      <span>Job Interviews</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/interview/university_admission"}>
                      <span>College Admissions</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/interview/visa_preparation"}>
                      <span>Visa Interviews</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/question-practice"}>
                      <span>Bring Your Own Questions</span>
                    </Link>
                  </DropdownItem> */}
            {/* <DropdownItem>
                    <Link to={'/hirings'} >
                      <span>Search Practices</span>
                    </Link>
                  </DropdownItem> */}
            {/* <DropdownItem>
                    <Link to={'/proficiency_test'} >
                      <span>Language Proficiency</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/debate_skills'} >
                      <span>Debate</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/presentation_skills'} >
                      <span>Presentations</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/meeting_skills'} >
                      <span>Meetings</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/reading_skills'} >
                      <span>Reading Skills</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/school'} >
                      <span>High School Students</span>
                    </Link>
                  </DropdownItem> */}
            {/* </React.Fragment>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              inNavbar
              nav
              className={
                isMobile
                  ? "mr-2 p-0 d-flex justify-content-between"
                  : "mr-2 p-0"
              }
            >
              <DropdownToggle nav>
                Explore
                <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
              </DropdownToggle>
              <DropdownMenu right>
                <React.Fragment>
                  <DropdownItem>
                    <Link to={"/candidate/career_paths"}>
                      <span>Career Paths</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/candidate/role_discovery"}>
                      <span>Role Discovery</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/candidate/resume_scorecard"}>
                      <span>Resume Scorecard</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/candidate/recruiter_chat"}>
                      <span>Recruiter Chat</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/interview/question_bank"}>
                      <span>Question Bank</span>
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to={"/hirings"}>
                      <span>Search Practices</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/pricing"}>
                      <span>Pricing</span>
                    </Link>
                  </DropdownItem> */}

            {/* <DropdownItem>
                    <Link to={'/proficiency_test'} >
                      <span>Language Proficiency</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/debate_skills'} >
                      <span>Debate</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/presentation_skills'} >
                      <span>Presentations</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/meeting_skills'} >
                      <span>Meetings</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/reading_skills'} >
                      <span>Reading Skills</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={'/school'} >
                      <span>High School Students</span>
                    </Link>
                  </DropdownItem> */}
            {/* </React.Fragment>
              </DropdownMenu>
            </UncontrolledDropdown> */}

            {/* <UncontrolledDropdown inNavbar nav className="mr-2 p-0">
           
          </UncontrolledDropdown> */}

            {/* <li className="nav-item ml-sm-0 p-0">
              <Link to="/hirings" className="p-2">Jobs</Link>
            </li> */}
            {/* <li className="nav-item ml-sm-0 p-0">
              <Link to="/courses" className="p-2">Courses</Link>
            </li> */}
            {/* <li className="nav-item ml-sm-0 p-0">
              <Link to="/how-it-works" className="p-2">How it works</Link>
            </li> */}
          </ul>
        </nav>
      );
    }
  }
  renderCompanyHeaderLinks(user, page) {
    var currentUrl = this.props.location.pathname;
    return (
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li
            className={currentUrl == "/" + page + "/dashboard" ? "active" : ""}
          >
            <Link to={"/" + page + "/dashboard"}>Dashboard</Link>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/hirings"
                ? "drop-down  active"
                : " drop-down "
            }
          >
            <Link to={"/" + page + "/hirings"}>Hirings</Link>
            <ul>
              <li
                className={
                  currentUrl == "/" + page + "/departments" ? "active" : ""
                }
              >
                <Link to={"/" + page + "/departments"}>
                  <span>Departments</span>
                </Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/hirings" ? "active" : ""
                }
              >
                <Link to={"/" + page + "/hirings"}>
                  <span>Hirings</span>
                </Link>
              </li>
              <li
                className={currentUrl == "/" + page + "/tests" ? "active" : ""}
              >
                <Link to={"/" + page + "/tests"}>Tests</Link>
              </li>
            </ul>
          </li>
          <li
            className={
              currentUrl == "/" + page + "/prejoin/candidates"
                ? "drop-down  active"
                : " drop-down "
            }
          >
            <Link to={"/" + page + "/prejoin/candidates"}>Pre-joining</Link>
            <ul>
              <li
                className={
                  currentUrl == "/" + page + "/prejoin/candidates"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/prejoin/candidates"}>Candidates</Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/onboarding/documents"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/onboarding/documents"}>Documents</Link>
              </li>
            </ul>
          </li>

          <li
            className={
              currentUrl == "/" + page + "/onboarding/candidates"
                ? "drop-down  active"
                : " drop-down "
            }
          >
            <Link to={"/" + page + "/onboarding/candidates"}>Post-joining</Link>
            <ul>
              <li
                className={
                  currentUrl == "/" + page + "/onboarding/candidates"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/onboarding/candidates"}>
                  Candidates
                </Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/onboarding/steps" ? "active" : ""
                }
              >
                <Link to={"/" + page + "/onboarding/steps"}>Steps</Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/onboarding/forms" ? "active" : ""
                }
              >
                <Link to={"/" + page + "/onboarding/forms"}>Forms</Link>
              </li>
              <li
                className={
                  currentUrl == "/" + page + "/onboarding/documents"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/" + page + "/onboarding/documents"}>Documents</Link>
              </li>
            </ul>
          </li>

          {/* <li className={currentUrl == "/"+page+'/departments'?"active":""}>
          <Link to={"/"+page+'/departments'} >
                  <span>Departments</span>
          </Link></li>
          <li className={currentUrl == "/"+page+'/hirings'?"active":""}>
            <Link to={"/"+page+'/hirings'} >
                  <span>Hirings</span>
          </Link></li>
          <li className={currentUrl == "/"+page+'/mapping'?"active":""}>
            <Link to={"/"+page+'/mapping'} >
                  <span>Company Mapping</span>
          </Link></li> */}
        </ul>
      </nav>
    );
  }

  renderMenuLinks() {
    var page = "";
    var navItems = [
      // {
      //    href:"for_mentors",
      //    title:"Mentors / Employees"
      // },
      // {
      //   href:"for_companies",
      //   title:"Companies"
      // }
    ];
    var currentUrl = this.props.location.pathname;
    return navItems.map((nav, i) => {
      var classActive = "";
      if (nav.startWith) {
        if (
          currentUrl == "/" + page + nav.href ||
          currentUrl.startsWith("/" + page + nav.startWith)
        ) {
          classActive = "active";
        }
      } else if (currentUrl == "/" + page + nav.href) {
        classActive = "active";
      }

      return (
        <NavItem className={classActive}>
          <Link className="nav-link" to={"/" + page + nav.href}>
            {nav.title}
          </Link>
        </NavItem>
      );
    });
  }

  onSearch = event => {
    if (this.textInput.current.value) {
      this.props.history.push(
        "/hirings?search=" + this.textInput.current.value
      );
      this.textInput.current.value = "";
      this.setState({ searchBox: false });
    }
  };
  handleKeyDown = e => {
    if (e.key === "Enter") {
      // var cloneFilter = {};
      // cloneFilter.search = this.textInput.current.value;
      if (this.textInput.current.value) {
        this.props.history.push(
          "/hirings?search=" + this.textInput.current.value
        );
        this.textInput.current.value = "";
      }
      // this.props.getHirings(cloneFilter)
    }
  };

  render() {
    var currentUrl = this.props.location.pathname;
    var {
      authenticated,
      candidate,
      coach,
      company,
      company_domain
    } = this.props;
    // const router = useRouter()
    var userCard = this.renderNav();
    var userCardHeader = null,
      userCardHeaderLinks = null;
    userCardHeaderLinks = this.renderMenuLinks();

    if (candidate && authenticated == true) {
      userCard = this.renderAuthNav(candidate, "candidate");
    } else if (coach) {
      userCard = this.renderAuthNav(coach, "coach");
    } else if (company) {
      userCard = this.renderAuthNav(company, "company");
    }

    if (
      this.props.location.pathname.startsWith("/videocall") ||
      this.props.location.pathname.startsWith("/video-room") ||
      this.props.location.pathname.startsWith("/live-room/") ||
      this.props.location.pathname.startsWith("/mirror-room/") ||
      (this.props.location.pathname.startsWith("/one-to-one-practice-room/") &&
        this.props.location.pathname.split("/").length == 4)
    ) {
      return null;
    }
    var logoLink = "/";
    if (authenticated && candidate) {
      logoLink = "/candidate/dashboard";
    } else if (authenticated && coach) {
      logoLink = "/mentor/dashboard";
    } else if (authenticated && company) {
      logoLink = "/company/dashboard";
    }
    var excludePaths = [
      "/",
      "/for_mentors",
      "/for_companies",
      "/what_is_shadowing",
      "/how_shadowing_works",
      "/privacy_policy",
      "/terms",
      "/what_is_shadowing",
      "/signup",
      "/login",
      "/about-us"
    ];
    if (isMobile) {
      console.log("this renders in mobile view");
    }
    return (
      <React.Fragment>
        <div className="container-fluid sticky-top p-0">
          <header id="header" className={"sticky-top container-fluid pb-0"}>
            <Navbar
              color="transparent"
              expand="md"
              className="nav-menu d-flex justify-content-between align-items-center"
            >
              <NavbarBrand className="d-flex">
                <Link to="/" className="logo mr-auto">
                  <img
                    src="https://shadowing-videos.nyc3.digitaloceanspaces.com/website/assets/img/logo.png"
                    alt="ShadowAI"
                  />
                </Link>
                {company_domain ? (
                  <img
                    height={30}
                    className="ml-3 mt-2"
                    src={getImage(company_domain.picture)}
                    alt={company_domain.c_name}
                  />
                ) : null}
              </NavbarBrand>
              <div className="d-flex">
                {/* <Button color='link' onClick={()=>this.setState({searchBox:true})}  >
                    <FontAwesomeIcon className="fs-18" icon={faSearch}  />
                </Button> */}
                {}
                <ul
                  className={
                    isMobile
                      ? "d-flex align-items-center"
                      : "align-items-lg-center"
                  }
                >
                  {userCard}
                </ul>
              </div>

              {/* <div className="mr-3 search_input" >
                <InputGroup className=" " >
                  <Input innerRef={this.textInput} className='b-0' placeholder='Search by position, company, location' 
                  onKeyDown={this.handleKeyDown}
                  />
                  <InputGroupAddon addonType="prepend">
                    <Button onClick={this.onSearch} color='link'  >
                        <FontAwesomeIcon icon={faSearch}  />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div> */}
              {/* <BrowserView className="d-flex ">
              <UncontrolledCollapse toggler="#toggler" navbar>
                {
                  company_domain?
                  <Nav
                  className="align-items-lg-center"
                  navbar
                >
                    {userCard}
                </Nav>:
                <Nav
                  className="align-items-lg-center"
                  navbar
                >
                    {this.renderOtherLinks()}
                  {userCard}
                </Nav>
                }
                
              </UncontrolledCollapse>
              </BrowserView> */}
            </Navbar>
            {/* {
              this.state.searchBox?
              <div className="seachMobileBox" >
                <div className="mr-2 search_input" >
                  <InputGroup className=" " >
                    <Input innerRef={this.textInput} className='b-0' placeholder='Search by position, company, location' 
                    onKeyDown={this.handleKeyDown}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button onClick={this.onSearch} color='link'  >
                          <FontAwesomeIcon icon={faSearch}  />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <Button color='link' onClick={()=>this.setState({searchBox:false})}  >
                    <FontAwesomeIcon icon={faTimes}  />
                </Button>
              </div>:null
            } */}
            {/* <div className="footer_fix_buttons" >
                {this.renderOtherLinks()}
              </div> */}

            {/* <MobileView>
            <UncontrolledCollapse toggler="#toggler" navbar>
                  <Nav
                    className="ml-auto"
                    navbar
                  >
                    {!candidate && !coach && !company?userCardHeaderLinks:null}
                    {userCard}
                </Nav>
              </UncontrolledCollapse>
            </MobileView>  */}
          </header>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  console.log(state);
  return {
    candidate: state.Auth.candidate,
    coach: state.Auth.coach,
    company: state.Auth.company,
    authenticated: state.Auth.authenticated,
    company_domain: state.Auth.company_domain
  };
};

const mapDispatchToProps = {
  userSignOut,
  getCandidateProfile,
  getHirings
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
