import * as CommonAction from "../constants/types.js";
import cookie from "react-cookies";
const Auth = (state = {}, action) => {
  switch (action.type) {
    case CommonAction.GET_COUNTRY: {
      return { ...state, countries: action.payload };
    }
    case CommonAction.SIGNOUT_CANDIDATE_SUCCESS: {
      return {
        ...state,
        token: null,
        candidate: null,
        authenticated: false
      };
    }
    case CommonAction.AUTHENTICATE_CANDIDATE:
      return { ...state, error: "", authenticated: action.payload };
    case CommonAction.CANDIDATE_TOKEN_SET: {
      return {
        ...state,
        token: action.payload
      };
    }

    case CommonAction.GET_CANDIDATE: {
      return {
        ...state,
        candidate: action.payload
      };
    }

    case CommonAction.SIGNOUT_COACH_SUCCESS: {
      return {
        ...state,
        token: null,
        coach: null,
        authenticated: false
      };
    }
    case CommonAction.AUTHENTICATE_COACH:
      return { ...state, error: "", authenticated: action.payload };
    case CommonAction.COACH_TOKEN_SET: {
      return {
        ...state,
        token: action.payload
      };
    }
    case CommonAction.GET_COACH: {
      return {
        ...state,
        coach: action.payload
      };
    }
    case CommonAction.GET_RELATED_RESULT: {
      return {
        ...state,
        related_results: action.payload
      };
    }
    case CommonAction.GET_COACHES: {
      return {
        ...state,
        coaches: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_NOTES: {
      return {
        ...state,
        candidate_notes: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_MEETINGS: {
      return {
        ...state,
        candidate_meetings: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_TASKS: {
      return {
        ...state,
        candidate_tasks: action.payload
      };
    }
    case CommonAction.GET_INDUSTRIES: {
      return {
        ...state,
        industries: action.payload
      };
    }
    case CommonAction.GET_MEETING_INFO: {
      return {
        ...state,
        meeting_info: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_COACH_COMPANY: {
      return {
        ...state,
        companies: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_COACH_SKILLS: {
      return {
        ...state,
        skills: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_COACH_POSITION: {
      return {
        ...state,
        positions: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_COACH_LANGUAGE: {
      return {
        ...state,
        languages: action.payload
      };
    }

    case CommonAction.GET_COMPANY: {
      return {
        ...state,
        company: action.payload
      };
    }
    case CommonAction.GET_CLASS_HIRINGS: {
      return {
        ...state,
        hirings: action.payload
      };
    }
    case CommonAction.GET_COMPANY_INFO: {
      return {
        ...state,
        company_info: action.payload
      };
    }
    case CommonAction.SIGNOUT_COMPANY_SUCCESS: {
      return {
        ...state,
        token: null,
        company: null,
        authenticated: false
      };
    }
    case CommonAction.GET_CANDIDATE_HIRINGS: {
      return {
        ...state,
        candidate_hirings: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_APPLIED_HIRINGS: {
      return {
        ...state,
        applied_hirings: action.payload
      };
    }
    case CommonAction.GET_HIRING_INFO: {
      return {
        ...state,
        hiring_info: action.payload
      };
    }
    case CommonAction.GET_HIRING_OTHER_POSITION: {
      return {
        ...state,
        hiring_other_positions: action.payload
      };
    }

    case CommonAction.COMAPNY_TOKEN_SET: {
      return {
        ...state,
        token: action.payload
      };
    }

    case CommonAction.GET_COACHE_INFO: {
      return {
        ...state,
        coach_info: action.payload
      };
    }
    case CommonAction.GET_COMPANIES: {
      return {
        ...state,
        companies: action.payload
      };
    }

    case CommonAction.GET_CANDIDATE_FEEDBACK_FORMS: {
      return {
        ...state,
        feedback_forms: action.payload
      };
    }
    case CommonAction.FETCH_BLOGS: {
      return {
        ...state,
        blogs: action.payload
      };
    }
    case CommonAction.FETCH_OTHER_BLOGS: {
      return {
        ...state,
        other_blogs: action.payload
      };
    }

    case CommonAction.BLOG_INFO_CONTENT: {
      return {
        ...state,
        blog_info_content: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_FEEDBAKFORMS: {
      return {
        ...state,
        candidate_feedback: action.payload
      };
    }
    case CommonAction.GET_DEFAULT_QUESTIONS: {
      return {
        ...state,
        default_questions: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_TEST: {
      return {
        ...state,
        candidate_tests: action.payload
      };
    }
    case CommonAction.FETCH_TEST_INFO: {
      return {
        ...state,
        test_info: action.payload
      };
    }
    case CommonAction.TEST_FORM_ANSWERS: {
      return {
        ...state,
        test_form_answers: { ...state.test_form_answers, ...action.payload }
      };
    }
    case CommonAction.TEST_USER_STATS: {
      return {
        ...state,
        test_user_stats: action.payload
      };
    }
    case CommonAction.GET_C_CLASS_TESTS: {
      return {
        ...state,
        class_tests: action.payload
      };
    }
    case CommonAction.GET_C_COMPANY_DETAILS: {
      return {
        ...state,
        c_company_details: action.payload
      };
    }
    case CommonAction.GET_C_COMPANY_HIRED: {
      return {
        ...state,
        c_company_hired: action.payload
      };
    }
    case CommonAction.GET_COMPANY_TREE: {
      return {
        ...state,
        company_tree: action.payload
      };
    }
    case CommonAction.GET_COMPANY_DEPARTMENTS: {
      return {
        ...state,
        company_departments: action.payload
      };
    }
    case CommonAction.GET_DEPARTMENT_LEADERS: {
      return {
        ...state,
        department_leaders: action.payload
      };
    }
    case CommonAction.GET_C_COMPANY_TREE: {
      return {
        ...state,
        ccompany_tree: action.payload
      };
    }
    case CommonAction.GET_COMPANY_STATS: {
      return {
        ...state,
        company_stats: action.payload
      };
    }
    case CommonAction.GET_COMPANY_TROUNDS: {
      return {
        ...state,
        company_rounds: action.payload
      };
    }
    case CommonAction.GET_C_COMPANY_DOCUMENTS: {
      return {
        ...state,
        ccompany_documents: action.payload
      };
    }
    case CommonAction.GET_C_COMPANY_DOCUMENT: {
      return {
        ...state,
        ccompany_document: action.payload
      };
    }
    case CommonAction.GET_HIRINGS: {
      return {
        ...state,
        hirings: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_FEEDBACK: {
      return {
        ...state,
        feedbacks: action.payload
      };
    }
    case CommonAction.GET_PREDEFINED_TESTS: {
      return {
        ...state,
        predefined_test: action.payload
      };
    }
    case CommonAction.COMPANY_HIRINGS_ROUNDS: {
      return {
        ...state,
        company_rounds: action.payload
      };
    }
    case CommonAction.GET_ALL_NOTES: {
      return {
        ...state,
        all_notes: action.payload
      };
    }
    case CommonAction.GET_EVENT_INFO: {
      return {
        ...state,
        event_info: action.payload
      };
    }
    case CommonAction.GET_SKILLS: {
      return {
        ...state,
        skills: action.payload
      };
    }
    case CommonAction.COMMUNITY_POST_COMMENTS: {
      return {
        ...state,
        post_comments: action.payload
      };
    }
    case CommonAction.GET_USER_COMMUNITIES: {
      return {
        ...state,
        user_communities: action.payload
      };
    }
    case CommonAction.GET_USER_ROOMS: {
      return {
        ...state,
        user_rooms: action.payload
      };
    }
    case CommonAction.GET_ROOM_FEEDBACKS: {
      return {
        ...state,
        room_feedback: action.payload
      };
    }
    case CommonAction.GET_ROOM_BLOGS: {
      return {
        ...state,
        room_blogs: action.payload
      };
    }
    case CommonAction.GET_ROOMS: {
      return {
        ...state,
        rooms: action.payload
      };
    }
    case CommonAction.GET_OPEN_ROOMS: {
      return {
        ...state,
        open_rooms: action.payload
      };
    }
    case CommonAction.GET_OPEN_ROOM_HISTORY: {
      return {
        ...state,
        open_room_history: action.payload
      };
    }
    case CommonAction.GET_C_ROOMS: {
      return {
        ...state,
        c_rooms: action.payload
      };
    }
    case CommonAction.GET_COMMUNITY_POSTS: {
      return {
        ...state,
        community_posts: action.payload
      };
    }
    case CommonAction.GET_COMPANY_EMPLOYEES: {
      return {
        ...state,
        company_employees: action.payload
      };
    }
    case CommonAction.GET_COMPANY_HIRINGS: {
      return {
        ...state,
        company_hirings: action.payload
      };
    }
    case CommonAction.GET_COMPANY_EVENTS: {
      return {
        ...state,
        company_events: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_LIVE_ROOMS: {
      return {
        ...state,
        candidate_live_rooms: action.payload
      };
    }
    case CommonAction.GET_ROOM_MENTORS: {
      return {
        ...state,
        room_mentors: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_CATEGORY: {
      return {
        ...state,
        mirror_room_category: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_SUBCATEGORY: {
      return {
        ...state,
        mirror_room_subcategory: action.payload
      };
    }
    case CommonAction.SET_MIRROR_ROOM_CANDIDATE: {
      return {
        ...state,
        mirror_room_candidate: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_DETAILS: {
      return {
        ...state,
        mirror_room_details: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_MIRROR_ROOMS: {
      return {
        ...state,
        candidate_mirror_rooms: action.payload
      };
    }
    case CommonAction.GET_COMPANY_VIDEOS: {
      return {
        ...state,
        company_videos: action.payload
      };
    }
    case CommonAction.GET_ROOM_INFO: {
      return {
        ...state,
        room_info: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_COMPANIES: {
      return {
        ...state,
        mirror_room_companies: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_MESSAGE_USER: {
      return {
        ...state,
        candidate_msg_users: action.payload
      };
    }
    case CommonAction.GET_COACH_MESSAGE: {
      return {
        ...state,
        candidate_user_msgs: action.payload
      };
    }
    case CommonAction.GET_ROOM_QUESTIONS: {
      return {
        ...state,
        room_questions: action.payload
      };
    }

    case CommonAction.GET_ROOM_QUESTION_CATEGORIES: {
      return {
        ...state,
        room_question_categories: action.payload
      };
    }

    case CommonAction.GET_ROOM_QUESTION_SUBCATEGORIES: {
      return {
        ...state,
        room_question_subcategories: action.payload
      };
    }
    case CommonAction.GET_ROOM_QUESTION_INFO: {
      return {
        ...state,
        room_question_info: action.payload
      };
    }
    case CommonAction.GET_COACH_ROOM_TIMES: {
      return {
        ...state,
        coach_room_times: action.payload
      };
    }
    case CommonAction.GET_SHARED_HIRINGS: {
      return {
        ...state,
        shared_hirings: action.payload
      };
    }

    case CommonAction.GET_PLACEMENT_HIRINGS: {
      return {
        ...state,
        placement_hirings: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_UNI_TESTS: {
      return {
        ...state,
        uni_tests: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_UNI_ROOMS: {
      return {
        ...state,
        uni_rooms: action.payload
      };
    }
    case CommonAction.GET_PREDEFINED_MIRROR_ROOMS: {
      return {
        ...state,
        predefined_rooms: action.payload
      };
    }
    case CommonAction.GET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload
      };
    }
    case CommonAction.GET_TEST_GROUPS: {
      return {
        ...state,
        test_groups: action.payload
      };
    }
    case CommonAction.GET_HIRING_MEETINGS: {
      return {
        ...state,
        hiring_meetings: action.payload
      };
    }
    case CommonAction.GET_HIRING_MEETING_INFO: {
      return {
        ...state,
        hiring_meeting_info: action.payload
      };
    }
    case CommonAction.GET_HIRING_MEETING_CANDIDATE_NOTES: {
      return {
        ...state,
        hiring_meeting_notes: action.payload
      };
    }
    case CommonAction.GET_HOME_FEEDBACKS: {
      return {
        ...state,
        home_feedbacks: action.payload
      };
    }
    case CommonAction.GET_CANDIDATE_PRICING: {
      return {
        ...state,
        candidate_pricing: action.payload
      };
    }
    case CommonAction.GET_HIRING_ROOMS: {
      return {
        ...state,
        hiring_rooms: action.payload
      };
    }
    case CommonAction.SET_CURRENT_QUESTION: {
      return {
        ...state,
        get_current_question: action.payload
      };
    }
    case CommonAction.SET_HIRING_CURRENT_QUESTION: {
      return {
        ...state,
        get_hiring_current_question: action.payload
      };
    }
    case CommonAction.GET_PREDEFINED_MIRROR_ROOM_INFO: {
      return {
        ...state,
        predefined_room_info: action.payload
      };
    }
    case CommonAction.GET_PREDEFINED_UNI_MIRROR_ROOMS: {
      return {
        ...state,
        predefined_uni_rooms: action.payload
      };
    }
    case CommonAction.GET_PREDEFINED_UNI_LISTS: {
      return {
        ...state,
        predefined_uni_lists: action.payload
      };
    }
    case CommonAction.GET_TOURNAMENTS: {
      return {
        ...state,
        tournaments: action.payload
      };
    }
    case CommonAction.GET_TOURNAMENT_INFO: {
      return {
        ...state,
        tournament_info: action.payload
      };
    }
    case CommonAction.GET_PROFILE_VIEWS: {
      return {
        ...state,
        profile_views: action.payload
      };
    }
    case CommonAction.MIRROR_ROOM_SHARED: {
      return {
        ...state,
        mirror_room_shared: action.payload
      };
    }
    case CommonAction.CANDIDATE_SHARED_MIRROR_ROOM: {
      return {
        ...state,
        candidate_shared_rooms: action.payload
      };
    }
    case CommonAction.MIRROR_ROOM_REVIEWER: {
      return {
        ...state,
        mirror_room_reviewer: action.payload
      };
    }
    case CommonAction.CANDIDATE_REVIEW_MIRROR_ROOM: {
      return {
        ...state,
        candidate_review_room: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_PDATA: {
      return {
        ...state,
        mirror_room_page_data: action.payload
      };
    }

    case CommonAction.GET_PRACTICE_PAGES: {
      return {
        ...state,
        practice_pages: action.payload
      };
    }
    case CommonAction.GET_PRACTICE_PAGE_INFO: {
      return {
        ...state,
        practice_page_info: action.payload
      };
    }

    case CommonAction.GET_COMPANY_DOMAIN: {
      return {
        ...state,
        company_domain: action.payload
      };
    }
    case CommonAction.GET_SCHOOL_COUNTRIES: {
      return {
        ...state,
        school_countries: action.payload
      };
    }
    case CommonAction.GET_SCHOOL_GRADES: {
      return {
        ...state,
        school_grades: action.payload
      };
    }
    case CommonAction.GET_SCHOOL_SUBJECTS: {
      return {
        ...state,
        school_subjects: action.payload
      };
    }
    case CommonAction.GET_SCHOOL_TOPICS: {
      return {
        ...state,
        school_topics: action.payload
      };
    }
    case CommonAction.GET_SCHOOL_PRACTICE_INFO: {
      return {
        ...state,
        school_practice_info: action.payload
      };
    }

    case CommonAction.GET_HOME_VIDEOS: {
      return {
        ...state,
        home_videos: action.payload
      };
    }
    case CommonAction.GET_HOME_VIDEOS: {
      return {
        ...state,
        home_videos: action.payload
      };
    }
    case CommonAction.FETCH_COURSES: {
      return {
        ...state,
        courses: action.payload
      };
    }
    case CommonAction.COURSE_INFO: {
      return {
        ...state,
        course_info: action.payload
      };
    }
    case CommonAction.COURSE_ITEM_INFO: {
      return {
        ...state,
        course_item_info: action.payload
      };
    }

    case CommonAction.GET_COURSE_REVIEWS: {
      return {
        ...state,
        course_reviews: action.payload
      };
    }

    case CommonAction.GET_COURSE_ITEM_COMMENTS: {
      return {
        ...state,
        course_item_comments: action.payload
      };
    }
    case CommonAction.GET_HOME_DOMAINS: {
      return {
        ...state,
        home_domains: action.payload
      };
    }
    case CommonAction.COURSE_ITEMS: {
      return {
        ...state,
        course_items: action.payload
      };
    }
    case CommonAction.GET_COURSE_BUNDLES: {
      return {
        ...state,
        course_bundles: action.payload
      };
    }
    case CommonAction.GET_COURSE_BUNDLE_INFO: {
      return {
        ...state,
        bundle_info: action.payload
      };
    }
    case CommonAction.GET_USER_COURSES: {
      return {
        ...state,
        user_courses: action.payload
      };
    }
    case CommonAction.SET_FREE_PRACTICE_INFO: {
      return {
        ...state,
        practice_info: action.payload
      };
    }
    case CommonAction.FETCH_MICRO_COURSES: {
      return {
        ...state,
        micro_courses: action.payload
      };
    }
    case CommonAction.GET_PROFICIENCY_PAGE_CATEGORY: {
      return {
        ...state,
        proficiency_page: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_SUBCATEGORY1: {
      return {
        ...state,
        mirror_room_subcategory1: action.payload
      };
    }
    case CommonAction.FETCH_COUNTRY: {
      return {
        ...state,
        country: action.payload
      };
    }
    case CommonAction.GET_MIRROR_ROOM_VISA_CATEGORY: {
      return {
        ...state,
        mirror_room_visa_category: action.payload
      };
    }
    case CommonAction.GET_COMPANY_LIST: {
      return {
        ...state,
        company_list: action.payload
      };
    }
    case CommonAction.GET_DEBATE_CATEGORY: {
      return {
        ...state,
        debate_category: action.payload
      };
    }
    case CommonAction.GET_DEBATE_SUBCATEGORY: {
      return {
        ...state,
        debate_subcategory: action.payload
      };
    }
    case CommonAction.GET_PRESENTATION_CATEGORY: {
      return {
        ...state,
        presentation_category: action.payload
      };
    }
    case CommonAction.GET_PRESENTATION_SUBCATEGORY: {
      return {
        ...state,
        presentation_subcategory: action.payload
      };
    }
    case CommonAction.GET_MEETING_CATEGORY: {
      return {
        ...state,
        meeting_category: action.payload
      };
    }
    case CommonAction.GET_MEETING_SUBCATEGORY: {
      return {
        ...state,
        meeting_subcategory: action.payload
      };
    }

    case CommonAction.GET_READING_CATEGORY: {
      return {
        ...state,
        reading_category: action.payload
      };
    }
    case CommonAction.GET_READING_SUBCATEGORY: {
      return {
        ...state,
        reading_subcategory: action.payload
      };
    }
    case CommonAction.FETCH_CHAT_WIDGET_INFO: {
      return {
        ...state,
        fetch_chat_widget_info: action.payload
      };
    }
    case CommonAction.GET_PRACTICE_TYPES: {
      return {
        ...state,
        practice_types: action.payload
      };
    }
    case CommonAction.FETCH_MIRROR_ROOM_CATEGORY: {
      return {
        ...state,
        mirror_category: action.payload
      };
    }
    case CommonAction.GET_AI_MCQ_USER_ANSWER: {
      return {
        ...state,
        ai_mcq_user_answer: action.payload
      };
    }
    case CommonAction.GET_ANALYZE_RESUME_HISTORY: {
      return {
        ...state,
        analyze_resume: action.payload
      };
    }
    case CommonAction.GET_RESUME_ANALYSIS_INFO: {
      return {
        ...state,
        resume_analysis: action.payload
      };
    }
    case CommonAction.GET_ROLE_DISCOVERY_DETAIL_INFO: {
      return {
        ...state,
        role_discovery_detail_info: action.payload
      };
    }
    case CommonAction.GET_FAQ: {
      return {
        ...state,
        get_faq: action.payload
      };
    }
    case CommonAction.GET_FAQ_QUESTION_LIST: {
      return {
        ...state,
        faq_que_list: action.payload
      };
    }
    case CommonAction.GET_INTRODUCTION_CATEGORY: {
      return {
        ...state,
        introduction_category: action.payload
      };
    }
    case CommonAction.GET_INTRODUCTION_SUBCATEGORY: {
      return {
        ...state,
        introduction_subcategory: action.payload
      };
    }
    case CommonAction.GET_UNIVERSITY_COURSES: {
      return {
        ...state,
        university_courses: action.payload
      };
    }
    case CommonAction.GET_APTITUDE_USER_ANSWER: {
      return {
        ...state,
        aptitude_user_answer: action.payload
      };
    }
    case CommonAction.GET_APTITUDE_TEST_DETAIL_INFO: {
      return {
        ...state,
        aptitude_test_detail_info: action.payload
      };
    }
    case CommonAction.AI_MIRRORROOM_CATEGORY: {
      return {
        ...state,
        ai_category: action.payload
      };
    }
    case CommonAction.COMPANIES_CATEGORY_VALUES: {
      return {
        ...state,
        companies_category: action.payload
      };
    }
    case CommonAction.GET_UNIVERSITY_PRACTICE: {
      return {
        ...state,
        university_practice: action.payload
      };
    }
    case CommonAction.GET_VISA_PRACTICE: {
      return {
        ...state,
        visa_practice: action.payload
      };
    }
    case CommonAction.GET_HOME_PRACTICE: {
      return {
        ...state,
        home_practice: action.payload
      };
    }
    case CommonAction.GET_SIGNED_DATA: {
      return {
        ...state,
        signed_data: action.payload
      };
    }
    case CommonAction.GET_JOB_POSITION: {
      return {
        ...state,
        job_positions: action.payload
      };
    }
    case CommonAction.GET_POSITION_INFO: {
      return {
        ...state,
        positions_info: action.payload
      };
    }
    case CommonAction.GET_JOB_POSITION_RANDOM: {
      return {
        ...state,
        random_positions: action.payload
      };
    }
    case CommonAction.GET_CAREER: {
      return {
        ...state,
        careers: action.payload
      };
    }
    case CommonAction.GET_CAREER_INFO: {
      return {
        ...state,
        career_info: action.payload
      };
    }
    case CommonAction.GET_SRCC_CANDIDATE_DETAIL: {
      return {
        ...state,
        srcc_candidate_detail: action.payload
      };
    }

    default:
      return state;
  }
};

export default Auth;
