import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import createStore from './store';

import App from './app/app';
import axios from './config/api';
import cookie from 'react-cookies';

import { AUTHENTICATE_CANDIDATE,CANDIDATE_TOKEN_SET } from './constants/types';
import {getCandidateProfile} from "./actions/auth";
import {getCoachProfile,getComapnyProfile} from "./actions/coach";
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import {GOOGLE_APP_ID} from "./config/config";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
   config
} from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;


const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 10000,
  offset: '30px',
  // you can also just use 'scale'
  // transition: transitions.SCALE,
  transition: transitions.FADE,
  // containerStyle: {
  //   width: '100px',
  // }
}
// import './index.css';

// Create a store and get back itself and its history object
const { store, history } = createStore();
const candidate_token = cookie.load('token')
if (candidate_token) {
    axios.defaults.headers.user = candidate_token;
    store.dispatch({type: AUTHENTICATE_CANDIDATE, payload: true })
    store.dispatch({type: CANDIDATE_TOKEN_SET, payload: candidate_token })
    store.dispatch(getCandidateProfile());
}
const member_token = cookie.load('member')
if (member_token) {
  axios.defaults.headers.member = member_token;
  store.dispatch({type: AUTHENTICATE_CANDIDATE, payload: true })
  store.dispatch({type: CANDIDATE_TOKEN_SET, payload: member_token })
  store.dispatch(getCoachProfile());
}
const company_token = cookie.load('company')
if (company_token) {
  axios.defaults.headers.company = company_token;
  store.dispatch({type: AUTHENTICATE_CANDIDATE, payload: true })
  store.dispatch({type: CANDIDATE_TOKEN_SET, payload: company_token })
  store.dispatch(getComapnyProfile());
}

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
if (process.env.NODE_ENV == 'production') {
  console.log = () => {}
console.error = () => {}
console.debug = () => {}
console.warn = () => {}
}

const Application = (
  // <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
  <Provider store={store}>
    <ConnectedRouter history={history}  basename={'/'} >
      <Frontload noServerRender={true}>
        <AlertProvider template={AlertTemplate} {...options}>
        
          <App />
        </AlertProvider>
      </Frontload>
    </ConnectedRouter>
  </Provider>
  // </GoogleOAuthProvider>
);

// <React.StrictMode>
//     <Provider store={store}>
//       <ConnectedRouter history={history} basename={'/'} >
//         <App />
//     </ConnectedRouter>
//     </Provider>
//   </React.StrictMode>

const root = document.querySelector('#root');
  if (root.hasChildNodes() === true) {
    // If it's an SSR, we use hydrate to get fast page loads by just
    // attaching event listeners after the initial render
    Loadable.preloadReady().then(() => {
      hydrate(Application, root);
    });
  } else {
    // If we're not running on the server, just render like normal
    render(Application, root);
  }
