import * as CommonAction  from '../constants/types.js';
import cookie from 'react-cookies';
const country_code = cookie.load('country_code')
const Home = (state={}, action) => {
    switch (action.type) {
        
        
        case CommonAction.SET_COUNTRY:{
            return {
                ...state, country_id: action.payload,
            };
        }
        
        default:
            return state;
    }

}

export default Home;