import * as CommonAction from "../constants/types.js";
import cookie from "react-cookies";
const Company = (state = {}, action) => {
  switch (action.type) {
    case CommonAction.GET_COMPANY_CONVERSATIONS: {
      return { ...state, c_conversation: action.payload };
    }
    case CommonAction.GET_C_USER_MESSAGE: {
      return { ...state, c_user_message: action.payload };
    }
    case CommonAction.GET_C_CANDIDATE_INFO: {
      return { ...state, c_candidate_info: action.payload };
    }
    case CommonAction.GET_C_CANDIDATE_APPLIED: {
      return { ...state, c_candidate_applied: action.payload };
    }
    case CommonAction.GET_C_CANDIDATE_APPLIED_RESULT: {
      return { ...state, c_candidate_applied_result: action.payload };
    }
    case CommonAction.GET_C_CANDIDATE_POSTS: {
      return { ...state, c_candidate_posts: action.payload };
    }
    case CommonAction.GET_C_CANDIDATE_COMMENTS: {
      return { ...state, c_candidate_comments: action.payload };
    }
    case CommonAction.GET_COMPANY_SELECT: {
      return { ...state, company_select: action.payload };
    }
    case CommonAction.GET_COMPANY_PLACEMENTS: {
      return { ...state, company_placements: action.payload };
    }
    case CommonAction.GET_COMPANY_MIRROR_ROOMS: {
      return { ...state, company_rooms: action.payload };
    }
    case CommonAction.GET_COMPANY_POSITIONS: {
      return { ...state, company_positions: action.payload };
    }
    case CommonAction.GET_COMPANY_HIRINGS: {
      return { ...state, company_hirings: action.payload };
    }
    case CommonAction.GET_TEST_QUESTIONS: {
      return { ...state, test_questions: action.payload };
    }
    case CommonAction.GET_CANDIDATE_MIRROR_ROOM_INFO: {
      return { ...state, room_info: action.payload };
    }
    case CommonAction.GET_APPLIED_CANDIDATES: {
      return { ...state, applied_candidates: action.payload };
    }
    case CommonAction.COMPANY_DAILY_USERS: {
      return { ...state, daily_users: action.payload };
    }
    case CommonAction.COMPANY_DAILY_INTERVIEW_PRACTICE: {
      return { ...state, daily_interview_practice: action.payload };
    }
    case CommonAction.COMPANY_TOP_USERS: {
      return { ...state, c_top_users: action.payload };
    }
    case CommonAction.COMPANY_LEADERBOARD_HIRINGS: {
      return { ...state, c_leaderboard_hirings: action.payload };
    }
    case CommonAction.GET_COMPANY_TRAINING_INFO: {
      return { ...state, c_training_info: action.payload };
    }
    case CommonAction.GET_TRAINING_COACH_RESULT: {
      return { ...state, c_training_result: action.payload };
    }
    case CommonAction.GET_DATE_WISE_ANALYSIS: {
      return { ...state, c_datewise_analysis: action.payload };
    }
    case CommonAction.GET_CANDIDATE_LEADERBOARD: {
      return { ...state, c_candidate_leaderboard: action.payload };
    }
    case CommonAction.COMPANY_ACCOUNT_OVERVIEW: {
      return { ...state, c_company_account_overview: action.payload };
    }
    case CommonAction.LIST_OF_STUDENTS: {
      return { ...state, c_list_of_students: action.payload };
    }
    case CommonAction.GET_CANDIDATE_LEADERBOARD_CHART: {
      return { ...state, c_candidate_leaderboard_chart: action.payload };
    }
    case CommonAction.GET_TOP_ROLES_PRACTICED: {
      return { ...state, c_top_roles_practiced: action.payload };
    }
    case CommonAction.GET_PREVIOUS_PRACTICED_DATA: {
      return { ...state, c_previous_practiced_data: action.payload };
    }
    default:
      return state;
  }
};
export default Company;
