import React, { useEffect, useRef } from 'react'
import { useAlert } from "react-alert";

export default function CreateNotification({message}) {
    // console.log(message);
    const alert = useAlert();
    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            alert.success(<div style={{ color: 'white',marginRight:'50px',textTransform: 'capitalize' }}>{message}</div>);
          } else {
            // do componentDidUpdate logic
            alert.success(<div style={{ color: 'white',marginRight:'50px',textTransform: 'capitalize' }}>{message}</div>);
          }
        
    }, [message]);
    
    // alert.success(<div style={{ color: 'white',marginRight:'50px',textTransform: 'capitalize' }}>{message}</div>)    
    return (
        <div>
        </div>
    )
}
