// The basics
import React, { Component } from "react";
import { connect, connectAdvanced } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

// Action creators and helpers
import { establishCurrentUser } from "../modules/auth";
import createStore from "../store";

// import Header from './header';
import Routes from "./routes";
import Header from "../components/Header/header";
import HeaderMobile from "../components/Header/header_mobile";

import Footer from "../components/Footer";
import SideBar from "../components/Sidebar/";
import socketIOClient from "socket.io-client";
import { API_HOST, WEBSITE_URL } from "../config/config";
import CreateNotification from "../components/createnotification";
import { makeid } from "../config/functions";
import {
  getDomainData,
  getNotification,
  onWebsiteAlerts
} from "../actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faQuestionCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/pop-modal/Modal";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
// import CookieConsentBanner from "./CookieConsent";

// import { AUTHENTICATE_CANDIDATE,CANDIDATE_TOKEN_SET } from '../constants/types';
// import {getCandidateProfile} from "../actions/auth";
// import {getCoachProfile,getComapnyProfile} from "../actions/coach";
// import axios from '../config/api';
// import cookie from 'react-cookies';

// const { store, history } = createStore();
// const candidate_token = cookie.load('token')
// if (candidate_token) {
//     axios.defaults.headers.user = candidate_token;
//     store.dispatch({type: AUTHENTICATE_CANDIDATE, payload: true })
//     store.dispatch({type: CANDIDATE_TOKEN_SET, payload: candidate_token })
//     store.dispatch(getCandidateProfile());
// }

// import './app.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: false,
      widgetShow: true,
      alert: false,
      loading: false,
      domain_err: false
    };
  }

  // componentDidMount() {
  //   var _this = this;
  //   // onWebsiteAlerts((err,data)=>{
  //   //   if(data && data.length > 0){
  //   //     _this.setState({alert:data[0].message})
  //   //   }
  //   // })
  //   var domain = window.location.host;
  //   if (domain !== WEBSITE_URL) {
  //     _this.setState({ loading: true });
  //     this.props.getDomainData({ domain: domain }, (err, res) => {
  //       if (res.error) {
  //         _this.setState({ domain_err: true });
  //       }
  //       _this.setState({ loading: false });
  //     });
  //   }

  //   // _this.handleNotification();
  //   // this.interval = setInterval(() => {
  //   //   _this.handleNotification()
  //   // }, 300000);
  // }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleNotification() {
    var _this = this;
    _this.props.getNotification(function(err, res) {
      _this.setState({
        message: res.message
      });
    });
  }
  // componentWillMount() {
  //   if (!isServer) {
  //     this.props.establishCurrentUser();
  //   }
  // }
  // const [sidebarIsOpen, setSidebarOpen] = useState(true);
  // const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  render() {
    var _this = this;
    var { widgetShow, alert, loading, domain_err } = this.state;
    var { isAuthenticated, location, history } = this.props;

    if (loading) {
      return "loading...";
    }
    // if (domain_err) {
    //   return "domain error";
    // }
    var PageClassName = isMobile ? "main_page" : "main_page p-4";
    return (
      <React.Fragment>
        {this.state.message ? (
          <CreateNotification message={this.state.message} />
        ) : null}
        <div className="d-flex h-100">
          {!isMobile && isAuthenticated ? (
            <SideBar
              toggle={1}
              isOpen={1}
              history={history}
              location={location}
            />
          ) : null}
          <div className="main_container" id="scroller">
            {isMobile ? <HeaderMobile /> : <Header />}
            <div className={PageClassName}>
              <Routes />
            </div>
            <Footer />
            {/* <Modal />      */}
            {/* <CookieConsentBanner /> */}
            {/* {!isMobile && alert?<div className='widget-alert'>
                <div className='widget-body'>
                  <div className={widgetShow?'widget-show':' widget-hide '}>
                      <p className='fs-16 mb-0 d-flex-inline' dangerouslySetInnerHTML={{__html: alert }} ></p>
                  </div>
                  <div onClick={()=>{
                    _this.setState({
                      widgetShow:!_this.state.widgetShow
                    })
                  }}>
                    
                    <FontAwesomeIcon swapOpacity={true} size="2x" icon={widgetShow?faTimes:faQuestion}/>
                  </div>
                </div>
            </div>:null} */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.authenticated
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { establishCurrentUser, getNotification, onWebsiteAlerts, getDomainData },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
