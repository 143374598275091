import * as CommonAction  from '../constants/types.js';
// import axios_origin from 'axios';
import axios from '../config/api';
import cookie from 'react-cookies';
import {getCourseReviews, getHiringInfo} from "./auth";
import * as AuthAction  from "./auth";

var cookie_options = {
    path: '/',
    // cookie_expires,
    // maxAge: 1000,
    // secure: true  
}




export const coachRegister = (data,cb) => {
    return (dispatch) => {
        axios.post('/coach/register', data).then((response) => {
        console.log("data:", response);
        if (response.data.error) {
            cb(response.data.error,null)
        }else{
            cb(null,response.data)
        }
        }).catch(function (error) {
        });
    }
};
export const coachSignIn = (cData,callback) => {
    return (dispatch) => {
        axios.post('/coach/signin', cData).then(({data}) => {
            console.log("data==",data)
            
                if(data.error && data.error!== ''){
                    if(callback){
                        callback(data.error,'');
                    }
                }else {
                    if(data.user_otp){
                        if(callback){
                            callback(null,data);
                        }
                    }else{
                        dispatch({type: CommonAction.COACH_TOKEN_SET, payload: data.user});
                        dispatch({type: CommonAction.AUTHENTICATE_COACH, payload: true});
                        cookie.save('member', data.user, cookie_options)
                        axios.defaults.headers.member = data.user
                        dispatch( getCoachProfile() );
                        if(callback){
                            callback(null,data);
                        }
                    }
                }
            
        }).catch(function (error) {
        });
    }
};
export const coachOtpCheck = (cData,callback) => {
    return (dispatch) => {
        axios.post('/check_coach_otp', cData).then(({data}) => {
            console.log("data==",data)
            if(data.error && data.error!== ''){
                if(callback){
                    callback(data.error,'');
                }
            }else {
                dispatch({type: CommonAction.COACH_TOKEN_SET, payload: data.user});
                dispatch({type: CommonAction.AUTHENTICATE_COACH, payload: true});
                cookie.save('member', data.user, cookie_options)
                axios.defaults.headers.member = data.user
                dispatch( getCoachProfile() );
                if(callback){
                  callback(null,data);
                }
            }
        }).catch(function (error) {
        });
    }
};

export const coachGoogleSignIn = (formData,callback) => {
    return (dispatch) => {
        axios.post('/coach/google_login', formData).then(({data}) => {
            // console.log("data==",data)
            if(data.error && data.error!== ''){
                if(callback){
                    callback(data.error,null);
                }
            }else {
                dispatch({type: CommonAction.COACH_TOKEN_SET, payload: data.user});
                dispatch({type: CommonAction.AUTHENTICATE_COACH, payload: true});
                cookie.save('member', data.user, cookie_options)
                axios.defaults.headers.member = data.user
                dispatch( getCoachProfile() );
                if(callback){
                  callback(null,data);
                }
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachProfile = (callback) => {
    return (dispatch) => {
        console.log('getCoachProfile**************');
        axios.get('coach_profile').then(({data}) => {
            dispatch({type: CommonAction.GET_COACH, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCoachAuth = (callback) => {
    return dispatch => 
        axios.get('coach_profile')
        .then(({data})=> data && data.id?{auth:true}:{auth:false})
        .catch(function (error) {
        });
    
};


export const updateCoachProfile = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/update',data).then(({data}) => {
            console.log(data);
            if(callback){
                callback(null,data)
            }
            dispatch(getCoachProfile());
        }).catch(function (error) {
        });
    }
};
export const updateCoachPhone = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/update_phone',data).then(({data}) => {
            console.log(data);
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const verifyCoachPhoneOtp = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/verify_otp',data).then(({data}) => {
            console.log(data);
            if(data.success){
                dispatch(getCoachProfile());
            }
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const updateCoachFees = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/update_fees',data).then(({data}) => {
            console.log(data);
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getRelatedMembers = (data,callback) => {
    return (dispatch) => {
        axios.post('related_members',data).then(({data}) => {
            dispatch({type: CommonAction.GET_RELATED_RESULT, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachInvitations = (data,callback) => {
    return (dispatch) => {
        axios.post('coach_invitaions',data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_INVITATIONS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const acceptCoachInvitations = (id,callback) => {
    return (dispatch) => {
        axios.get('accept_invitaions/'+id).then(({data}) => {
            dispatch(getCoachInvitations());
            dispatch(getCoachMembers());
            
        }).catch(function (error) {
        });
    }
};
export const rejectCoachInvitations = (id,callback) => {
    return (dispatch) => {
        axios.get('reject_invitaions/'+id).then(({data}) => {
            dispatch(getCoachInvitations());
        }).catch(function (error) {
        });
    }
};

export const getCoachMembers = (data,callback) => {
    return (dispatch) => {
        axios.post('coach_members',data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_MEMBERS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachMemberInfo = (id,callback) => {
    return (dispatch) => {
        axios.get(`coach_member_info/${id}`).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};

export const addMemberTask = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_member_task`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const editMemberTask = (data,callback) => {
    return (dispatch) => {
        axios.post(`edit_member_task`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachMemberTasks = (data,callback) => {
    return (dispatch) => {
        axios.post(`coach_member_tasks`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_MEMBER_TASKS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachTaskInfo = (id,callback) => {
    return (dispatch) => {
        axios.get(`coach_member_task_info/${id}`).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_TASK_INFO, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addMeetingSchedule = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_coach_meeting_schedule`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const editMeetingSchedule = (data,callback) => {
    return (dispatch) => {
        axios.post(`edit_coach_meeting_schedule`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getMeetingSchedule = (data,callback) => {
    return (dispatch) => {
        axios.post(`coach_meeting_schedule`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_MEETING_SCHEDULE, payload: data});
        }).catch(function (error) {
        });
    }
};


export const createClass = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_class`,data).then(({data}) => {
            callback(null,data)
            dispatch(getCoachClasses({}));
        }).catch(function (error) {
        });
    }
};

export const getCoachClasses = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_classes`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_CLASSES, payload: data});
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const addCandidateToClass = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_candidate_class`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachClassInfo = (id,callback) => {
    return (dispatch) => {
        axios.get(`coach_class_info/${id}`).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_CLASS_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};

/* ---Notes--- */

export const getCoachNotes = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/get_notes',data).then(({data}) => {
            dispatch({type: CommonAction.GET_CANDIDATE_NOTES, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addCoachNotes = (ndata,callback) => {
    return (dispatch) => {
        axios.post('/coach/add_notes',ndata).then(({data}) => {
            // console.log(data);
            dispatch(getCoachNotes(ndata))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const getCoachVerification = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach/verification',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const acceptMeeting = (id,callback) => {
        axios.get(`/accept_meeting/${id}`).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
};
export const rejectMeeting = (id,callback) => {
        axios.get(`/reject_meeting/${id}`).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
};

export const addClassMember = (cdata,callback) => {
    return (dispatch) => {
        axios.post(`add_class_member`,cdata).then(({data}) => {
            callback(null,data)
            dispatch(getCoachClassInfo(cdata.class_id))
            
        }).catch(function (error) {
        });
    }
};
export const fetchCoachPackages = (callback) => {
    return (dispatch) => {
        axios.get(`fetch-coach-packages`).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_PACKAGES, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export function CreateCheckoutSession(data,callback) {
    axios.post(`/create-checkout-session`,data).then(({data}) => {
        callback(null,data)
    }).catch(function (error) {
        // console.log("Error****:", error.message);
    });
};
// export const getCoachCandidate = (data,callback) => {
//     return (dispatch) => {
//         axios.post('fetch_candidates',data).then(({data}) => {
//             dispatch({type: CommonAction.GET_COACH_CANDIDATES, payload: data});
//         }).catch(function (error) {
//         });
//     }
// };


// export const getCoachCandidateColumns = (column) => {
//     return (dispatch) => {
//         axios.post('candidates/get_unique_values/'+column).then(({data}) => {
//             dispatch({type: CommonAction['GET_COACH_CANDIDATE_'+column.toUpperCase()], payload: data});
//         }).catch(function (error) {
//         });
//     }
// };

export const importClassMember = (cdata,class_id,callback) => {
    return (dispatch) => {
        axios.post(`/import_class_member`,cdata).then(({data}) => {
            callback(null,data)
            dispatch(getCoachClassInfo(class_id))
        }).catch(function (error) {
        });
    }
};




export const getCoachHirings = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_hirings`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_HIRINGS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const addHiringToClass = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_hiring_to_class`,data).then(({data}) => {
            callback(null,data)
            dispatch(getCoachHirings());
        }).catch(function (error) {
        });
    }
};



/** company */

export const companyRegister = (data,cb) => {
    return (dispatch) => {
        axios.post('/company/register', data).then((response) => {
        console.log("data:", response);
        if (response.data.error) {
            cb(response.data.error,null)
        }else{
            cb(null,response.data)
        }
        }).catch(function (error) {
        });
    }
};

export const getComapnyVerfication = (data,callback) => {
    return (dispatch) => {
        axios.post('/company/verification',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const companySignIn = (data,callback) => {
    return (dispatch) => {
        axios.post('/company/signin', data).then(({data}) => {
            console.log("data==",data)
            if(data.error && data.error!== ''){
                if(callback){
                    callback(data.error,null);
                }
            }else {
                dispatch({type: CommonAction.COMAPNY_TOKEN_SET, payload: data.user});
                dispatch({type: CommonAction.AUTHENTICATE_CANDIDATE, payload: true});
                cookie.save('company', data.user, cookie_options)
                axios.defaults.headers.company=data.user
                dispatch( getComapnyProfile() );
                if(callback){
                  callback(null,data);
                }
            }
        }).catch(function (error) {
        });
    }
};

export const getComapnyProfile = (callback) => {
    return (dispatch) => {
        axios.get('company_profile').then(({data}) => {
            console.log(data);
            if(data && data.id){
                dispatch({type: CommonAction.GET_COMPANY, payload: data});
                dispatch({type: CommonAction.AUTHENTICATE_CANDIDATE, payload: true});
            }
        }).catch(function (error) {
        });
    }
};


export const updateComapnyProfile = (data,callback) => {
    return (dispatch) => {
        axios.post('/company/update',data).then(({data}) => {
            console.log(data);
            if(callback){
                callback(null,data)
            }
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};
export const updateComapnyPhone = (data,callback) => {
    return (dispatch) => {
        axios.post('/company/update_phone',data).then(({data}) => {
            console.log(data);
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const verifyComapnyPhoneOtp = (data,callback) => {
    return (dispatch) => {
        axios.post('/company/verify_otp',data).then(({data}) => {
            console.log(data);
            if(data.success){
                dispatch(getComapnyProfile());
            }
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};


export const addCompanyCoach = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_company_coach`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const EditCompanyCoach = (data,callback) => {
    return (dispatch) => {
        axios.post(`edit_company_coach`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const addCompanyHiring = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_company_hiring`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const editCompanyHiring = (data,callback) => {
    return (dispatch) => {
        axios.post(`edit_company_hiring`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const addFeedbackForm = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_feedback_form`,data).then(({data}) => {
            callback(null,data)
            // dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const getCoachFeedbackForms = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_feedbacks`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_FEEDBAKFORMS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const addTest = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_test`,data).then(({data}) => {
            callback(null,data)
            // dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};
export const getClassFeedbacks = (data,callback) => {
    console.log(data);
    return (dispatch) => {
        axios.post(`class_feedbacks`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_CLASS_FEEDBAKCS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getFeedbacksInfo = (feedback_id,callback) => {
    return (dispatch) => {
        axios.get(`feedback_info/${feedback_id}`).then(({data}) => {
            dispatch({type: CommonAction.GET_FEEDBACK_INFO, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const updatePublicState = (fData,callback) => {
    return (dispatch) => {
        axios.post(`public_state_update`,fData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getFeedbackForms = (data,callback) => {
    console.log(data);
    return (dispatch) => {
        axios.post('/coach_feedback_forms',data).then(({data}) => {
            // console.log(data);
            dispatch({type:CommonAction.GET_COACH_FFORMS,payload:data})
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addFeedbackAnswers = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/coach_feedback_answer',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const setTestFormData = (data,callback) => {
    return (dispatch) => {
        dispatch({type: CommonAction.TEST_FORM, payload: data});
        if(callback){
            callback(null,true)
        }
    }
};
export const setHiringFormData = (data,callback) => {
    return (dispatch) => {
        dispatch({type: CommonAction.HIRING_FORM, payload: data});
        if(callback){
            callback(null,true)
        }
    }
};


export const getCoachTests = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/coach_tests',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_TEST, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getTestInfo = (key,callback) => {
    return (dispatch) => {
        axios.get('/test_info/'+key).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_TEST_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getUserResult = (form_key,candidate_id,callback,hiring_id) => {
    return (dispatch) => {
        var bdata = {
            form_key,
            candidate_id,
        }
        if(hiring_id){
            bdata.hiring_id = hiring_id
        }
        axios.post('/get_candidate_test_result',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_TEST_USER_RESULT, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getClassTests = (data,callback) => {
    return (dispatch) => {
        axios.post('/class_tests',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_CLASS_TESTS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const setTestPage=(pageid,callback)=>{
    return (dispatch) => {
        dispatch({type: CommonAction.SET_TEST_PAGE, payload: pageid});
    }
}
export const setTestType=(test_type,callback)=>{
    return (dispatch) => {
        dispatch({type: CommonAction.SET_TEST_TYPE, payload: test_type});
    }
}

export const updateTestInfo = (data,callback) => {
    return (dispatch) => {
        axios.post(`/coach/update/test_info`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
        }).catch(function (error) {
        });
    }
};
export const updateTestQuestion = (data,callback) => {
    return (dispatch) => {
        axios.post(`/coach/test/question/update`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addHiringTests = (data,callback) => {
    return (dispatch) => {
        axios.post('/add_hiring_tests',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};
export const updateManager = (data,callback) => {
    return (dispatch) => {
        axios.post('/update_manager',data).then(({data}) => {
            dispatch(getComapnyProfile());
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};

export const addCompanyDepartment = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_department`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const editCompanyDepartment = (data,callback) => {
    return (dispatch) => {
        axios.post(`update_department`,data).then(({data}) => {
            callback(null,data)
            dispatch(getComapnyProfile());
        }).catch(function (error) {
        });
    }
};

export const deleteCompanyDepartment = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_department`,data).then(({data}) => {
            dispatch(getComapnyProfile());
            callback(null,data)
        }).catch(function (error) {
        });
    }
};

export const getCompanyHiringInfo = (key,callback) => {
    return (dispatch) => {
        axios.get('/coach_hiring_info/'+key).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_HIRING_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCompanyHiringCandidates = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/coach_hiring_candidate',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_HIRING_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};
/** coach */

export const getCoachRoundHirings = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_round_hirings`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_ROUND_HIRINGS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const addHiringTestsToRound = (data,callback) => {
    return (dispatch) => {
        axios.post('/add_hiring_rounds_tests',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};

export const addHiringRoundMeeting = (mData,callback) => {
    return (dispatch) => {
        axios.post(`add_hiring_rounds_meetings`,mData).then(({data}) => {
            dispatch(getHiringInfo(mData.hiring_key))
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCoachRounds = (callback) => {
    return (dispatch) => {
        axios.post('/coach/test/rounds').then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.COACH_HIRINGS_ROUNDS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const updateRoundTest = (formData,callback) => {
    return (dispatch) => {
        axios.post('/coach/test/assign/rounds',formData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addSelectedHiringCandidates = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_selected_candidate`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const rejectHiringCandidates = (data,callback) => {
    return (dispatch) => {
        axios.post(`reject_hiring_candidate`,data).then(({data}) => {
            callback(null,data)
            // dispatch({type: CommonAction.GET_COACH_MEMBER_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};



/** company prejoining */
export const getCompanyPrejoinedCandidates = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_prejoined_candidates`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COMPANY_PREJOINING_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};

export const sendPrejoinedOfferLatter = (data,callback) => {
    return (dispatch) => {
        axios.post(`send_prejoined_latter`,data).then(({data}) => {
            callback(null,data)
            dispatch(getCompanyPrejoinedCandidates());
        }).catch(function (error) {
        });
    }
};
export const assignPrejoinCoach = (data,callback) => {
    return (dispatch) => {
        axios.post(`assign_prejoin_coach`,data).then(({data}) => {
            callback(null,data)
            dispatch(getCompanyPrejoinedCandidates());
        }).catch(function (error) {
        });
    }
};


/** Coach prejoining */
export const getCoachPrejoinedCandidates = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_prej_candidates`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COMPANY_PREJOINING_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCoachPrejoinedClasses = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_prej_classes`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_PREJOINING_CLASSES, payload: data});
        }).catch(function (error) {
        });
    }
};
export const assignPrejoinedClass = (data,callback) => {
    return (dispatch) => {
        axios.post(`assign_prejoined_class`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getCoachPrejoinedCandidates());
            dispatch(getCoachPrejoinedClasses());
            
        }).catch(function (error) {
        });
    }
};

export const getCoachDocuments = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_coach_documents`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_DOCUMENTS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const addCoachDocuments = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_coach_documents`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getCoachDocuments({}));
        }).catch(function (error) {
        });
    }
};
export const addCoachDocumentGroup = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_document_group`,data).then(({data}) => {
            dispatch(getCoachDocumentGroups({}))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachDocumentGroups = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_document_groups`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_DOCUMENT_GROUPS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const deleteCoachDocument = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_document`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};


export const getCompanyForms = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_company_forms`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COMPANY_FORMS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const addCompanyForms = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_company_forms`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getCompanyForms());
        }).catch(function (error) {
        });
    }
};

export const delete_company_forms = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_company_forms`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getCompanyForms());
        }).catch(function (error) {
        });
    }
};




/** company doc */
export const getCompanyDocuments = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_company_documents`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_DOCUMENTS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const addCompanyDocuments = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_company_documents`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
            dispatch(getCompanyDocuments());
            dispatch(getCompanyDocumentGroups());

        }).catch(function (error) {
        });
    }
};
export const addCompanyDocumentGroup = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_company_document_group`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCompanyDocumentGroups = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_company_document_groups`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_DOCUMENT_GROUPS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const deleteCompanyDocument = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_company_document`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const getOnboardingSteps = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_onboarding_steps`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_ONBOARDING_STEPS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const addOnboardingSteps = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_onboarding_steps`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};

export const addOnboardingStepItem = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_onboarding_steps_item`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};
export const deleteStepItem = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_onboarding_steps_item`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};

export const deleteOnboardingStep = (data,callback) => {
    return (dispatch) => {
        axios.post(`delete_onboarding_steps`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};


export const changeStepSortOrder = (data,callback) => {
    return (dispatch) => {
        axios.post(`change_onboarding_steps`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};
export const changeStepItemSortOrder = (data,callback) => {
    return (dispatch) => {
        axios.post(`change_onboarding_steps_item`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch(getOnboardingSteps());
        }).catch(function (error) {
        });
    }
};



export const getDocumentAnalytics = (data,callback) => {
    return (dispatch) => {
        axios.post(`view_company_document_analytics`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const getFormAnalytics = (data,callback) => {
    return (dispatch) => {
        axios.post(`get_form_analytics`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_COMPANY_FORM_INFO, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const updateHiringCandidateStatuss = (data,callback) => {
    return (dispatch) => {
        axios.post(`update_hiring_candidates_status`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const setPassword = (data,callback) => {
    return (dispatch) => {
        axios.post(`/coach/set_password`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const checkCodeExist = (data,callback) => {
    return (dispatch) => {
        axios.post(`/coach/check_code`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};


export const getCoachGivenFeedbacks = (data,callback) => {
    return (dispatch) => {
        axios.post('/coach_given_feedbacks',data).then(({data}) => {
            // console.log(data);
            dispatch({type:CommonAction.GET_CANDIDATE_FEEDBACK,payload:data})
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachAllGivenFeedback = (data,callback) => {
    return (dispatch) => {
        axios.post(`coach_given_all_feedbacks`,data).then(({data}) => {
            dispatch({type: CommonAction.GET_ALL_GIVEN_FEEDBAKS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const updateHiringInfo = (hData,callback) => {
    return (dispatch) => {
        axios.post(`/company/update_hiring_info`,hData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const updateRoundInfo = (hData,callback) => {
    return (dispatch) => {
        axios.post(`/company/update_round_info`,hData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};


export const importPreJoinMember = (cdata,callback) => {
    return (dispatch) => {
        axios.post(`/import_prejoin_member`,cdata).then(({data}) => {
            dispatch(getCompanyPrejoinedCandidates({
                type:'prejoined'
            }))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getOpenRooms = (rData,callback) => {
    return (dispatch) => {
        axios.post(`/mentor_open_rooom`,rData).then(({data}) => {
            dispatch({ type: CommonAction.GET_MENTOR_OPEN_ROOMS, payload: data });
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachMessagesUsers = (data,callback) => {
    return (dispatch) => {
        axios.post('/get_coach_messages_user',data).then(({data}) => {
            // console.log(data);
            dispatch({type:CommonAction.GET_COACH_MESSAGE_USER,payload:data})
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getUserMessages = (data,callback) => {
    return (dispatch) => {
        axios.post('/get_user_messages',data).then(({data}) => {
            // console.log(data);
            dispatch({type:CommonAction.GET_USER_MESSAGE,payload:data})
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const sendCandidateMessage = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/msg_candidate`,filter).then(({data}) => {
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const getMirrorRoomQuestions = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/company/mirror_room_questions`,filter).then(({data}) => {
            dispatch({ type: CommonAction.GET_MIRROR_ROOM_QUESTION, payload: data });
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const addMirrorRoom = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/company/add_mirror_room`,filter).then(({data}) => {
            dispatch(getMirrorRooms(false))
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const setPositionFormType = (position_type,callback) => {
    return (dispatch) => {
        dispatch({ type: CommonAction.SET_POSITION_FORM_TYPE, payload: position_type });
    }
};
export const getMirrorRooms = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/company/get_mirror_rooms`,filter).then(({data}) => {
            dispatch({ type: CommonAction.GET_COMPANY_MIRROR_ROOMS, payload: data });
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};

export const assignHiringMirrorRoom = (tTata,hiring_key,callback) => {
    return (dispatch) => {
        axios.post('/assign_mirror_rooms',tTata).then(({data}) => {
            dispatch(getHiringInfo(hiring_key))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};





/** university */

export const getCoachCandidate = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidates',data).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};


export const getCoachCandidateColumns = (column) => {
    return (dispatch) => {
        axios.post('candidates/get_unique_values/'+column).then(({data}) => {
            dispatch({type: CommonAction['GET_COACH_CANDIDATE_'+column.toUpperCase()], payload: data});
        }).catch(function (error) {
        });
    }
};


export const importCandidates = (cdata,callback) => {
    return (dispatch) => {
        axios.post(`/import_coach_candidates`,cdata).then(({data}) => {
            callback(null,data)
            dispatch(getCoachCandidate())
        }).catch(function (error) {
        });
    }
};

export const addCoachCandidate = (cdata,callback) => {
    return (dispatch) => {
        axios.post(`add_coach_candidate`,cdata).then(({data}) => {
            callback(null,data)
            dispatch(getCoachCandidate())
        }).catch(function (error) {
        });
    }
};

export const addCompanyEvents = (cdata,callback) => {
    return (dispatch) => {
        axios.post(`add_company_events`,cdata).then(({data}) => {
            callback(null,data)      
            dispatch(getCompanyEvents())      
        }).catch(function (error) {
        });
    }
};


export const getCompanyEvents = (data,callback) => {
    return (dispatch) => {
        axios.post('get_company_events',data).then(({data}) => {
            dispatch({type: CommonAction.GET_COMPANY_EVENTS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCCandidateMirrorRooms = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidate_mirror_rooms',data).then(({data}) => {
            dispatch({type: CommonAction.GET_CANDIDATE_MIRROR_ROOMS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCCandidateHirings = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidate_hirings',data).then(({data}) => {
            dispatch({type: CommonAction.GET_CANDIDATE_HIRINGS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCStudentTests = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidate_tests',data).then(({data}) => {
            dispatch({type: CommonAction.GET_CANDIDATE_TEST, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCStudentPosts = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidate_community_posts',data).then(({data}) => {
            dispatch({type: CommonAction.GET_COMMUNITY_POSTS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCStudentComments = (data,callback) => {
    return (dispatch) => {
        axios.post('fetch_candidate_community_comments',data).then(({data}) => {
            dispatch({type: CommonAction.COMMUNITY_POST_COMMENTS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCompanyDepartments = (data,callback) => {
    return (dispatch) => {
        axios.post('get_company_departments',data).then(({data}) => {
            dispatch({type: CommonAction.COACH_DEPARTMENTS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getUniversityStudents = (data,callback) => {
    return (dispatch) => {
        axios.post('get_university_student',data).then(({data}) => {
            dispatch({type: CommonAction.COACH_STUDENTS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getUniversityHirings = (data,callback) => {
    return (dispatch) => {
        axios.post('get_university_hiring',data).then(({data}) => {
            dispatch({type: CommonAction.UNIVERSITY_HIRINGS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getUniversityTests = (data,callback) => {
    return (dispatch) => {
        axios.post('get_university_tests',data).then(({data}) => {
            dispatch({type: CommonAction.UNIVERSITY_TESTS, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getUniversityRooms = (data,callback) => {
    return (dispatch) => {
        axios.post('/get_university_rooms',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_HIRING_ROOMS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getAssignedHirings = (data,callback) => {
    return (dispatch) => {
        axios.post('/get_assigned_hirings',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_ASSIGNED_HIRINGS, payload: data});
        }).catch(function (error) {
        });
    }
};
export const assignO2ORoom = (tTata,hiring_key,callback) => {
    return (dispatch) => {
        axios.post('/assign_coach_room_candidate',tTata).then(({data}) => {
            // dispatch(getHiringInfo(hiring_key))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachHiringMeetings = (data,callback) => {
    return (dispatch) => {
        axios.post('/get_coach_hiring_meetings',data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_HIRINGS_COACH_MEETINGS, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getHiringMeetingNotes = (data,callback) => {
    return (dispatch) => {
        axios.post('/hiring/meeting/coach/get_notes',data).then(({data}) => {
            dispatch({type: CommonAction.GET_HIRING_MEETING_CANDIDATE_NOTES, payload: data});
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addHiringMeetingNotes = (ndata,callback) => {
    return (dispatch) => {
        axios.post('/hiring/meeting/coach/add_notes',ndata).then(({data}) => {
            // console.log(data);
            dispatch(getCoachNotes(ndata))
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

/*  company hiring  */
export const getCompanyHiringFilterCandidates = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/filter_hiring_candidate/',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_HIRING_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};

/*  company hiring  */
export const getDashboardApplicantAnalytics = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/dashboard/applicants_analytics',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};

export const getDashboardSelectedCandidates = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/dashboard/selected_candidate_analytics',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};
export const getDashboardMirrorRoomCandidate = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/dashboard/getCompanyMirrorRoomCandidates',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }            
        }).catch(function (error) {
        });
    }
};

export const getFilteredRoomCandidate = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/get_filtered_room_candidates',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_FILTERED_CANDIDATES, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCompanyCandidateInfo = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/get_candidate_info',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COMPANY_CANDIDATE_INFO, payload: data});
        }).catch(function (error) {
        });
    }
};
export const createNewCourse = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/create',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const updateCourse = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/update',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCourses = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/courses',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCourseInfo = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course_info',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};


export const addCourseItems = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/add_item',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};
export const updateCourseItems = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/update_item',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};

export const getCourseItems = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/coutse_items',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};
export const getCourseDocuments = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/get_document',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            // dispatch({type: CommonAction.GET_COACH_COURSES, payload: data});
        }).catch(function (error) {
        });
    }
};


export const createNewDocument = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/create_document',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};


export const addCoachMirrorRoom = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/coach/add_mirror_room`,filter).then(({data}) => {
            // dispatch(getMirrorRooms(false))
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const getMirrorRoomInfo = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/company/get_mirror_room_info`,filter).then(({data}) => {
            // dispatch(getMirrorRooms(false))
            dispatch({type: CommonAction.GET_COACH_MIRROR_ROOM_INFO, payload: data});
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const updateMirrorRoomQuestion = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/update_room_question`,filter).then(({data}) => {
            // dispatch(getMirrorRooms(false))
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const changeCourseItemOrder = (bdata,callback) => {
    return (dispatch) => {
        axios.post(`change_course_item_order`,bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
        }).catch(function (error) {
        });
    }
};
export const createCourseBundle = (bdata,callback) => {
    return (dispatch) => {
        axios.post(`course/bundle/create`,bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
        }).catch(function (error) {
        });
    }
};
export const applyForReviewCourse = (bdata,callback) => {
    return (dispatch) => {
        axios.post(`apply_for_review_course`,bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
        }).catch(function (error) {
        });
    }
};

export const getCourseReviewComments = (id,callback) => {
    return (dispatch) => {
        axios.get(`get_review_course_comments/`+id).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const getCourseBundles = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/get_course_bundles',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({type: CommonAction.GET_COURSE_BUNDLES, payload: data});
        }).catch(function (error) {
        });
    }
};

export function getCourseBundleInfo(pagetitle,callback) {
    return (dispatch) => {
        axios.get(`/bundle_info/${pagetitle}`).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({ type: CommonAction.GET_COURSE_BUNDLE_INFO, payload: data });
        }).catch(function (error) {
            // console.log("Error****:", error.message);
        });
    }
};


export const applyForReviewCourseBundle = (bdata,callback) => {
    return (dispatch) => {
        axios.post(`apply_for_review_bundle`,bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            
        }).catch(function (error) {
        });
    }
};

export const getCourseBundleReviewComments = (id,callback) => {
    return (dispatch) => {
        axios.get(`get_review_bundle_comments/`+id).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addTrialCourseRoom = (data,callback) => {
    return (dispatch) => {
        axios.post(`add_trial_practice`,data).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const startCoursePreBooking = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/start_pre_booking',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const endCoursePreBooking = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/end_pre_booking',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const changeCourseItemStatus = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/update_course_item_status',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const changeCourseItemRoomId = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/update_course_item_room_id',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};

export const addCourseAuthors = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/add_authors',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getCourseAuthors = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/course/get_authors',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const getAssignedCourses = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/assign_courses',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({ type: CommonAction.GET_COACH_ASSIGNED_COURSES, payload: data });
        }).catch(function (error) {
        });
    }
};
export const getAssignedCourseInfo = type => async dispatch => {
    const res = await  axios.get(`/assigned_course_info/${type}`);
    var course_info = res.data;
    if(course_info && course_info.id){
        dispatch(AuthAction.getCourseItems({ pagetitle : course_info.pagetitle }))
        dispatch(getCourseReviews({ course_id : course_info.id }));
    }
    return dispatch({ type: CommonAction.COURSE_INFO, payload: res.data });
};
export const getAssignedCourseItem = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/assigned_course_item_info',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
            dispatch({ type: CommonAction.GET_COACH_ASSIGNED_COURSE_ITEM, payload: data });
        }).catch(function (error) {
        });
    }
};
export const addMirrorRoomCoach = (data,callback) => {
    return (dispatch) => {
        axios.post(`/add_mirror_room_coach`,data).then(({data}) => {
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const getCoachMirrorRoomDetails = (filter,callback) => {
    return (dispatch) => {
        axios.post(`/coach_mirror_room_details`,filter).then(({data}) => {
            dispatch({ type: CommonAction.GET_MIRROR_ROOM_DETAILS, payload: data });
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const updateMirrorRoomCoach = (data,callback) => {
    return (dispatch) => {
        axios.post(`/update_room_coach`,data).then(({data}) => {
            if(callback){
                callback(null,data);
            }
        }).catch(function (error) {
        });
    }
};
export const uploadCoachVideo = (vData,callback) => {
    return (dispatch) => {
        axios.post('/coach_upload_video',vData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
        });
    }
};
export const addMirrorCoachFeedBack = (fData,callback) => {
    return (dispatch) => {
        axios.post('/add_mirror_room_coach_feedback',fData).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        })
    }
};
export const getCoachMirrorRoomInfo = (filter,callback) => {
    return (dispatch) => {
        axios.post('/coach_mirror_room_info',filter).then(({data}) => {
            dispatch({type: CommonAction.GET_ROOM_INFO, payload: data});
            if(callback){
                callback(null,data)
            }

        }).catch(function (error) {
        });
    }
};
export const getCoachPracticeRooms = (filter,callback) => {
    return (dispatch) => {
        dispatch({type: CommonAction.GET_COACH_MIRROR_ROOMS, payload: false});
        axios.post('/coach_mirror_rooms',filter).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_MIRROR_ROOMS, payload: data});
            if(callback){
                callback(null,data)
            }

        }).catch(function (error) {
        });
    }
};
export const getCoachAssignedTraining = (filter,callback) => {
    return (dispatch) => {
        axios.post('/coach_training',filter).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_TRAININGS, payload: data});
            if(callback){
                callback(null,data)
            }

        }).catch(function (error) {
        });
    }
};
export const getCoachTrainingInfo = (filter,callback) => {
    return (dispatch) => {
        axios.post('/get_training_info',filter).then(({data}) => {
            dispatch({type: CommonAction.GET_COACH_TRAINING_INFO, payload: data});
            if(callback){
                callback(null,data)
            }

        }).catch(function (error) {
        });
    }
};
export const addCoachTrainingAnswers = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/add_coach_training_answer',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
            if(callback){
                callback(null,{})
            }
        });
    }
};
export const getTrainingResultInfo = (bdata,callback) => {
    return (dispatch) => {
        axios.post('/training_result_info',bdata).then(({data}) => {
            if(callback){
                callback(null,data)
            }
        }).catch(function (error) {
            if(callback){
                callback(null,{})
            }
        });
    }
};
export const getUniversityCandidates = (data, callback) => {
    return (dispatch) => {
        axios.post('fetch_university_candidate', data).then(({ data }) => {
            dispatch({ type: CommonAction.GET_UNIVERSITY_CANDIDATES, payload: data });
            if (callback) {
                callback(null, data)
            }
        }).catch(function (error) {
        });
    }
};
export const CandidatesPracticeAnalysis = (data, callback) => {
    return (dispatch) => {
        axios.post('/get_candidate_practice_analysis', data).then(({ data }) => {
            if (callback) {
                callback(null, data)
            }
            dispatch({ type: CommonAction.GET_CANDIDATE_PRACTICE_ANALYSIS, payload: data });
        }).catch(function (error) {
        });
    }
};
