import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import 'react-pro-sidebar/dist/css/styles.css';

const SubMenuLinks = (props) => {
  // const [collapsed, setCollapsed] = useState(true);
  // const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items, location } = props;
    // console.log(items.map((t)=>t.target),location.pathname,items.map((t)=>t.target).includes(location.pathname));
    // var mainMenuClass=classNames({ "menu-open": !collapsed });
    // mainMenuClass+=items.map((t)=>t.target).includes(location.pathname)?' active ':'';
    // console.log(mainMenuClass);
    return (
      <SubMenu title={title} icon={<FontAwesomeIcon icon={icon} />}>
          {/* <MenuItem>Component 1</MenuItem>
          <MenuItem>Component 2</MenuItem> */}
          {items.map((item, index) => {
            return (
              <MenuItem key={`submenu-`+index} title="dashboard" icon={<FontAwesomeIcon icon={item.icon?item.icon:faBriefcase} />}>
                {item.title}
              <Link to={item.target} />
            </MenuItem>
            )}
          )}
      </SubMenu>
    )
  // return (
  //   <div>
  //     <NavItem
  //       onClick={toggle}
  //       className={mainMenuClass}
  //     >
  //       <NavLink className="dropdown-toggle">
  //         <FontAwesomeIcon fixedWidth={true} icon={icon} className="mr-4" />
  //         {title}
  //       </NavLink>
  //     </NavItem>
  //     <Collapse
  //       isOpen={!collapsed}
  //       navbar
  //       className={classNames("items-menu", { "mb-1": !collapsed })}
  //     >
  //       {items.map((item, index) => {
  //       return (
  //         <NavItem key={index} className="pl-4">
  //           <NavLink tag={Link} to={item.target} className={location.pathname==item.target?'active':''}>
  //               <FontAwesomeIcon fixedWidth={true} icon={item.icon?item.icon:faBriefcase} className="mr-4" /> {item.title}
  //           </NavLink>
  //         </NavItem>
  //       )}
  //       )}
  //     </Collapse>
  //   </div>
  // );
};

export default SubMenuLinks;
