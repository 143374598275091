import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import Home from './home';
import Auth from './auth';
import Coach from './coach';
import Company from './company';
import auth2 from '../modules/auth';

import profile from '../modules/profile';
const reducers = (history) => combineReducers({
    router: connectRouter(history),
    auth:auth2,
    profile,
    Home,
    Auth,
    Coach,
    Company
});
export default  reducers  