export const SUBSCRIBE = "SUBSCRIBE";
export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const POST_PROMO = "POST_PROMO";
export const GET_QUOTE = "GET_QUOTE";

export const GET_COUNTRY = "GET_COUNTRY";
export const SET_COUNTRY = "SET_COUNTRY";
export const CANDIDATE_TOKEN_SET = "CANDIDATE_TOKEN_SET";
export const GET_CANDIDATE = "GET_CANDIDATE";
export const SIGNOUT_CANDIDATE_SUCCESS = "SIGNOUT_CANDIDATE_SUCCESS";
export const AUTHENTICATE_CANDIDATE = "AUTHENTICATE_CANDIDATE";

export const COACH_TOKEN_SET = "COACH_TOKEN_SET";
export const GET_COACH = "GET_COACH";
export const SIGNOUT_COACH_SUCCESS = "SIGNOUT_COACH_SUCCESS";
export const AUTHENTICATE_COACH = "AUTHENTICATE_COACH";
export const GET_RELATED_RESULT = "GET_RELATED_RESULT";
export const GET_COACH_INVITATIONS = "GET_COACH_INVITATIONS";
export const GET_COACH_MEMBERS = "GET_COACH_MEMBERS";
export const GET_COACH_MEMBER_INFO = "GET_COACH_MEMBER_INFO";
export const GET_COACH_MEMBER_TASKS = "GET_COACH_MEMBER_TASKS";
export const GET_COACH_MEETING_SCHEDULE = "GET_COACH_MEETING_SCHEDULE";
export const GET_COACH_CLASSES = "GET_COACH_CLASSES";
export const GET_COACHES = "GET_COACHES";
export const GET_CANDIDATE_NOTES = "GET_CANDIDATE_NOTES";
export const GET_CANDIDATE_MEETINGS = "GET_CANDIDATE_MEETINGS";
export const GET_CANDIDATE_TASKS = "GET_CANDIDATE_TASKS";
export const GET_COACH_CLASS_INFO = "GET_COACH_CLASS_INFO";
export const GET_COACH_CLASS_TASKS = "GET_COACH_CLASS_TASKS";
export const GET_COACH_CLASS_SCHEDULE = "GET_COACH_CLASS_SCHEDULE";
export const GET_INDUSTRIES = "GET_INDUSTRIES";

export const GET_COACH_TASK_INFO = "GET_COACH_TASK_INFO";
export const GET_MEETING_INFO = "GET_MEETING_INFO";

export const GET_COACH_CANDIDATES = "GET_COACH_CANDIDATES";
export const GET_COACH_CANDIDATE_COMPANY = "GET_COACH_CANDIDATE_COMPANY";
export const GET_COACH_CANDIDATE_SKILLS = "GET_COACH_CANDIDATE_SKILLS";
export const GET_COACH_CANDIDATE_POSITION = "GET_COACH_CANDIDATE_POSITION";
export const GET_COACH_CANDIDATE_LANGUAGE = "GET_COACH_CANDIDATE_LANGUAGE";
export const GET_COACH_CANDIDATE_COLLAGE = "GET_COACH_CANDIDATE_COLLAGE";
export const GET_COACH_CANDIDATE_DEGREE = "GET_COACH_CANDIDATE_DEGREE";

export const GET_CANDIDATE_COACH_COMPANY = "GET_CANDIDATE_COACH_COMPANY";
export const GET_CANDIDATE_COACH_SKILLS = "GET_CANDIDATE_COACH_SKILLS";
export const GET_CANDIDATE_COACH_POSITION = "GET_CANDIDATE_COACH_POSITION";
export const GET_CANDIDATE_COACH_LANGUAGE = "GET_CANDIDATE_COACH_LANGUAGE";
export const GET_CANDIDATE_COACH_COLLAGE = "GET_CANDIDATE_COACH_COLLAGE";
export const GET_CANDIDATE_COACH_DEGREE = "GET_CANDIDATE_COACH_DEGREE";
export const GET_COACHE_INFO = "GET_COACHE_INFO";

export const GET_COACH_HIRINGS = "GET_COACH_HIRINGS";
export const GET_CLASS_HIRINGS = "GET_CLASS_HIRINGS"; //candidate class hirings

/** company */

export const GET_COMPANY = "GET_COMPANY";
export const COMAPNY_TOKEN_SET = "COMAPNY_TOKEN_SET";

export const GET_COMPANY_INFO = "GET_COMPANY_INFO";
export const SIGNOUT_COMPANY_SUCCESS = "SIGNOUT_COMPANY_SUCCESS";

export const GET_CANDIDATE_HIRINGS = "GET_CANDIDATE_HIRINGS";
export const GET_CANDIDATE_APPLIED_HIRINGS = "GET_CANDIDATE_APPLIED_HIRINGS";

export const GET_HIRING_INFO = "GET_HIRING_INFO";
export const GET_HIRING_OTHER_POSITION = "GET_HIRING_OTHER_POSITION";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COACH_PACKAGES = "GET_COACH_PACKAGES";

// feedback
export const GET_COACH_FEEDBAKFORMS = "GET_COACH_FEEDBAKFORMS";
export const GET_CANDIDATE_FEEDBACK_FORMS = "GET_CANDIDATE_FEEDBACK_FORMS";
export const GET_CLASS_FEEDBACK_FORMS = "GET_CLASS_FEEDBACK_FORMS";

export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_OTHER_BLOGS = "FETCH_OTHER_BLOGS";
export const BLOG_INFO_CONTENT = "BLOG_INFO_CONTENT";

export const GET_CLASS_FEEDBAKCS = "GET_CLASS_FEEDBAKCS";
export const GET_FEEDBACK_INFO = "GET_FEEDBACK_INFO";

export const GET_COACH_FFORMS = "GET_COACH_FFORMS";
export const GET_CANDIDATE_FEEDBAKFORMS = "GET_CANDIDATE_FEEDBAKFORMS";
export const GET_DEFAULT_QUESTIONS = "GET_DEFAULT_QUESTIONS";
export const TEST_FORM = "TEST_FORM";
export const GET_CANDIDATE_TEST = "GET_CANDIDATE_TEST";
export const FETCH_TEST_INFO = "FETCH_TEST_INFO";
export const TEST_FORM_ANSWERS = "TEST_FORM_ANSWERS";
export const TEST_USER_STATS = "TEST_USER_STATS";
export const GET_COACH_TEST = "GET_COACH_TEST";
export const GET_COACH_TEST_INFO = "GET_COACH_TEST_INFO";
export const GET_TEST_USER_RESULT = "GET_TEST_USER_RESULT";
export const GET_CLASS_TESTS = "GET_CLASS_TESTS";
export const GET_C_CLASS_TESTS = "GET_C_CLASS_TESTS";
export const HIRING_FORM = "HIRING_FORM";
export const SET_TEST_PAGE = "SET_TEST_PAGE";
export const SET_TEST_TYPE = "SET_TEST_TYPE";
export const COACH_HIRINGS_ROUNDS = "COACH_HIRINGS_ROUNDS";
export const GET_COACH_HIRING_INFO = "GET_COACH_HIRING_INFO";
export const GET_COACH_HIRING_CANDIDATES = "GET_COACH_HIRING_CANDIDATES";

export const GET_COACH_ROUND_HIRINGS = "GET_COACH_ROUND_HIRINGS";
export const GET_C_COMPANY_DETAILS = "GET_C_COMPANY_DETAILS";
export const GET_C_COMPANY_HIRED = "GET_C_COMPANY_HIRED";
export const GET_COMPANY_TREE = "GET_COMPANY_TREE";
export const GET_COMPANY_DEPARTMENTS = "GET_COMPANY_DEPARTMENTS";
export const GET_DEPARTMENT_LEADERS = "GET_DEPARTMENT_LEADERS";
export const GET_C_COMPANY_TREE = "GET_C_COMPANY_TREE";

export const GET_COMPANY_PREJOINING_CANDIDATES =
  "GET_COMPANY_PREJOINING_CANDIDATES";
export const GET_PREJOINING_CLASSES = "GET_PREJOINING_CLASSES";
export const GET_COACH_DOCUMENTS = "GET_COACH_DOCUMENTS";
export const GET_COACH_DOCUMENT_GROUPS = "GET_COACH_DOCUMENT_GROUPS";

export const GET_COMPANY_FORMS = "GET_COMPANY_FORMS";
export const GET_ONBOARDING_STEPS = "GET_ONBOARDING_STEPS";
export const GET_COMPANY_STATS = "GET_COMPANY_STATS";
export const GET_COMPANY_TROUNDS = "GET_COMPANY_TROUNDS";
export const GET_C_COMPANY_DOCUMENTS = "GET_C_COMPANY_DOCUMENTS";
export const GET_C_COMPANY_DOCUMENT = "GET_C_COMPANY_DOCUMENT";
export const GET_HIRINGS = "GET_HIRINGS";
export const GET_CANDIDATE_FEEDBACK = "GET_CANDIDATE_FEEDBACK";
export const GET_COMPANY_FORM_INFO = "GET_COMPANY_FORM_INFO";
export const GET_PREDEFINED_TESTS = "GET_PREDEFINED_TESTS";
export const COMPANY_HIRINGS_ROUNDS = "COMPANY_HIRINGS_ROUNDS";
export const GET_ALL_GIVEN_FEEDBAKS = "GET_ALL_GIVEN_FEEDBAKS";
export const GET_ALL_NOTES = "GET_ALL_NOTES";
export const GET_EVENT_INFO = "GET_EVENT_INFO";
export const GET_SKILLS = "GET_SKILLS";
export const COMMUNITY_POST_COMMENTS = "COMMUNITY_POST_COMMENTS";
export const GET_USER_COMMUNITIES = "GET_USER_COMMUNITIES";
export const GET_USER_ROOMS = "GET_USER_ROOMS";
export const GET_ROOM_FEEDBACKS = "GET_ROOM_FEEDBACKS";
export const GET_ROOMS = "GET_ROOMS";
export const GET_ROOM_BLOGS = "GET_ROOM_BLOGS";
export const GET_OPEN_ROOMS = "GET_OPEN_ROOMS";
export const GET_MENTOR_OPEN_ROOMS = "GET_MENTOR_OPEN_ROOMS";
export const GET_OPEN_ROOM_HISTORY = "GET_OPEN_ROOM_HISTORY";
export const GET_C_ROOMS = "GET_C_ROOMS";
export const GET_COMMUNITY_POSTS = "GET_COMMUNITY_POSTS";
export const GET_COMPANY_EMPLOYEES = "GET_COMPANY_EMPLOYEES";
export const GET_COMPANY_HIRINGS = "GET_COMPANY_HIRINGS";
export const GET_COMPANY_EVENTS = "GET_COMPANY_EVENTS";
export const GET_CANDIDATE_LIVE_ROOMS = "GET_CANDIDATE_LIVE_ROOMS";
export const GET_ROOM_MENTORS = "GET_ROOM_MENTORS";
export const GET_MIRROR_ROOM_CATEGORY = "GET_MIRROR_ROOM_CATEGORY";
export const GET_MIRROR_ROOM_SUBCATEGORY = "GET_MIRROR_ROOM_SUBCATEGORY";
export const SET_MIRROR_ROOM_CANDIDATE = "SET_MIRROR_ROOM_CANDIDATE";
export const GET_MIRROR_ROOM_DETAILS = "GET_MIRROR_ROOM_DETAILS";
export const GET_CANDIDATE_MIRROR_ROOMS = "GET_CANDIDATE_MIRROR_ROOMS";
export const GET_COMPANY_VIDEOS = "GET_COMPANY_VIDEOS";
export const GET_ROOM_INFO = "GET_ROOM_INFO";
export const GET_MIRROR_ROOM_COMPANIES = "GET_MIRROR_ROOM_COMPANIES";
export const GET_COACH_MESSAGE_USER = "GET_COACH_MESSAGE_USER";
export const GET_USER_MESSAGE = "GET_USER_MESSAGE";
export const GET_CANDIDATE_MESSAGE_USER = "GET_CANDIDATE_MESSAGE_USER";
export const GET_COACH_MESSAGE = "GET_COACH_MESSAGE";

export const GET_ROOM_QUESTIONS = "GET_ROOM_QUESTIONS";
export const GET_ROOM_QUESTION_CATEGORIES = "GET_ROOM_QUESTION_CATEGORIES";
export const GET_ROOM_QUESTION_SUBCATEGORIES =
  "GET_ROOM_QUESTION_SUBCATEGORIES";
export const GET_ROOM_QUESTION_INFO = "GET_ROOM_QUESTION_INFO";
export const GET_COACH_ROOM_TIMES = "GET_COACH_ROOM_TIMES";
export const MIRROR_ROOM_FORM = "MIRROR_ROOM_FORM";
export const GET_MIRROR_ROOM_QUESTION = "GET_MIRROR_ROOM_QUESTION";
export const SET_POSITION_FORM_TYPE = "SET_POSITION_FORM_TYPE";
export const GET_COMPANY_MIRROR_ROOMS = "GET_COMPANY_MIRROR_ROOMS";
export const GET_SUGGESTED_HIRING = "GET_SUGGESTED_HIRING";
export const GET_COMPANY_CONVERSATIONS = "GET_COMPANY_CONVERSATIONS";
export const GET_C_USER_MESSAGE = "GET_C_USER_MESSAGE";
export const GET_C_CANDIDATE_INFO = "GET_C_CANDIDATE_INFO";
export const GET_C_CANDIDATE_APPLIED = "GET_C_CANDIDATE_APPLIED";
export const GET_C_CANDIDATE_APPLIED_RESULT = "GET_C_CANDIDATE_APPLIED_RESULT";
export const GET_C_CANDIDATE_POSTS = "GET_C_CANDIDATE_POSTS";
export const GET_C_CANDIDATE_COMMENTS = "GET_C_CANDIDATE_COMMENTS";
export const COACH_DEPARTMENTS = "COACH_DEPARTMENTS";
export const COACH_STUDENTS = "COACH_STUDENTS";
export const UNIVERSITY_HIRINGS = "UNIVERSITY_HIRINGS";
export const UNIVERSITY_TESTS = "UNIVERSITY_TESTS";
// export const GET_COACH_HIRING_CANDIDATES = 'GET_COACH_HIRING_CANDIDATES';
export const GET_COMPANY_SELECT = "GET_COMPANY_SELECT";
export const GET_COMPANY_PLACEMENTS = "GET_COMPANY_PLACEMENTS";
export const GET_COACH_HIRING_ROOMS = "GET_COACH_HIRING_ROOMS";
export const GET_COMPANY_POSITIONS = "GET_COMPANY_POSITIONS";
export const GET_ASSIGNED_HIRINGS = "GET_ASSIGNED_HIRINGS";

export const GET_SHARED_HIRINGS = "GET_SHARED_HIRINGS";
export const GET_PLACEMENT_HIRINGS = "GET_PLACEMENT_HIRINGS";
export const GET_CANDIDATE_UNI_TESTS = "GET_CANDIDATE_UNI_TESTS";
export const GET_CANDIDATE_UNI_ROOMS = "GET_CANDIDATE_UNI_ROOMS";
export const GET_TEST_QUESTIONS = "GET_TEST_QUESTIONS";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_TEST_GROUPS = "GET_TEST_GROUPS";
export const GET_CANDIDATE_MIRROR_ROOM_INFO = "GET_CANDIDATE_MIRROR_ROOM_INFO";
export const GET_APPLIED_CANDIDATES = "GET_APPLIED_CANDIDATES";
export const GET_HIRING_MEETINGS = "GET_HIRING_MEETINGS";
export const GET_HIRING_MEETING_INFO = "GET_HIRING_MEETING_INFO";
export const GET_HIRING_MEETING_CANDIDATE_NOTES =
  "GET_HIRING_MEETING_CANDIDATE_NOTES";
export const GET_HIRINGS_COACH_MEETINGS = "GET_HIRINGS_COACH_MEETINGS";

export const GET_COACH_FILTERED_CANDIDATES = "GET_COACH_FILTERED_CANDIDATES";

export const COMPANY_DAILY_USERS = "COMPANY_DAILY_USERS";
export const COMPANY_DAILY_INTERVIEW_PRACTICE =
  "COMPANY_DAILY_INTERVIEW_PRACTICE";
export const COMPANY_TOP_USERS = "COMPANY_TOP_USERS";
export const GET_COMPANY_CANDIDATE_INFO = "GET_COMPANY_CANDIDATE_INFO";
export const GET_HOME_FEEDBACKS = "GET_HOME_FEEDBACKS";
export const GET_CANDIDATE_PRICING = "GET_CANDIDATE_PRICING";
export const GET_HIRING_ROOMS = "GET_HIRING_ROOMS";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const SET_HIRING_CURRENT_QUESTION = "SET_HIRING_CURRENT_QUESTION";

export const GET_PREDEFINED_MIRROR_ROOMS = "GET_PREDEFINED_MIRROR_ROOMS";
export const GET_PREDEFINED_MIRROR_ROOM_INFO =
  "GET_PREDEFINED_MIRROR_ROOM_INFO";
export const GET_PREDEFINED_UNI_MIRROR_ROOMS =
  "GET_PREDEFINED_UNI_MIRROR_ROOMS";

export const GET_PREDEFINED_UNI_LISTS = "GET_PREDEFINED_UNI_LISTS";
export const GET_TOURNAMENTS = "GET_TOURNAMENTS";
export const GET_TOURNAMENT_INFO = "GET_TOURNAMENT_INFO";
export const COMPANY_LEADERBOARD_HIRINGS = "COMPANY_LEADERBOARD_HIRINGS";
export const GET_PROFILE_VIEWS = "GET_PROFILE_VIEWS";
export const MIRROR_ROOM_SHARED = "MIRROR_ROOM_SHARED";
export const CANDIDATE_SHARED_MIRROR_ROOM = "CANDIDATE_SHARED_MIRROR_ROOM";

export const MIRROR_ROOM_REVIEWER = "MIRROR_ROOM_REVIEWER";
export const CANDIDATE_REVIEW_MIRROR_ROOM = "CANDIDATE_REVIEW_MIRROR_ROOM";

export const GET_MIRROR_ROOM_PDATA = "GET_MIRROR_ROOM_PDATA";

export const GET_PRACTICE_PAGES = "GET_PRACTICE_PAGES";
export const GET_PRACTICE_PAGE_INFO = "GET_PRACTICE_PAGE_INFO";

export const GET_COMPANY_DOMAIN = "GET_COMPANY_DOMAIN";

export const GET_SCHOOL_COUNTRIES = "GET_SCHOOL_COUNTRIES";
export const GET_SCHOOL_PRACTICE_INFO = "GET_SCHOOL_PRACTICE_INFO";
export const GET_SCHOOL_GRADES = "GET_SCHOOL_GRADES";
export const GET_SCHOOL_TOPICS = "GET_SCHOOL_TOPICS";
export const GET_SCHOOL_SUBJECTS = "GET_SCHOOL_SUBJECTS";
export const GET_HOME_VIDEOS = "GET_HOME_VIDEOS";
export const GET_COACH_COURSES = "GET_COACH_COURSES";

export const FETCH_COURSES = "FETCH_COURSES";
export const COURSE_INFO = "COURSE_INFO";
export const COURSE_ITEM_INFO = "COURSE_ITEM_INFO";

export const GET_COURSE_REVIEWS = "GET_COURSE_REVIEWS";
export const GET_COURSE_ITEM_COMMENTS = "GET_COURSE_ITEM_COMMENTS";
export const GET_HOME_DOMAINS = "GET_HOME_DOMAINS";
export const GET_COACH_MIRROR_ROOM_INFO = "GET_COACH_MIRROR_ROOM_INFO";
export const COURSE_ITEMS = "COURSE_ITEMS";
export const GET_COURSE_BUNDLES = "GET_COURSE_BUNDLES";
export const GET_COURSE_BUNDLE_INFO = "GET_COURSE_BUNDLE_INFO";
export const GET_USER_COURSES = "GET_USER_COURSES";
export const SET_FREE_PRACTICE_INFO = "SET_FREE_PRACTICE_INFO";
export const GET_COACH_ASSIGNED_COURSES = "GET_COACH_ASSIGNED_COURSES";
export const GET_COACH_ASSIGNED_COURSE_ITEM = "GET_COACH_ASSIGNED_COURSE_ITEM";
export const GET_COACH_MIRROR_ROOMS = "GET_COACH_MIRROR_ROOMS";
export const GET_COACH_TRAININGS = "GET_COACH_TRAININGS";
export const GET_COACH_TRAINING_INFO = "GET_COACH_TRAINING_INFO";
export const GET_COMPANY_TRAINING_INFO = "GET_COMPANY_TRAINING_INFO";
export const GET_TRAINING_COACH_RESULT = "GET_TRAINING_COACH_RESULT";
export const GET_UNIVERSITY_CANDIDATES = "GET_UNIVERSITY_CANDIDATES";
export const FETCH_MICRO_COURSES = "FETCH_MICRO_COURSES";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";

export const GET_PROFICIENCY_PAGE_CATEGORY = "GET_PROFICIENCY_PAGE_CATEGORY";
export const GET_MIRROR_ROOM_SUBCATEGORY1 = "GET_MIRROR_ROOM_SUBCATEGORY1";

export const FETCH_COUNTRY = "FETCH_COUNTRY";
export const GET_MIRROR_ROOM_VISA_CATEGORY = "GET_MIRROR_ROOM_VISA_CATEGORY";
export const GET_CANDIDATE_PRACTICE_ANALYSIS =
  "GET_CANDIDATE_PRACTICE_ANALYSIS";

export const GET_DEBATE_CATEGORY = "GET_DEBATE_CATEGORY";
export const GET_DEBATE_SUBCATEGORY = "GET_DEBATE_SUBCATEGORY";
export const GET_PRESENTATION_CATEGORY = "GET_PRESENTATION_CATEGORY";
export const GET_PRESENTATION_SUBCATEGORY = "GET_PRESENTATION_SUBCATEGORY";
export const GET_MEETING_CATEGORY = "GET_MEETING_CATEGORY";
export const GET_MEETING_SUBCATEGORY = "GET_MEETING_SUBCATEGORY";
export const GET_READING_CATEGORY = "GET_READING_CATEGORY";
export const GET_READING_SUBCATEGORY = "GET_READING_SUBCATEGORY";
export const FETCH_CHAT_WIDGET_INFO = "FETCH_CHAT_WIDGET_INFO";
export const GET_PRACTICE_TYPES = "GET_PRACTICE_TYPES";
export const FETCH_MIRROR_ROOM_CATEGORY = "FETCH_MIRROR_ROOM_CATEGORY";
export const GET_AI_MCQ_USER_ANSWER = "GET_AI_MCQ_USER_ANSWER";
export const GET_ANALYZE_RESUME_HISTORY = "GET_ANALYZE_RESUME_HISTORY";
export const GET_RESUME_ANALYSIS_INFO = "GET_RESUME_ANALYSIS_INFO";
export const GET_ROLE_DISCOVERY_DETAIL_INFO = "GET_ROLE_DISCOVERY_DETAIL_INFO";
export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_QUESTION_LIST = "GET_FAQ_QUESTION_LIST";
export const GET_INTRODUCTION_CATEGORY = "GET_INTRODUCTION_CATEGORY";
export const GET_INTRODUCTION_SUBCATEGORY = "GET_INTRODUCTION_SUBCATEGORY";
export const GET_UNIVERSITY_COURSES = "GET_UNIVERSITY_COURSES";
export const GET_APTITUDE_USER_ANSWER = "GET_APTITUDE_USER_ANSWER";
export const GET_APTITUDE_TEST_DETAIL_INFO = "GET_APTITUDE_TEST_DETAIL_INFO";
export const AI_MIRRORROOM_CATEGORY = "AI_MIRRORROOM_CATEGORY";
export const COMPANIES_CATEGORY_VALUES = "COMPANIES_CATEGORY_VALUES";
export const GET_UNIVERSITY_PRACTICE = "GET_UNIVERSITY_PRACTICE";
export const GET_VISA_PRACTICE = "GET_VISA_PRACTICE";
export const GET_HOME_PRACTICE = "GET_HOME_PRACTICE";
export const GET_SIGNED_DATA = "GET_SIGNED_DATA";
export const GET_JOB_POSITION = "GET_JOB_POSITION";
export const GET_POSITION_INFO = "GET_POSITION_INFO";
export const GET_JOB_POSITION_RANDOM = "GET_JOB_POSITION_RANDOM";
export const GET_CAREER = "GET_CAREER";
export const GET_CAREER_INFO = "GET_CAREER_INFO";

export const GET_DATE_WISE_ANALYSIS = "GET_DATE_WISE_ANALYSIS";
export const GET_CANDIDATE_LEADERBOARD = "GET_CANDIDATE_LEADERBOARD";
export const COMPANY_ACCOUNT_OVERVIEW = "COMPANY_ACCOUNT_OVERVIEW";
export const LIST_OF_STUDENTS = "LIST_OF_STUDENTS";
export const GET_CANDIDATE_LEADERBOARD_CHART =
  "GET_CANDIDATE_LEADERBOARD_CHART";
export const GET_TOP_ROLES_PRACTICED = "GET_TOP_ROLES_PRACTICED";
export const GET_PREVIOUS_PRACTICED_DATA = "GET_PREVIOUS_PRACTICED_DATA";
export const GET_SRCC_CANDIDATE_DETAIL = "GET_SRCC_CANDIDATE_DETAIL";
