//import $ from 'jquery';

//title, summary, details, severity, dismissible, autoDismiss, appendToId

import { API_URL, DEFAULT_IMAGES } from "./config";

var defaultCompanyImage = [
  "https://shadowing-videos.nyc3.digitaloceanspaces.com/website/assets/img/Shadowing-company-image-1.jpg"
  // '/assets/img/Shadowing-company-image-2.jpg',
  // '/assets/img/Shadowing-company-image-3.jpg',
  // '/assets/img/Shadowing-company-image-4.jpg',
  // '/assets/img/Shadowing-company-image-5.jpg',
  // '/assets/img/Shadowing-company-image-6.png',
];
var defaultUserImage = [
  "https://shadowing-videos.nyc3.digitaloceanspaces.com/website/assets/img/Shadowing-profile-image-1.png"
  // '/assets/img/Shadowing-profile-image-2.png',
  // '/assets/img/Shadowing-profile-image-3.png',
  // '/assets/img/Shadowing-profile-image-4.png',
  // '/assets/img/Shadowing-profile-image-5.png',
  // '/assets/img/Shadowing-profile-image-6.png',
];

export function getImage(value, type) {
  var image =
    "https://shadowing-videos.nyc3.digitaloceanspaces.com/website/assets/img/Shadowing-profile-image-6.png";
  if (value && (value.startsWith("http") || value.startsWith("https"))) {
    image = value;
  } else if (value) {
    image = API_URL + "/" + value;
  } else {
    if (type == "company") {
      const random = Math.floor(Math.random() * defaultCompanyImage.length);
      image = defaultCompanyImage[random];
    } else if (type == "test") {
      image =
        "https://shadowing-videos.nyc3.digitaloceanspaces.com/website/assets/img/Shadowing-company-image-1.jpg";
    } else {
      const random = Math.floor(Math.random() * defaultCompanyImage.length);
      image = defaultUserImage[random];
    }
  }

  return image;
}

export function get_youtube_thumbnail(url, quality) {
  if (url) {
    var video_id, thumbnail, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      if (typeof quality == "undefined") {
        quality = "high";
      }

      var quality_key = "maxresdefault"; // Max quality
      if (quality == "low") {
        quality_key = "sddefault";
      } else if (quality == "medium") {
        quality_key = "mqdefault";
      } else if (quality == "high") {
        quality_key = "hqdefault";
      }

      var thumbnail =
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
      return thumbnail;
    }
  }
  return false;
}

export function youtube_parser(url) {
  var regExp = /^.((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
export function url_parser(url) {
  if (url && url.length) {
    if (url.includes("vimeo")) {
      var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      var match = url.match(regExp);
      return match && match[5] ? match[5] : false;
    } else {
      var regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

      // var regExp = /^.((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]).*/;
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : false;
    }
  } else {
    return false;
  }
}

export function url_type(url) {
  if (url.includes("vimeo")) {
    return "vimeo";
  } else {
    return "youtube";
  }
}
export function getURL(value, type) {
  if (value && (value.startsWith("http://") || value.startsWith("https://"))) {
    return value;
  } else {
    return "https://" + value;
  }
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function ageCalculation(bdate) {
  var birth = new Date(bdate);
  var curr = new Date();
  var diff = curr.getTime() - birth.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export function numberFormat(val) {
  if (val >= 10000000) val = (val / 10000000).toFixed(1) + " Cr";
  else if (val >= 100000) val = (val / 100000).toFixed(1) + " Lac";
  else if (val >= 1000) val = (val / 1000).toFixed(1) + " K";
  return val;
}

export function numberFormatInt(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + "B"
    : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M"
      : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K"
        : Math.abs(Number(labelValue));
}

export function getImageMeta(url, i, callback) {
  var img = new Image();
  img.src = url;
  img.onload = function() {
    if (callback) {
      callback(this.width, this.height, i);
    } else {
      return this.width, this.height, i;
    }
  };
}

export function createUrl(str) {
  if (str) {
    // str = str.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
    str = str
      .toLowerCase()
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
    return str.split(" ").join("-");
  } else {
    return str;
  }
}
export function randomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getUrlMeta(o_url) {
  var r = /^(?:www\.)?(.*?)\.(?:com|au\.uk|co\.in)/;
  var url = o_url.replace(/^https:\/\//, "").replace(/^http:\/\//, "");
  var type = url.match(r)[1];
  var acc = o_url.replace(/^.*\/\/[^\/]+/, "");
  return {
    type,
    acc
  };
}

export function objectToFormData(obj, fd, namespace) {
  var fd = fd || new FormData();

  var formKey;
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File,
      // use recursivity.
      // console.log(typeof obj[property]);
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
}
export function arrayMoveImmutable(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

// export function removeAlerts() {
//   $('#web-alerts').empty();
// }

// export function createAlert(alert_data) {
//     //https://codepen.io/codysechelski/pen/dYVwjb?q=alert&limit=all&type=type-pens
//     if(alert_data.type == undefined || !alert_data.type){
//      alert_data.class = 'success';
//     } else {
//      alert_data.class = alert_data.type;
//     }
//     if(alert_data.dismissible == undefined){
//      alert_data.dismissible = true;
//     }
//     if(alert_data.autoDismiss == undefined){
//      alert_data.autoDismiss = true;
//     }

//     var iconMap = {
//      info: "fa fa-info-circle",
//      success: "fa fa-thumbs-up",
//      warning: "fa fa-exclamation-triangle",
//      danger: "fa ffa fa-exclamation-circle"
//     };

//     var iconAdded = false;

//     var alertClasses = ["alert", "animated", "flipInX"];
//     alertClasses.push("alert-" + alert_data.class.toLowerCase());

//     if (alert_data.dismissible != undefined && alert_data.dismissible) {
//      alertClasses.push("alert-dismissible");
//     }

//     var msgIcon = $("<i />", {
//      "class": iconMap[alert_data.class] // you need to quote "class" since it's a reserved keyword
//     });

//     var msg = $("<div />", {
//      "class": alertClasses.join(" ") // you need to quote "class" since it's a reserved keyword
//     });
//     if(!iconAdded){
//       msgIcon.appendTo(msg);
//       iconAdded = true;
//     }

//     if (alert_data.title) {
//       var msgTitle = $("<h5 />", {
//        html: alert_data.title
//       }).appendTo(msg);
//     } else {
//      var msgTitle = $("<h5 />", {
//        html: 'Success'
//      }).appendTo(msg);

//      if(!iconAdded){
//        msgTitle.prepend(msgIcon);
//        iconAdded = true;
//      }
//     }

//     if (alert_data.sub_title != undefined && alert_data.sub_title) {
//      var msgSummary = $("<strong />", {
//        html: alert_data.sub_title
//      }).appendTo(msg);

//      if(!iconAdded){
//        msgSummary.prepend(msgIcon);
//        iconAdded = true;
//      }
//     }

//     if (alert_data.message) {
//      var msgDetails = $("<p />", {
//        html: alert_data.message
//      }).appendTo(msg);

//      if(!iconAdded){
//        msgDetails.prepend(msgIcon);
//        iconAdded = true;
//      }
//     }

//     if (alert_data.dismissible) {
//      var msgClose = $("<span />", {
//        "class": "close", // you need to quote "class" since it's a reserved keyword
//        "data-dismiss": "alert",
//        html: "<i className='fa fa-times-circle'></i>",
//        onClick:"this.closest('.alert').remove();"
//      }).appendTo(msg);
//     }
//     $('#web-alerts').prepend(msg);
//     if(alert_data.autoDismiss){
//       setTimeout(function(){
//         msg.addClass("flipOutX");
//         setTimeout(function(){
//           msg.remove();
//         },500);
//       }, 2000);
//     }
// }
export function strip(htmlString) {
  //  var tmp = document.createElement("DIV");
  //  tmp.innerHTML = html;
  //  return tmp.textContent || tmp.innerText || "";
  // var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
  // return htmlString.replace(/<[^>]+>/g, '');
  return htmlString.replace(/\n/g, "");
  // var decodedStripedHtml = he.decode(stripedHtml);
  // return stripedHtml
}
export function validateURL(url) {
  var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (!re.test(url)) {
    return false;
  } else {
    return true;
  }
}
export function percentCalc(totalNum, percNum) {
  if (percNum == 0 && totalNum == 0) {
    return 0;
  } else if (percNum <= totalNum) {
    var percentage = (percNum * 100) / totalNum;
    return percentage.toFixed(2);
  } else {
    return 0;
  }
}
export function getUnique(arr, comp) {
  if (arr) {
    const unique = arr
      .map(e => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e])
      .map(e => arr[e]);

    return unique;
  } else {
    return arr;
  }
}
export function dateRange(startDate, endDate) {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];
  var datesObjs = [];
  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month;
      dates.push([i, displayMonth, "01"].join("-"));
      datesObjs.push({
        date: 1,
        month: displayMonth,
        year: i
      });
    }
  }
  return datesObjs;
}
export function filterByProperty(array, prop, value) {
  var filtered = [];
  for (var i = 0; i < array.length; i++) {
    filtered.push(array[i][prop]);
  }
  return filtered;
}

export function languageConversion(lang) {
  var language = {
    aa: "Afar",
    ab: "Abkhazian",
    ae: "Avestan",
    af: "Afrikaans",
    ak: "Akan",
    am: "Amharic",
    an: "Aragonese",
    ar: "Arabic",
    as: "Assamese",
    av: "Avaric",
    ay: "Aymara",
    az: "Azerbaijani",
    ba: "Bashkir",
    be: "Belarusian",
    bg: "Bulgarian",
    bh: "Bihari",
    bi: "Bislama",
    bm: "Bambara",
    bn: "Bengali",
    bo: "Tibetan",
    br: "Breton",
    bs: "Bosnian",
    ca: "Catalan",
    ce: "Chechen",
    ch: "Chamorro",
    co: "Corsican",
    cr: "Cree",
    cs: "Czech",
    cu: "Church Slavic",
    cv: "Chuvash",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    dv: "Divehi",
    dz: "Dzongkha",
    ee: "Ewe",
    el: "Greek",
    en: "English",
    eo: "Esperanto",
    es: "Spanish",
    et: "Estonian",
    eu: "Basque",
    fa: "Persian",
    ff: "Fulah",
    fi: "Finnish",
    fj: "Fijian",
    fo: "Faroese",
    fr: "French",
    fy: "Western Frisian",
    ga: "Irish",
    gd: "Scottish Gaelic",
    gl: "Galician",
    gn: "Guarani",
    gu: "Gujarati",
    gv: "Manx",
    ha: "Hausa",
    he: "Hebrew",
    iw: "Hebrew",
    hi: "Hindi",
    ho: "Hiri Motu",
    hr: "Croatian",
    ht: "Haitian",
    hu: "Hungarian",
    hy: "Armenian",
    hz: "Herero",
    ia: "Interlingua (International Auxiliary Language Association)",
    id: "Indonesian",
    in: "Indonesian",
    ie: "Interlingue",
    ig: "Igbo",
    ii: "Sichuan Yi",
    ik: "Inupiaq",
    io: "Ido",
    is: "Icelandic",
    it: "Italian",
    iu: "Inuktitut",
    ja: "Japanese",
    jv: "Javanese",
    ka: "Georgian",
    kg: "Kongo",
    ki: "Kikuyu",
    kj: "Kwanyama",
    kk: "Kazakh",
    kl: "Kalaallisut",
    km: "Khmer",
    kn: "Kannada",
    ko: "Korean",
    kr: "Kanuri",
    ks: "Kashmiri",
    ku: "Kurdish",
    kv: "Komi",
    kw: "Cornish",
    ky: "Kirghiz",
    la: "Latin",
    lb: "Luxembourgish",
    lg: "Ganda",
    li: "Limburgish",
    ln: "Lingala",
    lo: "Lao",
    lt: "Lithuanian",
    lu: "Luba-Katanga",
    lv: "Latvian",
    mg: "Malagasy",
    mh: "Marshallese",
    mi: "Maori",
    mk: "Macedonian",
    ml: "Malayalam",
    mn: "Mongolian",
    mr: "Marathi",
    ms: "Malay",
    mt: "Maltese",
    my: "Burmese",
    na: "Nauru",
    nb: "Norwegian Bokmal",
    nd: "North Ndebele",
    ne: "Nepali",
    ng: "Ndonga",
    nl: "Dutch",
    nn: "Norwegian Nynorsk",
    no: "Norwegian",
    nr: "South Ndebele",
    nv: "Navajo",
    ny: "Chichewa",
    oc: "Occitan",
    oj: "Ojibwa",
    om: "Oromo",
    or: "Oriya",
    os: "Ossetian",
    pa: "Panjabi",
    pi: "Pali",
    pl: "Polish",
    ps: "Pashto",
    pt: "Portuguese",
    qu: "Quechua",
    rm: "Raeto-Romance",
    rn: "Kirundi",
    ro: "Romanian",
    ru: "Russian",
    rw: "Kinyarwanda",
    sa: "Sanskrit",
    sc: "Sardinian",
    sd: "Sindhi",
    se: "Northern Sami",
    sg: "Sango",
    si: "Sinhala",
    sk: "Slovak",
    sl: "Slovenian",
    sm: "Samoan",
    sn: "Shona",
    so: "Somali",
    sq: "Albanian",
    sr: "Serbian",
    ss: "Swati",
    st: "Southern Sotho",
    su: "Sundanese",
    sv: "Swedish",
    sw: "Swahili",
    ta: "Tamil",
    te: "Telugu",
    tg: "Tajik",
    th: "Thai",
    ti: "Tigrinya",
    tk: "Turkmen",
    tl: "Tagalog",
    tn: "Tswana",
    to: "Tonga",
    tr: "Turkish",
    ts: "Tsonga",
    tt: "Tatar",
    tw: "Twi",
    ty: "Tahitian",
    ug: "Uighur",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    ve: "Venda",
    vi: "Vietnamese",
    vo: "Volapuk",
    wa: "Walloon",
    wo: "Wolof",
    xh: "Xhosa",
    yi: "Yiddish",
    yo: "Yoruba",
    za: "Zhuang",
    zh: "Chinese",
    zu: "Zulu",
    "ar-sa": "Arabic (Saudi Arabia)",
    "ar-eg": "Arabic (Egypt)",
    "ar-dz": "Arabic (Algeria)",
    "ar-tn": "Arabic (Tunisia)",
    "ar-ye": "Arabic (Yemen)",
    "ar-iq": "Arabic (Iraq)",
    "ar-ly": "Arabic (Libya)",
    "ar-ma": "Arabic (Morocco)",
    "ar-om": "Arabic (Oman)",
    "ar-sy": "Arabic (Syria)",
    "en-gb": "English (UK)",
    "zh-tw": "Chinese (Taiwan)",
    "zh-hk": "Chinese (Hong Kong SAR)",
    "zh-cn": "Chinese (PRC)",
    "zh-sg": "Chinese (Singapore)"
  };
  if (language[lang]) {
    return language[lang];
  } else {
    return lang;
  }
}
export function tooltipText(text, city_name) {
  // console.log(text);
  var data = {
    "Activity Score":
      "This score shows the percentage of conversations about running, walking and other related activities in " +
      city_name,
    "Crime Score":
      "This score shows the percentage of conversations about assault, robbery, theft and other related criminal activities in " +
      city_name,
    "Diversity Score":
      "This score shows the percentage of conversations about equality, human rights and other related topics in " +
      city_name,
    "Parking Score":
      "This score shows the percentage of conversations about parking tickets, parking meters and other related topics in " +
      city_name,
    "School Score":
      "This score shows the percentage of conversations about local schools, libraries and other education related topics in " +
      city_name,
    "Traffic Score":
      "This score shows the percentage of conversations about traffic, road accidents and other traffic related issues in " +
      city_name,
    popular_links:
      "List of the most popular news articles by engagement and reach",
    verified_user: "User that is verified on social media. ( Blue tickmark )",
    popular_user: "Active contributors on social media with decent followings",
    fake_user: "User that is creating fake news and trends",
    news_trackers:
      "List of the most active columnists, contributors and news agencies",
    active_user: "Users contributing regularly on social media"
  };
  if (data[text]) {
    return data[text];
  } else {
    return text;
  }
}

export function ArrayGroupby(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function randomstring(L) {
  var s = "";
  var randomchar = function() {
    var n = Math.floor(Math.random() * 62);
    if (n < 10) return n; //1-10
    if (n < 36) return String.fromCharCode(n + 55); //A-Z
    return String.fromCharCode(n + 61); //a-z
  };
  while (s.length < L) s += randomchar();
  return s;
}
export function listToTree(arr = [], parentId) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]["nodes"] = [];
  }

  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem[parentId]) {
        // console.log(mappedArr,mappedElem[parentId]);
        mappedArr[mappedElem[parentId]]["nodes"].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}
export function comments_to_tree(list) {
  if (list && list.length) {
    // console.log(list.length);
    var map = {},
      node,
      roots = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      // console.log(node);
      if (node.parent_id !== "0" && node.parent_id !== 0) {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parent_id]] && list[map[node.parent_id]].children) {
          list[map[node.parent_id]].children.push(node);
        }
      } else {
        roots.push(node);
      }
    }
    return roots;
  } else {
    // console.log(list.length);
    return [];
  }
}
export function makeid(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function getUserTypeLabel(authType, userType) {
  // console.log(authType,userType);

  if (authType == "university") {
    if (userType == "coach") {
      return "Staff";
    }
    if (userType == "candidate") {
      return "Student";
    }
  } else if (authType == "candidate") {
    if (userType == "university") {
      return "University";
    } else {
      return "Mentor";
    }
  } else {
    if (userType == "coach") {
      return "Manager";
    }
    if (userType == "candidate") {
      return "Employee";
    }
  }
}

export function getcompanyLabels(companyType, labelFor) {
  // console.log(companyType, labelFor);
  if (companyType && companyType == "university") {
    if (labelFor == "candidate") {
      return "Student";
    } else if (labelFor == "company") {
      return "College";
    } else if (labelFor == "manager" || labelFor == "managers") {
      return "Staff";
    } else if (labelFor == "coach") {
      return "Staff";
    } else if (labelFor == "employee") {
      return "Employee";
    }
  } else {
    if (labelFor == "candidate") {
      return "Candidate";
    } else if (labelFor == "company") {
      return "Company";
    } else if (labelFor == "coach") {
      return "Mentor";
    } else if (labelFor == "manager") {
      return "Manager";
    } else if (labelFor == "managers") {
      return "Managers";
    }
  }
}
export function getRandomFromArray(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
export let ends = [
  ".edu",
  ".ac.in",
  "edu.ag",
  "edu.ar",
  "edu.au",
  "edu.bd",
  "edu.br",
  "edu.bn",
  "edu.ca",
  "edu.cn",
  "edu.co",
  "edu.dj",
  "edu.ec",
  "edu.eg",
  "edu.sv",
  "edu.er",
  "edu.ee",
  "edu.et",
  "edu.gh",
  "edu.hk",
  "edu.it",
  "edu.in",
  "edu.jm",
  "edu.jo",
  "edu.lb",
  "edu.ly",
  "edu.mo",
  "edu.my",
  "edu.mt",
  "edu.mx",
  "edu.np",
  "edu.ni",
  "edu.ng",
  "edu.om",
  "edu.pk",
  "edu.pe",
  "edu.ph",
  "edu.pl",
  "edu.qa",
  "edu.sa",
  "edu.rs",
  "edu.sg",
  "edu.so",
  "edu.es",
  "edu.lk",
  "edu.sd",
  "edu.tw",
  "edu.tr",
  "edu.ua",
  "edu.uy",
  "edu.vn"
];
export function checkStudentEmail(email) {
  var domain = email.substring(email.lastIndexOf("@") + 1);
  var student = false;
  // ends.map((e)=>{
  //   if(email.endsWith(e)){
  //     student = true
  //   }
  // })
  var eduContains = ["edu", "ac.in"];

  const contains = eduContains.some(element => {
    if (domain.includes(element)) {
      return true;
    }
    return false;
  });
  return contains;
}
export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  } else {
    return false;
  }
}

export function getDifference(array1, array2) {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.id;
    });
  });
}
export function removeTags(str) {
  if (str === null || str === "") {
    return false;
  } else {
    var str1 = str.toString();
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str1.replace(/(<([^>]+)>)/gi, "");
  }
}
export function getMatchingPercentage(string, keywords) {
  console.log("------------getMatchingPercentage");
  if (string && string.length > 0 && keywords && keywords.length > 0) {
    console.log("ifffffffffffff");
    var kArray = keywords.split(",").map(e => e.toLowerCase().trim());
    console.log(kArray);
    var match = string.match(new RegExp(kArray.join("|"), "g"));
    console.log(string);
    console.log("match  >>", match);
    if (match) {
      match = [...new Set(match)];
      var matchingVal = ((match.length * 100) / kArray.length).toFixed(0);
      return matchingVal;
    } else {
      return 0;
    }
  } else {
    console.log("return from 880");
    return 0;
  }
}
export function isInTheFuture(date) {
  const today = new Date();
  return new Date(date) > today;
}

// {"voice": 2.6,
//   "knowledge": 1,
//   "confidence": 3.4,
//   "eye_contact": 3.7,
//   "communication": 3.2,
//   "head_movements": 2.6,
//   "professionalism": 2,
//   "behavior_analysis": 3.1500000000000004
// }
export function roomTypeLabel(key) {
  if (key == "job_hiring") {
    return "Job Interview";
  } else if (key == "course") {
    return "Course";
  } else if (key == "performance-review") {
    return "Performance Review";
  } else if (key == "mental-health-check") {
    return "Mental Health Check";
  } else if (key == "culture-assessment") {
    return "Culture Assessment";
  } else {
    return null;
  }
}
export function paramsLabel(key) {
  // if(key=='professionalism'){
  //   return 'Professionalism'
  // }
  // else if(key=='emotions'){
  //   return 'Confidence'
  // }
  // else if(key=='energy'){
  //   return 'Voice'
  // }
  if (key == "knowledge") {
    return "Knowledge";
  }
  // else if(key=='communication'){
  //   return 'Communication'
  // }
  // else if(key=='sociability'){
  //   return 'Behavior analysis'
  // }
  else if (key == "confidence") {
    return "Confidence";
  } else if (key == "voice") {
    return "Voice";
  } else if (key == "behavior_analysis") {
    return "Behavior Analysis";
  } else if (key == "happy") {
    return "Happy";
  } else if (key == "surprise") {
    return "Surprise";
  } else if (key == "angry") {
    return "Anger";
  } else if (key == "sad") {
    return "Stress";
  } else if (key == "fear") {
    return "Fear";
  } else if (key == "facing_center") {
    return "Facing center";
  } else if (key == "head_up/down") {
    return "Head up/down";
  } else if (key == "e_facing_center") {
    return "Facing center";
  } else if (key == "looking_right/left") {
    return "Looking right/left";
  } else if (key == "audio_level") {
    return "Audio Level";
  } else if (key == "volume") {
    return "Volume";
  } else if (key == "long_pauses") {
    return "Long Pauses";
  } else {
    return false;
  }
}
export function improvementPoints(parameter) {
  var parameters = {
    confidence: [
      "Sit up straight",
      "Tone of voice (do not whisper or hesitate)",
      `<p><a href="https://shadowing-videos.nyc3.digitaloceanspaces.com/website/parameter_files/Confidence-Improve-Shadowing-AI-Inc.pdf" target="_blank">Learn more about Confidence Analysis</a></p>`
      // "Write down what you're going to say.",
      // "Take action, rather than seeking perfection first.",
      // "Visualize the conversation first, then act.",
    ],
    behavior_analysis: [
      "Limit distractions (pets, views, and others in the room)",
      "Be aware of fixing your hair or moving it out of your face",
      `<p><a href="https://shadowing-videos.nyc3.digitaloceanspaces.com/website/parameter_files/Behavioral-analysis-Shadowing-AI-Inc.pdf" target="_blank">Learn more about Behavior Analysis</a></p>`
      // "Pay attention to your body and verbal language.",
      // "Identify occasions you feel comfortable speaking.",
      // "Do The Right Thing and Get Things Done",
    ],
    voice: [
      "Speak clearly and in an upbeat tone",
      "Do not whisper or hesitate",
      `<p><a href="https://shadowing-videos.nyc3.digitaloceanspaces.com/website/parameter_files/Voice-Improve-Shadowing-AI-Inc.pdf" target="_blank">Learn more about Voice Analysis</a></p>`
      // "Define why your voice is important",
      // "'Act as if' to build confidence and credibility.",
      // "Be An Advocate For Others",
    ],
    knowledge: [
      "Follow the instructions for each prompt",
      "Use industry terms and language when responding to prompts (unless told otherwise)",
      `<p><a href="https://shadowing-videos.nyc3.digitaloceanspaces.com/website/parameter_files/Knowledge-Analysis-Shadowing-AI-Inc.pdf" target="_blank">Learn more about Knowledge Analysis</a></p>`
      // "Phrase statements through 'I think' and 'This is why'.",
      // "Focus on facts, not emotions.",
      // "Stand-up For Yourself",
    ]
    // communication:[
    //   "Let Go Of Outcomes",
    //   "Take Small Steps Before You Run",
    //   "Monitor Your Progress",
    // ]
  };
  if (parameters[parameter]) {
    return parameters[parameter];
  } else {
    return [];
  }
}
export function paramsTooltipText(parameter) {
  var parameters = {
    confidence:
      "This shows how confident you are based on the analysis of your body movement, stress, anxiety, voice tone and posture.",
    behavior_analysis:
      "You can find more about your body movements, like eye movement, head movement and more. Do not read the answer on the screen because AI can find that.",
    voice:
      "This is more about your speaking style. Is your voice too loud or low? Are you speaking too fast or slow? Are you taking too many long pauses? This will help you improve your communication skills.",
    knowledge:
      "(Matching answer - % of keywords matched):Your answer will be matched with the expected answer and will show you the % of matched answers.",
    pauses:
      "This shows how long the pauses are. Any pause which is more than 4 seconds is considered to be a long pause.",
    volume:
      "Your voice reflects your speaking style - whether your voice was too loud or mellow.",
    speed:
      "This may interpret how you share information into the interviewer's perception of your performance. Interviewees, which are speaking too fast or slow, might not do well in the interviews.",
    angry: `<p className='mb-0'>How not to show anger:</p>
    <ul>
      <li>Avoid Clenched fists</li>
      <li>Avoid Stern or frozen facial expression</li>
      <li>Avoid Gritting teeth</li>
      <li>Avoid ‘Growl like’ noises</li>
      <li>Avoid Aggressively leaning into the camera</li>
    </ul>
    `,
    fear: `
      <p className='mb-0'>How not to show fear:</p>
      <ul>
        <li>Shoulders square to the camera</li>
        <li>Confident voice</li>
        <li>Avoid trembling lips</li>
        <li>Don’t cower away</li>
      </ul>
    `,
    sad: `<p className='mb-0'>How not to show stress:</p>
    <ul>
      <li>Fidgeting is an indication of stress</li>
        <li>Avoid gritting teeth</li>
        <li>Unclench your hands</li>
        <li>Relax your tongue from the roof of your mouth</li>
        <li>Breathe.</li>
    </ul>`,
    happy: `<p className='mb-0'>Show smile / happy</p>
    <ul>
      <li>Speak clearly and in an upbeat tone</li>
      <li>Smile - practice to not over exaggerate</li>
      <li>Look forward to the questions</li>
      <li>Find opportunities to remind yourself of your great qualities</li>
      <li>Remember who you are!</li>
    </ul>
    `,
    surprise: `
    <p className='mb-0'>How not to show surprise behavior:</p>
    <ul>
      <li>Avoid raising eyebrows high multiple times</li>
      <li>Avoid upper eyelids raised high,</li>
      <li>Avoid Jaw dropped down</li>
    </ul>`,
    eye_movements: `
    <li>Adjust the computer (or set it on books) so that you are looking directly at the screen.</li>
    <li>Practice using your computer camera to be sure to know where to look so you are making eye contact with the interviewer.</li>
    <li>Maintain focus (and remove distractions - such as an animal or even views outside the room)</li>`,
    head_movements: `
    <li>Limit distractions (pets, views, and others in the room)</li>
    <li>Be aware of fixing your hair or moving it out of your face</li>
    <li>Avoid looking up and down often, especially if referring to notes</li>`
  };
  if (parameters[parameter]) {
    return parameters[parameter];
  } else {
    return false;
  }
}

export function paramsTooltipCompanyText(parameter) {
  var parameters = {
    confidence:
      "This shows how confident a candidate is based on the analysis of body movement, stress, anxiety, voice tone and posture.",
    behavior_analysis:
      "You can find more about the body movements, like eye movement, head movement and more. We can find out if a candidate is reading the answer on the screen using our AI.",
    voice:
      "This is more about the candidate's speaking style. Is the voice too loud or low? Are they speaking too fast or slow? Are they taking too many long pauses? This will help you get the details on their communication skills.",
    knowledge:
      "The answer will be matched with the expected answer and will show you the % of matched answers.",
    pauses:
      "This shows how long the pauses are. Any pause which is more than 4 seconds is considered to be a long pause.",
    volume:
      "The voice reflects the speaking style - whether the voice was too loud or mellow.",
    speed: "This shows the speed whether they are speaking too fast or slow.",
    angry: `<p className='mb-0'>This shows the analysis of anger. This is how we calculate it.</p>
    <ul>
      <li>Clenched fists</li>
      <li>Stern or frozen facial expression</li>
      <li>Gritting teeth</li>
      <li>‘Growl like’ noises</li>
      <li>Aggressively leaning into the camera</li>
    </ul>
    `,
    fear: `
      <p className='mb-0'>This shows the analysis of fear. This is how we calculate it.</p>
      <ul>
        <li>Shoulders square to the camera</li>
        <li>Confident voice</li>
        <li>Trembling lips</li>
      </ul>
    `,
    sad: `<p className='mb-0'>This shows the analysis of stress.</p>`,
    happy: `<p className='mb-0'>This shows the analysis of happy. </p>`,
    surprise: `
    <p className='mb-0'>This shows the analysis of surprice. This is how we calculate it.</p>
    <ul>
      <li>Raising eyebrows high multiple times</li>
      <li>Upper eyelids raised high,</li>
      <li>Jaw dropped down</li>
    </ul>`,
    eye_movements: `<p className='mb-0'>This shows how frequently a candidate has moved the eyes.</p>`,
    head_movements: `<p className='mb-0'>This shows how frequently a candidate has moved the head.</p>`
  };
  if (parameters[parameter]) {
    return parameters[parameter];
  } else {
    return false;
  }
}

export function checkRepeatation(string) {
  if (string) {
    const str = string;
    let matches = str.match(/\b(\w+)\b.*\b\1\b/g);
    if (matches && matches.length) {
      return matches.map(a => a.split(" ")[0]);
    } else {
      return [];
    }
  } else {
    return [];
  }
}
export function addDefaultSrc(e, type) {
  if (type == "no-preview") {
    e.target.src = "/assets/img/no-preview-available.png";
  } else {
    e.target.src = getImage("", type);
  }
}

export function getAnswerSpeedBySec(answerData, duration) {
  var sec = 5;
  try {
    var answerData = JSON.parse(answerData);
    var arrData = Array(Math.ceil(duration / sec)).fill({ t: 0 });
    var chartData = arrData.map((d, i) => {
      var start = i * sec,
        end = start + sec;
      //check data between start and end
      var filteredArr = answerData.filter(e => e.s >= start && e.s <= end);
      return { s: start, e: end, data: filteredArr.length };
      /* console.log("<<<< data between "+start+" and "+end+" >>>") */
      /* console.log(filteredArr) */
      /* console.log("<<<<>>>") */
    });
    return chartData;
  } catch {
    return [];
  }
}

export function padTo2Digits(num) {
  return num.toString().padStart(1, "0");
}
const wordInString = (s, word) => new RegExp("\\b" + word + "\\b", "i").test(s);
export function convertSectoMin(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}
export function isDateExpired(date) {
  const today = new Date();
  return new Date(date) < today;
}
export function _calculateAge(birthday) {
  // birthday is a date
  var today = new Date();
  var birthDate = new Date(birthday);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
export function getFillarWords(transcription) {
  var fillerWordsStr =
    "well,Actually,Basically,Seriously,You see,You know,I mean,You know what I mean,At the end of the day,Believe me,I guess,I suppose,Or something";
  var fillerWords = [
    "ah",
    "hmm",
    "like",
    "let's say",
    "um",
    "and you know",
    "right",
    "okay",
    "er",
    "uh",
    ...fillerWordsStr.split(",")
  ];
  var fillarArray = [];
  fillerWords.map(wd => {
    // var occur = getWordsOccurances(transcription,wd,false)
    var occur = wordInString(transcription, wd);
    if (occur) {
      fillarArray.push({
        word: wd,
        total: 1
      });
    }
  });
  return fillarArray;
}
export function getRandomQuotes() {
  var quotes = [
    {
      text: "I cannot teach anybody anything; I can only make them think.",
      name: "Socrates"
    },
    {
      text:
        "Tell me and I forget. Teach me and I remember. Involve me and I learn.",
      name: "Benjamin Franklin"
    },
    {
      text: "Teaching is the greatest act of optimism.",
      name: "Colleen Wilcox"
    },
    {
      text:
        "If you are planning for a year, sow rice; if you are planning for a decade, plant trees; if you are planning for a lifetime, educate people.",
      name: "Chinese Proverb"
    },

    {
      text:
        "Education breeds confidence. Confidence breeds hope. Hope breeds peace.",
      name: "Confucius"
    },
    {
      text:
        "If someone is going down the wrong road, he doesn’t need motivation to speed him up. What he needs is education to turn him around.",
      name: "Jim Rohn"
    },
    {
      text: "The art of teaching is the art of assisting discovery.",
      name: "Mark Van Doren"
    },
    {
      text: "What the teacher is, is more important than what he teaches.",
      name: "Karl Meninger"
    }
  ];
  return quotes[Math.floor(Math.random() * quotes.length)];
}
export function checkisImage(extension) {
  var imgArray = ["jpg", "jpeg", "png", "gif"];
  if (imgArray.includes(extension)) {
    return true;
  } else {
    return false;
  }
}
export function upsert(array, element) {
  // (1)
  const i = array.findIndex(_element => _element.category == element.category);
  if (i > -1) {
    array[i].value = array[i].value + element.value; // (2)
    return array;
  } else {
    array.push(element);
    return array;
  }
}
export function getCurrancyIcon(currency) {
  if (currency == "INR") {
    return "₹";
  } else if (currency == "USD") {
    return "$";
  } else if (currency == "GBP") {
    return "£";
  } else if (currency == "EUR") {
    return "€";
  } else if (currency == "CAD") {
    return "C$";
  } else if (currency == "AUD") {
    return "A$";
  }
}
export function convertStoMs(seconds) {
  let minutes = ~~(seconds / 60);
  let extraSeconds = seconds % 60;

  if (!minutes && !extraSeconds) {
    return false;
  } else {
    return minutes + " Min(s). " + extraSeconds + " Sec(s).";
  }
}
export function packageFeatureLabel(type) {
  switch (type) {
    case "mirror_rooms":
      return {
        title: "Job Interview Practice",
        descr: "Live interview practices for any position",
        route: "/interview/job_hiring",
        linkText: "Start Practice"
      };
      break;
    case "admission_rooms":
      return {
        title: "University Admission Interview",
        descr:
          "Live interview practices for university admissions in 11 countries",
        route: "/interview/university_admission",
        linkText: "Start Practice"
      };
      break;
    case "aptitude_test":
      return {
        title: "Apptitude Test",
        descr: "Complete aptitude test for different positions globally",
        route: "/candidate/aptitude_test",
        linkText: "Start Test"
      };
      break;
    case "flowchart":
      return {
        title: "Career Paths",
        descr: "Understand different paths to your dream role",
        route: "/candidate/career_paths",
        linkText: "Find Pathways"
      };
      break;
    case "role_discovery":
      return {
        title: "Role Discovery",
        descr: "Find roles that align with your career aspirations",
        route: "/candidate/role_discovery",
        linkText: "Discover Roles"
      };
      break;
    case "resume_relevency":
      return {
        title: "Resume Scorecard",
        descr: "How relevant is your resume to the role",
        route: "/candidate/resume_scorecard",
        linkText: "Get Your Score"
      };
      break;
    case "ai_recruiter_chat":
      return {
        title: "Chat with AI Recruiter",
        descr: "Get your job description related queries answered",
        route: "/candidate/recruiter_chat",
        linkText: "Start Asking"
      };
      break;
    case "mirror_rooms":
      return {
        title: "Job Interview Practice",
        descr: "Live interview practices for any position",
        route: "/interview/job_hiring",
        linkText: "Start Practice"
      };
      break;
    case "admission_rooms":
      return {
        title: "University Admission Interview",
        descr:
          "Live interview practices for university admissions in 11 countries",
        route: "/interview/university_admission",
        linkText: "Start Practice"
      };
      break;
    case "create_resume":
      return {
        title: "Create Resume",
        descr: "Create resumes in different format instantly",
        route: "/candidate/create_resume",
        linkText: "Create Resume"
      };
      break;
    case "cover_letter":
      return {
        title: "Cover Letter",
        descr: "Create cover letters in different format",
        route: "/candidate/cover-letter",
        linkText: "Create Cover Letter"
      };
      break;
    case "li_headline":
      return {
        title: "LinkedIn Headline",
        descr: "Create headline for your LinkedIn profile",
        route: "/candidate/linkedin_headline",
        linkText: "Create Headline"
      };
      break;
    case "li_summary":
      return {
        title: "LinkedIn Summary",
        descr: "Create summary for your LinkedIn profile",
        route: "/candidate/linkedin_summary",
        linkText: "Create Summary"
      };
      break;
    case "revised_video_user":
      return {
        title: "AI Generated Response (Beta)",
        descr: "Revised clone video with ideal response",
        route: "/candidate/interview_analytics",
        linkText: "Get Clone Video"
      };
      break;
    case "revised_video_ai_avatar":
      return {
        title: "Recommended Response",
        descr: "Get recommended answer using AI avatar",
        route: "/candidate/interview_analytics",
        linkText: "Get AI Avatar Response"
      };
      break;

    default:
      return null;
      break;
  }
}
export function categoryTagClass(tag) {
  switch (tag) {
    case "Intern":
      return "badge badge-warning badge-tag";
      break;
    case "Entry Level":
      return "badge badge-info badge-tag";
      break;

    default:
      break;
  }
}

export function getSubscriptionLabel(subscription) {
  console.log(subscription);
  switch (subscription) {
    case "quarterly":
      return "quarter";
      break;
    case "yearly":
      return "year";
      break;
    case "monthly":
      return "month";
      break;
    case "weekly":
      return "week";
      break;

    default:
      break;
  }
}
export function rePosition(c_package, priorityKeys) {
  const sortedKeys = Object.keys(c_package).sort((a, b) => {
    const indexOfA = priorityKeys.indexOf(a);
    const indexOfB = priorityKeys.indexOf(b);

    if (indexOfA !== -1 && indexOfB !== -1) {
      return indexOfA - indexOfB;
    }
    if (indexOfA !== -1) {
      return -1;
    }
    if (indexOfB !== -1) {
      return 1;
    }
    return 0;
  });

  const reorderedPackage = {};
  sortedKeys.forEach(key => {
    reorderedPackage[key] = c_package[key];
  });

  return reorderedPackage;
}

// export function rePosition(c_package, priorityKeys) {
//   // console.log(obj, key);

//   // const newObj = { [key]: obj[key] };
//   // // Delete the key from the original object
//   // delete obj[key];
//   // // Assign the remaining properties of the original object to the new object
//   // Object.assign(newObj, obj);

//   // return newObj;

//   return Object.keys(c_package).sort((a, b) => {
//     const indexOfA = priorityKeys.indexOf(a);
//     const indexOfB = priorityKeys.indexOf(b);

//     if (indexOfA !== -1 && indexOfB !== -1) {
//       return indexOfA - indexOfB;
//     }

//     if (indexOfA !== -1) {
//       return -1;
//     }

//     if (indexOfB !== -1) {
//       return 1;
//     }

//     return 0;
//   });
// }
export function replaceWithBr(str) {
  // console.log(typeof str)
  // console.log(str)
  str = str.toString();
  var clearedStr = str.replace(/["']/g, "");
  // console.log(typeof clearedStr)
  clearedStr = str.replace(/\n/g, "");
  // console.log(clearedStr)
  if (str.startsWith("<p>'")) {
    str = str.slice(4);
    str = str.slice(0, -4);
  }
  if (str.charAt(0) == "'") {
    str = str.slice(1);
  }
  if (str.charAt(str.length - 1) == "'") {
    str = str.slice(0, -1);
  }
  return str;
}
