import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
            // console.log('ScrollToTop------------->>>');
            window.scrollTo(0, 0)
            if(document && document.getElementById("scroller") && document.getElementById("scroller").scroll){
              document.getElementById("scroller").scroll(0,0)
            }
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  export default withRouter(ScrollToTop)
  