import * as CommonAction from "../constants/types.js";
import axios_origin from "axios";
import axios from "../config/api";
import cookie from "react-cookies";
import { getCompanyHiringInfo } from "./coach.js";
var cookie_options = {
  path: "/"
  // cookie_expires,
  // maxAge: 1000,
  // secure: false
};

export const candidateRegister = (data, cb) => {
  return dispatch => {
    axios
      .post("/candidate/register", data)
      .then(response => {
        console.log("data:", response);
        if (response.data.error) {
          cb(response.data.error, null);
        } else {
          cb(null, response.data);
        }
      })
      .catch(function(error) {});
  };
};
export const candidateSignIn = ({ email, password }, callback) => {
  return dispatch => {
    axios
      .post("/candidate/signin", { email, password })
      .then(({ data }) => {
        // console.log("data==",data)
        if (data.error && data.error !== "") {
          if (callback) {
            callback(data.error, null);
          }
        } else {
          dispatch({
            type: CommonAction.CANDIDATE_TOKEN_SET,
            payload: data.user
          });
          dispatch({
            type: CommonAction.AUTHENTICATE_CANDIDATE,
            payload: true
          });
          cookie.save("token", data.user, cookie_options);
          axios.defaults.headers.user = data.user;
          dispatch(getCandidateProfile(callback));
          // if(callback){
          //   callback(null,data);
          // }
        }
      })
      .catch(function(error) {});
  };
};
export const candidateGoogleAuth = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/google_detail", data)
      .then(({ data }) => {
        // console.log("data==",data)
        if (data.error && data.error !== "") {
          if (callback) {
            callback(data.error, null);
          }
        } else {
          if (callback) {
            callback(null, data);
          }
        }
      })
      .catch(function(error) {});
  };
};
export const candidateGoogleSignIn = (formData, callback) => {
  return dispatch => {
    axios
      .post("/candidate/google_login", formData)
      .then(({ data }) => {
        // console.log("data==",data)
        if (data.error && data.error !== "") {
          if (callback) {
            callback(data.error, null);
          }
        } else {
          dispatch({
            type: CommonAction.CANDIDATE_TOKEN_SET,
            payload: data.user
          });
          dispatch({
            type: CommonAction.AUTHENTICATE_CANDIDATE,
            payload: true
          });
          cookie.save("token", data.user, cookie_options);
          axios.defaults.headers.user = data.user;
          dispatch(getCandidateProfile());
          if (callback) {
            callback(null, data);
          }
        }
      })
      .catch(function(error) {
        console.log(error);
        callback("Email is not registered with us. Please signup.", null);
      });
  };
};

export const userForgotPassword = (uData, callback) => {
  return dispatch => {
    axios
      .post("/account/forgot-password", uData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response.status == 422) {
          console.log(error.response);
          if (callback) {
            callback(error.response.data.error, null);
          }
        }
      });
  };
};
export const checkResetCodeExist = (data, cb) => {
  return dispatch => {
    axios
      .post("/account/reset-password", data)
      .then(response => {
        console.log("data:", response);
        if (response.data.error) {
          cb(response.data.error, null);
        } else {
          cb(null, response.data);
        }
      })
      .catch(function(error) {});
  };
};
export const setUserPassword = (data, callback) => {
  return dispatch => {
    axios
      .post(`/account/set_password`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const userSignOut = callback => {
  return dispatch => {
    dispatch({ type: "RESET" });
    dispatch({ type: CommonAction.SIGNOUT_CANDIDATE_SUCCESS });
    dispatch({ type: CommonAction.CANDIDATE_TOKEN_SET, payload: false });
    dispatch({ type: CommonAction.AUTHENTICATE_CANDIDATE, payload: false });
    dispatch({ type: CommonAction.GET_CANDIDATE, payload: null });

    dispatch({ type: CommonAction.SIGNOUT_COACH_SUCCESS });
    dispatch({ type: CommonAction.COACH_TOKEN_SET, payload: false });
    dispatch({ type: CommonAction.AUTHENTICATE_COACH, payload: false });
    dispatch({ type: CommonAction.GET_COACH, payload: null });

    dispatch({ type: CommonAction.SIGNOUT_COMPANY_SUCCESS });
    dispatch({ type: CommonAction.COMAPNY_TOKEN_SET, payload: false });
    // dispatch({type: CommonAction.AUTHENTICATE_COMPANY, payload: false});
    dispatch({ type: CommonAction.GET_COMPANY, payload: null });

    cookie.remove("token", { path: "/" });
    cookie.remove("member", { path: "/" });
    cookie.remove("company", { path: "/" });
    // document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    // document.cookie = `member=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    axios.defaults.headers = { "Content-Type": "application/json" };
    if (callback) {
      callback();
    }
  };
};
export const getCandidateProfile = callback => {
  return dispatch => {
    axios
      .get("user_profile")
      .then(({ data }) => {
        if (data && data.id) {
          dispatch({ type: CommonAction.GET_CANDIDATE, payload: data });
          dispatch({
            type: CommonAction.AUTHENTICATE_CANDIDATE,
            payload: true
          });
        }
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getUserAuth = callback => {
  return dispatch =>
    axios
      .get("user_profile")
      .then(({ data }) => (data && data.id ? { auth: true } : { auth: false }))
      .catch(function(error) {});
};
export function userActivation(data, cb) {
  axios
    .post(`user_activation`, data)
    .then(response => {
      if (response.data.error) {
        cb(response.data.error, null);
      } else {
        cb(null, response.data);
      }
    })
    .catch(function(error) {});
}
export const getCountry = callback => {
  return dispatch => {
    axios
      .get("/country")
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COUNTRY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateProfile = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateDetails = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const updateCandidatePhone = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/phone_update", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const verifyCandidatePhoneOtp = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/verify_otp", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const sendInvitation = (data, callback) => {
  return dispatch => {
    axios
      .post("/send_request", data)
      .then(({ data }) => {
        // console.log(data);
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCoaches = (data, callback) => {
  return dispatch => {
    axios
      .post("/coaches", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_COACHES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

// export const getCoacheInfo = (key,callback) => async dispatch => {
//     const res = await  axios.get('/coach_info/'+key);
//     console.log('RESOLVED');
//     return dispatch({ type: CommonAction.GET_COACHE_INFO, payload: res.data });
// };

export const getCoacheInfo = (key, callback) => {
  return dispatch => {
    axios
      .get("/coach_info/" + key)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COACHE_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const sendRequestCoach = (data, callback) => {
  return dispatch => {
    axios
      .post("/request/coach", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const invitationAccept = (id, callback) => {
  return dispatch => {
    axios
      .get(`/candidate/accept_invitation/${id}`)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const invitationReject = (id, callback) => {
  return dispatch => {
    axios
      .get(`/candidate/reject_invitation/${id}`)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addNotes = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate/notes", bdata)
      .then(({ data }) => {
        dispatch(getNotes(bdata));
        // console.log(data);
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMeetings = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/meetings", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_CANDIDATE_MEETINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addScheduleMeeting = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/request-meeting", bdata)
      .then(({ data }) => {
        // console.log(data);
        if (callback) {
          callback(null, data);
        }
        dispatch(
          getMeetingSchedule({
            coach_id: bdata.coach_id
          })
        );
      })
      .catch(function(error) {});
  };
};
export const editScheduleMeeting = (data, callback) => {
  return dispatch => {
    axios
      .post("/edit-request-meeting", data)
      .then(({ data }) => {
        // console.log(data);
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateMemberTasks = (data, callback) => {
  return dispatch => {
    axios
      .post(`candidate_member_tasks`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COACH_MEMBER_TASKS, payload: data });
      })
      .catch(function(error) {});
  };
};

export const getMeetingSchedule = (data, callback) => {
  return dispatch => {
    axios
      .post(`candidate_meeting_schedule`, data)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_COACH_MEETING_SCHEDULE,
          payload: data
        });
      })
      .catch(function(error) {});
  };
};

export const deleteNotes = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`detete_note`, bdata)
      .then(({ data }) => {
        callback(null, data);
      })
      .catch(function(error) {});
  };
};
export const editNotes = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`edit_note`, bdata)
      .then(({ data }) => {
        callback(null, data);
      })
      .catch(function(error) {});
  };
};
export const getCandidateTasks = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/task", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_CANDIDATE_TASKS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidateVerification = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/verification", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getIndustries = (data, callback) => {
  return dispatch => {
    axios
      .get("/industries")
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_INDUSTRIES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getUpomingTasks = (data, callback) => {
  return dispatch => {
    axios
      .post(`candidate_upcoming_member_tasks`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COACH_MEMBER_TASKS, payload: data });
      })
      .catch(function(error) {});
  };
};

export const getScheduleMeetingInfo = (id, callback) => {
  return dispatch => {
    axios
      .get(`/meeting_info/${id}`)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const acceptClassRequest = (code, callback) => {
  return dispatch => {
    axios
      .get("/class_request/accept/" + code)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const rejectClassRequest = (code, callback) => {
  return dispatch => {
    axios
      .get("/class_request/reject/" + code)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const subscribe = (pData, callback) => {
  return dispatch => {
    axios
      .post("/sub", pData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export function userPaymentSuccess(sessionId, callback) {
  axios
    .get(`/payment-success-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}
export function userPaymentCancel(sessionId, callback) {
  axios
    .get(`/payment-cancel-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}

export function coachSubscriptionCancel(sub_id, data, callback) {
  return dispatch => {
    axios
      .post(`/cancel-subscription/${sub_id}`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}

export const getCandidateCoachColumns = column => {
  return dispatch => {
    axios
      .get("/coach/get_unique_values/" + column)
      .then(({ data }) => {
        dispatch({
          type: CommonAction["GET_CANDIDATE_COACH_" + column.toUpperCase()],
          payload: data
        });
      })
      .catch(function(error) {});
  };
};

export const getCoachMeetingInfo = (code, callback) => {
  return dispatch => {
    axios
      .post("/coach/meeting_info", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateClassHirings = (code, callback) => {
  return dispatch => {
    axios
      .post("/get_candidate_class_hirings", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CLASS_HIRINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

// export const getCompanyInfo = (code,callback) => {
//     return (dispatch) => {
//         axios.get('/get_company_info/'+code).then(({data}) => {
//             dispatch({type: CommonAction.GET_COMPANY_INFO, payload: data});
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//         });
//     }
// };
export const getCompanyInfo = code => async dispatch => {
  const res = await axios.get(`/get_company_info/` + code);
  console.log("RESOLVED");
  return dispatch({ type: CommonAction.GET_COMPANY_INFO, payload: res.data });
};

export const checkCandidateEmail = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/check_candidate_email", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const applyHiring = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/apply_hiring", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateHirings = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/get_candidate_hirings", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CANDIDATE_HIRINGS, payload: data });

        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateAppliedHirings = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/applied_candidate_hirings", bdata)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_CANDIDATE_APPLIED_HIRINGS,
          payload: data
        });

        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

// export const getHiringInfo = (code,callback) => {
//     return (dispatch) => {
//         axios.get('/get_hiring_info/'+code).then(({data}) => {
//             dispatch({type: CommonAction.GET_HIRING_INFO, payload: data});
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//         });
//     }
// };
export const getHiringInfo = (code, cb) => async dispatch => {
  const res = await axios.get(`/get_hiring_info/` + code);
  // console.log('RESOLVED');
  if (cb) {
    cb(null, res.data);
  }
  return dispatch({ type: CommonAction.GET_HIRING_INFO, payload: res.data });
};

export const getHiringReccomended = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/get_reccomended_hirings", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({
          type: CommonAction.GET_HIRING_OTHER_POSITION,
          payload: data
        });
      })
      .catch(function(error) {});
  };
};
export const companyInterested = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/company_interest", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCompanies = data => async dispatch => {
  dispatch({ type: CommonAction.GET_COMPANIES, payload: undefined });
  const res = await axios.post(`/companies`, data);
  console.log("RESOLVED");
  return dispatch({ type: CommonAction.GET_COMPANIES, payload: res.data });
};
export const removeCompanies = data => async dispatch => {
  dispatch({ type: CommonAction.GET_COMPANIES, payload: undefined });
};
export const getBlogs = data => async dispatch => {
  const res = await axios.post(`/blogs`, data);
  return dispatch({ type: CommonAction.FETCH_BLOGS, payload: res.data });
};
export const getOtherBlogs = data => async dispatch => {
  const res = await axios.post(`/blogs`, data);
  return dispatch({ type: CommonAction.FETCH_OTHER_BLOGS, payload: res.data });
};
// export const getBlogs = (data,callback) => {

//     return (dispatch) => {
//         axios.get('/blogs').then(({data}) => {
//             // console.log(data);
//             dispatch({type:CommonAction.FETCH_BLOGS,payload:data})
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//         });
//     }
// };

// export const getBlogContent = (type,cb) => {
//     return dispatch =>
//     axios.get(`/blog_info/${type}`).then(({data}) => {
//         dispatch({type:CommonAction.BLOG_INFO_CONTENT,payload:data})
//         // if(cb){
//         //     callback(null,cb)
//         // }
//     }).catch(function (error) {
//     });

// };

export const getBlogContent = type => async dispatch => {
  const res = await axios.get(`/blog_info/${type}`);
  return dispatch({ type: CommonAction.BLOG_INFO_CONTENT, payload: res.data });
};
export const addFeedbackForm = (data, callback) => {
  return dispatch => {
    axios
      .post(`create_feedback_form`, data)
      .then(({ data }) => {
        callback(null, data);
        // dispatch(getComapnyProfile());
      })
      .catch(function(error) {});
  };
};
export const getCandidateFeedbackForms = (data, callback) => {
  return dispatch => {
    axios
      .post(`get_candidate_feedbacks`, data)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_CANDIDATE_FEEDBAKFORMS,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getDefaultFeedbackQuestions = (data, callback) => {
  return dispatch => {
    axios
      .post(`get_default_questions`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_DEFAULT_QUESTIONS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

// export const getCompanies = (data,callback) => {
//     return (dispatch) => {
//     new Promise(resolve => {
//         // var data = [
//         //     {name:'ford'},
//         //     {name:'ford 2'},
//         //     {name:'ford 3'},
//         //     {name:'ford 4'},
//         //     {name:'ford 5'}
//         // ]
//         // dispatch({
//         //     type: CommonAction.GET_COMPANIES,
//         //     payload:data
//         // });
//         // resolve(data);
//         axios.post('/companies',data).then(({data}) => {
//             dispatch({type:CommonAction.GET_COMPANIES,payload:data})
//             console.log(data);
//             resolve(data);
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//             // resolve(data);
//         });
//     });
//     }
// };

export const getFeedbackForms = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate_feedback_forms", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({
          type: CommonAction.GET_CANDIDATE_FEEDBACK_FORMS,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getClassFeedbackForms = (data, callback) => {
  console.log(data);
  return dispatch => {
    axios
      .post("/class_feedback_forms", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({
          type: CommonAction.GET_CLASS_FEEDBACK_FORMS,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addFeedbackAnswers = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_feedback_answer", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch(getCandidateGivenFeedbacks(bdata));
      })
      .catch(function(error) {});
  };
};
export const getCandidateTests = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_tests", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CANDIDATE_TEST, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getTestInfo = data => async dispatch => {
  // dispatch({ type: CommonAction.FETCH_TEST_INFO, payload: undefined });
  const res = await axios.post(`/get_test`, data);
  return dispatch({ type: CommonAction.FETCH_TEST_INFO, payload: res.data });
};
export const addTestAnswers = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_test_answer", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const checkAttempts = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/check_attempts", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const setTestFormAnswer = (data, callback) => {
  console.log(data);
  return dispatch => {
    dispatch({ type: CommonAction.TEST_FORM_ANSWERS, payload: data });
    if (callback) {
      callback(null, true);
    }
  };
};
export const getTestStats = (key, callback) => {
  return dispatch => {
    axios
      .get("/test_stats/" + key)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.TEST_USER_STATS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getClassTests = (data, callback) => {
  return dispatch => {
    axios
      .post("/c_class_tests", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_CLASS_TESTS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getCandidateCompanyDetails = callback => {
  return dispatch => {
    axios
      .get("/candidate/company")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_COMPANY_DETAILS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getCandidateHired = callback => {
  return dispatch => {
    axios
      .get("/candidate/check/hired")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_COMPANY_HIRED, payload: data });
      })
      .catch(function(error) {});
  };
};
export const addepartmentDetails = (formData, callback) => {
  return dispatch => {
    axios
      .post("/add_department_details", formData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCompanyTree = callback => {
  return dispatch => {
    axios
      .get("/company/mapping/tree")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COMPANY_TREE, payload: data });
      })
      .catch(function(error) {});
  };
};
export const addDepartment = (data, callback) => {
  return dispatch => {
    axios
      .post("/company/add_department", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMDepartments = callback => {
  return dispatch => {
    axios
      .get("/company/department")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COMPANY_DEPARTMENTS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getMLeaders = (filter, callback) => {
  return dispatch => {
    axios
      .post("/department/leaders", filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_DEPARTMENT_LEADERS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getMCompanyTree = callback => {
  return dispatch => {
    axios
      .get("/candidate/company/tree")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_COMPANY_TREE, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getCompanyStatistics = callback => {
  return dispatch => {
    axios
      .get("/company/stats")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COMPANY_STATS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getHiringRounds = callback => {
  return dispatch => {
    axios
      .get("/company/rounds")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COMPANY_TROUNDS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getMCompanyDocuments = callback => {
  return dispatch => {
    axios
      .get("/candidate/company/documents")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_COMPANY_DOCUMENTS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getMCompanyDocumentInfo = (id, callback) => {
  return dispatch => {
    axios
      .get("/candidate/company/document/" + id)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_C_COMPANY_DOCUMENT, payload: data });
      })
      .catch(function(error) {});
  };
};

export const submitDocSign = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/company/sign", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getMCompanyOnboardingSteps = callback => {
  return dispatch => {
    axios
      .get("/candidate/company/onboardingsteps")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_ONBOARDING_STEPS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const addFormAnswers = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_form_answer", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch(getMCompanyOnboardingSteps());
      })
      .catch(function(error) {});
  };
};

export const getHirings = data => async dispatch => {
  const res = await axios.post(`/hirings`, data);
  console.log("RESOLVED");
  return dispatch({ type: CommonAction.GET_HIRINGS, payload: res.data });
};

export function getHirings2(filter, callback) {
  return dispatch => {
    axios
      .post("/hirings", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HIRINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
  // const res = await  axios.post(`/hirings`,data);
  // console.log('RESOLVED');
  // return dispatch({ type: CommonAction.GET_HIRINGS, payload: res.data });
}

export function getSearchHirings(filter, callback) {
  return dispatch => {
    axios
      .post("/search_hirings", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HIRINGS, payload: data.data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}

export const getCandidateGivenFeedbacks = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate_given_feedbacks", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_CANDIDATE_FEEDBACK, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getPredefinedTests = (filter, callback) => {
  return dispatch => {
    axios
      .post("/predefined_tests", filter)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_PREDEFINED_TESTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addPreDefinedTest = (tTata, hiring_key, callback) => {
  return dispatch => {
    axios
      .post("/assign_predefined_tests", tTata)
      .then(({ data }) => {
        dispatch(getHiringInfo(hiring_key));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCompanyRounds = callback => {
  return dispatch => {
    axios
      .post("/company/test/rounds")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.COMPANY_HIRINGS_ROUNDS, payload: data });
      })
      .catch(function(error) {});
  };
};
export const updateRoundTest = (formData, callback) => {
  return dispatch => {
    axios
      .post("/company/test/assign/rounds", formData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const editFeedback = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`edit_feedback`, bdata)
      .then(({ data }) => {
        callback(null, data);
      })
      .catch(function(error) {});
  };
};

export const deleteFeedback = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`detete_feedback`, bdata)
      .then(({ data }) => {
        callback(null, data);
      })
      .catch(function(error) {});
  };
};
export const getCandidateAllGivenFeedback = (data, callback) => {
  return dispatch => {
    axios
      .post(`candidate_given_all_feedbacks`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ALL_GIVEN_FEEDBAKS, payload: data });
      })
      .catch(function(error) {});
  };
};

export const getNotes = (data, callback) => {
  return dispatch => {
    axios
      .post("/get_candidate_notes", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_ALL_NOTES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateNotes = (data, callback) => {
  return dispatch => {
    axios
      .post(`get_candidate_notes`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ALL_NOTES, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getCoachNotes = (data, callback) => {
  return dispatch => {
    axios
      .post(`get_coach_notes`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ALL_NOTES, payload: data });
      })
      .catch(function(error) {});
  };
};
export const getEventInfo = (code, cb) => async dispatch => {
  const res = await axios.get(`/get_event_info/` + code);
  console.log("RESOLVED");
  if (cb) {
    cb(null, res.data);
  }
  return dispatch({ type: CommonAction.GET_EVENT_INFO, payload: res.data });
};
export const setEventApply = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`set_event_apply`, bdata)
      .then(({ data }) => {
        callback(null, data);
        dispatch(getEventInfo(bdata.key));
      })
      .catch(function(error) {});
  };
};

export const getTopSkills = data => async dispatch => {
  const res = await axios.post(`/get_top_hiring_skills`, data);
  console.log("RESOLVED");
  return dispatch({ type: CommonAction.GET_SKILLS, payload: res.data });
};

export const joinCompanyCommunity = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`join_company_community`, bdata)
      .then(({ data }) => {
        dispatch(getCompanyInfo(bdata.code));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const createCompanyCommunityPost = (bdata, callback) => {
  return dispatch => {
    axios
      .post(`create_community_post`, bdata)
      .then(({ data }) => {
        // dispatch(getCompanyInfo(bdata.code));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getPostComments = (post_id, callback) => {
  return dispatch => {
    axios
      .get(`/get_post_comments/${post_id}`)
      .then(({ data }) => {
        dispatch({ type: CommonAction.COMMUNITY_POST_COMMENTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCommunityPostComments = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/get_community_comments`, bdata).then(({ data }) => data);
};

export const createComment = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/create-comment`, bdata).then(({ data }) => data);
};

export const onUpVotes = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/on_comment_upvotes`, bdata).then(({ data }) => data);
};
export const onDownVotes = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/on_comment_downvotes`, bdata).then(({ data }) => data);
};
export const onPostUpVotes = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/on_post_upvotes`, bdata).then(({ data }) => data);
};
export const onPostDownVotes = bdata => {
  // return fetch(url).then((response) => response.json());
  return axios.post(`/on_post_downvotes`, bdata).then(({ data }) => data);
};
export const getNotification = callback => {
  return dispatch => {
    axios
      .get(`/notification`)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const onWebsiteAlerts = callback => {
  axios
    .get(`/website_alerts`)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};
export const getUserCommunities = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/user_communities`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_USER_COMMUNITIES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getRoomInfo = (code, callback) => {
  return dispatch => {
    axios
      .post("/room_info", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const checkRoomCandidate = (code, callback) => {
  axios
    .post("/check_room_candidate", code)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const getUserRooms = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/user_rooms`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_USER_ROOMS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addRoomFeedbackAnswers = (filter, callback) => {
  axios
    .post(`/add_room_answers`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const addRoomSessionAnswers = (filter, callback) => {
  axios
    .post(`/add_room_session_answers`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const getRoomSessionQuestions = callback => {
  axios
    .get(`/room_session_questions`)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};
export const updateRoomsHost = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/update_host`, filter)
      .then(({ data }) => {
        dispatch(getUserRooms(false));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const leaveRoom = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/leave_room`, filter)
      .then(({ data }) => {
        dispatch(getUserRooms(false));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getRoomFeedbacks = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/room_feedbacks`, rData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ROOM_FEEDBACKS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCoachRooms = data => async dispatch => {
  const res = await axios.post(`/coach_rooms`, data);
  return dispatch({ type: CommonAction.GET_ROOMS, payload: res.data });
};

export const getRoomAvailableTime = (filter, callback) => {
  axios
    .post(`/get_coach_room_availability`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const bookCoachRoom = (filter, callback) => {
  axios
    .post(`/book_coach_room`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const getOpenRooms = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/user_open_rooom`, rData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_OPEN_ROOMS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getOpenRoomHistory = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/user_open_rooom_history`, rData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_OPEN_ROOM_HISTORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addOpenRoomFeedbackAnswers = (filter, callback) => {
  axios
    .post(`/add_open_room_answers`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};
export const getRoomBlogs = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/blogs`, rData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ROOM_BLOGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const optOutRoom = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/optout_room`, rData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getRooms = (rData, callback) => {
  return dispatch => {
    axios
      .post(`/rooms`, rData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_C_ROOMS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getLiveRooms = data => async dispatch => {
  const res = await axios.post(`/live_rooms`, data);
  return dispatch({ type: CommonAction.GET_ROOMS, payload: res.data });
};

export const getCandidateLiveRoom = (filter, callback) => {
  return dispatch => {
    axios
      .post("/user_live_rooms", filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_CANDIDATE_LIVE_ROOMS,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getLiveRoomInfo = (code, callback) => {
  return dispatch => {
    axios
      .post("/live_room_info", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addLiveRoomFeedbackAnswers = (filter, callback) => {
  axios
    .post(`/add_live_room_answers`, filter)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const getCommunityPosts = (data, callback) => {
  return dispatch => {
    axios
      .post("/company_community_post", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMMUNITY_POSTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCompanyEmployees = (data, callback) => {
  return dispatch => {
    axios
      .post("/company_employees", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMPANY_EMPLOYEES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCompanyHirings = (data, callback) => {
  return dispatch => {
    axios
      .post("/company_hirings", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMPANY_HIRINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCompanyEvents = (data, callback) => {
  return dispatch => {
    axios
      .post("/company_events", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMPANY_EVENTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addLiveRoomHistory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/user_live_rooom_history`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_OPEN_ROOM_HISTORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getRoomMentors = data => async dispatch => {
  const res = await axios.get(`/home_coach_room_mentors`, data);
  return dispatch({ type: CommonAction.GET_ROOM_MENTORS, payload: res.data });
};
export const removeMirrorRoomData = (filter, callback) => {
  return dispatch => {
    dispatch({ type: CommonAction.GET_MIRROR_ROOM_CATEGORY, payload: null });
    dispatch({ type: CommonAction.GET_MIRROR_ROOM_SUBCATEGORY, payload: null });
  };
};
export const getMirrorRoomCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/mirror_room_category`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_CATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMirrorRoomSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/mirror_room_sub_category`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_SUBCATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMirrorRoomSetCandidate = data => {
  return dispatch => {
    dispatch({ type: CommonAction.SET_MIRROR_ROOM_CANDIDATE, payload: data });
  };
};
export const getMirrorRoomDetails = (filter, callback) => {
  return dispatch => {
    dispatch({ type: CommonAction.GET_MIRROR_ROOM_DETAILS, payload: false });
    axios
      .post(`/mirror_room_details`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MIRROR_ROOM_DETAILS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const setVideoCurrentQuestion = (currentQuestion, callback) => {
  return dispatch => {
    dispatch({
      type: CommonAction.SET_CURRENT_QUESTION,
      payload: currentQuestion
    });
  };
};
export const setHiringVideoCurrentQuestion = (currentQuestion, callback) => {
  return dispatch => {
    dispatch({
      type: CommonAction.SET_HIRING_CURRENT_QUESTION,
      payload: currentQuestion
    });
  };
};
export const addContactQuery = (data, callback) => {
  return dispatch => {
    axios
      .post(`/contact`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addMirrorRoomCandidate = (data, callback) => {
  return dispatch => {
    axios
      .post(`/add_mirror_room_candidate`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateMirrorRoomCandidate = (data, callback) => {
  return dispatch => {
    axios
      .post(`/update_room_candidate`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidateMirrorRoom = (filter, callback) => {
  return dispatch => {
    dispatch({ type: CommonAction.GET_CANDIDATE_MIRROR_ROOMS, payload: false });
    axios
      .post("/candidate_mirror_rooms", filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_CANDIDATE_MIRROR_ROOMS,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidateMirrorRoomAnalytics = (filter, callback) => {
  return dispatch => {
    axios.post("/mirror_room_analytics", filter).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const updateMirrorRoomAnalytics = (filter, callback) => {
  return dispatch => {
    axios.post("/update_mirror_room_analytics", filter).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getCompanyVideos = (filter, callback) => {
  return dispatch => {
    axios
      .post("/company_videos", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMPANY_VIDEOS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const uploadVideo = (vData, callback) => {
  return dispatch => {
    axios
      .post("/upload_video", vData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addMirrorFeedBack = (fData, callback) => {
  return dispatch => {
    axios.post("/add_mirror_room_feedback", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const updateMirrorRoomAnalytics2 = (filter, callback) => {
  return dispatch => {
    axios.post("/update_mirror_room_analytics2", filter).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const updateMirrorRoomQuestionAnalytics = (filter, callback) => {
  return dispatch => {
    axios
      .post("/update_mirror_room_questions_ai_analytics", filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      });
  };
};

export const getMirrorRoomInfo = (filter, callback) => {
  return dispatch => {
    axios
      .post("/candidate_mirror_room_info", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ROOM_INFO, payload: data });
        dispatch({
          type: CommonAction.GET_CANDIDATE_MIRROR_ROOM_INFO,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const handleCreateShare = (rData, callback) => {
  return dispatch => {
    axios.post("/createShare", rData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getMirrorRoomCompanies = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/mirror_room_companies`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_COMPANIES,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCCompanies = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_companies`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_COMPANIES,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const sendCoachMessage = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/msg_coach`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateMessagesUsers = (data, callback) => {
  return dispatch => {
    axios
      .post("/get_candidate_messages_user", data)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_CANDIDATE_MESSAGE_USER,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCoachMessages = (data, callback) => {
  return dispatch => {
    axios
      .post("/get_coach_messages", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_COACH_MESSAGE, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getRoomQuestions = data => async dispatch => {
  const res = await axios.post(`/room_questions`, data);
  return dispatch({ type: CommonAction.GET_ROOM_QUESTIONS, payload: res.data });
};

export const getRoomQuestionCategories = data => async dispatch => {
  const res = await axios.post(`/room_question_categories`, data);
  return dispatch({
    type: CommonAction.GET_ROOM_QUESTION_CATEGORIES,
    payload: res.data
  });
};

export const getRoomQuestionSubCategories = data => async dispatch => {
  const res = await axios.post(`/room_question_subcategories`, data);
  return dispatch({
    type: CommonAction.GET_ROOM_QUESTION_SUBCATEGORIES,
    payload: res.data
  });
};

export const getRoomQuestionInfo = type => async dispatch => {
  const res = await axios.get(`/room_question_info/${type}`);
  return dispatch({
    type: CommonAction.GET_ROOM_QUESTION_INFO,
    payload: res.data
  });
};

export const addCoachRoomCandidate = (data, callback) => {
  return dispatch => {
    axios
      .post("/add_coach_room_candidate", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

// export const getCoachRoomDetails = (filter,callback) => {
//     return (dispatch) => {
//         axios.post(`/coach_room_details`,filter).then(({data}) => {
//             dispatch({ type: CommonAction.GET_MIRROR_ROOM_DETAILS, payload: data });
//             if(callback){
//                 callback(null,data);
//             }
//         }).catch(function (error) {
//         });
//     }
// };

export const getCoachRoomInfo = (code, callback) => {
  return dispatch => {
    axios
      .post("/coach_room_info", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCoachRoomTimes = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/coach_room_times`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COACH_ROOM_TIMES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addCoachRoomFeedback = (fData, callback) => {
  return dispatch => {
    axios.post("/add_coach_room_feedback", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const suggestHiringPosition = (fData, callback) => {
  return dispatch => {
    axios.post("/suggest_hiring_position", fData).then(({ data }) => {
      dispatch(getSuggestHiringPositions());
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getSuggestHiringPositions = (fData, callback) => {
  return dispatch => {
    axios.post("/get_suggest_hiring_positions", fData).then(({ data }) => {
      dispatch({ type: CommonAction.GET_SUGGESTED_HIRING, payload: data });
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const removeSuggestHiringPositions = (fData, callback) => {
  return dispatch => {
    axios.post("/remove_suggest_hiring_positions", fData).then(({ data }) => {
      dispatch(getSuggestHiringPositions());
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const getCandidateHiringInfo = (code, callback) => {
  return dispatch => {
    axios.get(`/get_candidate_hiring_info/` + code).then(({ data }) => {
      dispatch({ type: CommonAction.GET_HIRING_INFO, payload: data });
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const getCandidateHiringTestInfo = (bdata, callback) => {
  return dispatch => {
    axios.post(`/get_hiring_test_info`, bdata).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const addHiringTestAnswers = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_hiring_test_answer", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        if (callback) {
          callback(null, {});
        }
      });
  };
};

export const getHiringMirrorRoomDetails = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/hiring_mirror_room_details`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MIRROR_ROOM_DETAILS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const uploadHirincCandidateVideo = (vData, callback) => {
  return dispatch => {
    axios
      .post("/upload_hiring_video", vData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getHiringMirrorRoomInfo = (filter, callback) => {
  return dispatch => {
    axios
      .post("/candidate_hiring_mirror_room_info", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_ROOM_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateSharedHirings = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/shared_candidate_hirings", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_SHARED_HIRINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidatePlacementHirings = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/placement_candidate_hirings", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_PLACEMENT_HIRINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateUniTests = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_university_tests", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CANDIDATE_UNI_TESTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateUniRooms = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_university_rooms", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CANDIDATE_UNI_ROOMS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addHiringMirrorFeedBack = (fData, callback) => {
  return dispatch => {
    axios.post("/add_hiring_mirror_room_feedback", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const changeHiringStatus = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/change_candidate_hiring_status", bdata)
      .then(({ data }) => {
        dispatch(getCandidateAppliedHirings({}, callback));
      })
      .catch(function(error) {});
  };
};
export const getLanguages = (bdata, callback) => {
  return dispatch => {
    axios
      .get("/fetch_languages", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_LANGUAGES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getTestgroups = callback => {
  return dispatch => {
    axios
      .get("/test_groups")
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_TEST_GROUPS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
// export const getPredefinedRooms = (bdata,callback) => {
//     return (dispatch) => {
//         axios.post('/pre_defined_rooms',bdata).then(({data}) => {
//             dispatch({type:CommonAction.GET_PREDEFINED_MIRROR_ROOMS,payload:data})
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//         });
//     }
// };
export const getPredefinedRooms = data => async dispatch => {
  // dispatch({ type: CommonAction.GET_PREDEFINED_MIRROR_ROOMS, payload: undefined });
  const res = await axios.post(`/pre_defined_rooms`, data);
  console.log("RESOLVED");
  return dispatch({
    type: CommonAction.GET_PREDEFINED_MIRROR_ROOMS,
    payload: res.data
  });
};

export const getAdmissionPredefinedRooms = data => async dispatch => {
  // dispatch({ type: CommonAction.GET_PREDEFINED_MIRROR_ROOMS, payload: undefined });
  const res = await axios.post(`/pre_uni_defined_rooms`, data);
  console.log("RESOLVED");
  return dispatch({
    type: CommonAction.GET_PREDEFINED_UNI_MIRROR_ROOMS,
    payload: res.data
  });
};

export const getAdmissionPredefinedUniLists = data => async dispatch => {
  const res = await axios.post(`/pre_uni_defined_room_universities`, data);
  return dispatch({
    type: CommonAction.GET_PREDEFINED_UNI_LISTS,
    payload: res.data
  });
};
export const getAdmissionPredefinedRoomInfo = data => async dispatch => {
  // console.log('<<<<<<<<<<<< getAdmissionPredefinedRoomInfo >>>>>>>>>>>>');
  // dispatch({ type: CommonAction.GET_PREDEFINED_MIRROR_ROOMS, payload: undefined });
  const res = await axios.post(`/pre_uni_defined_room_info`, data);
  console.log("RESOLVED >>>>>>");
  return dispatch({
    type: CommonAction.GET_PREDEFINED_MIRROR_ROOM_INFO,
    payload: res.data
  });
};
export const getHiringsMeetings = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/get_hiring_meetings", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HIRING_MEETINGS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getHiringMeetingInfo = (code, callback) => {
  return dispatch => {
    axios
      .post("/hiring/meeting_info", code)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HIRING_MEETING_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getHiringCandMeetingNotes = (data, callback) => {
  return dispatch => {
    axios
      .post("/hiring/meeting/candidate/get_notes", data)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: CommonAction.GET_ALL_NOTES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const addHiringCandMeetingNotes = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/hiring/meeting/candidate/add_notes", bdata)
      .then(({ data }) => {
        dispatch(getNotes(bdata));
        // console.log(data);
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const checkCodeExist = (data, callback) => {
  return dispatch => {
    axios
      .post(`/candidate/check_code`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const setPassword = (data, callback) => {
  return dispatch => {
    axios
      .post(`/candidate/set_password`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getSignupCodeDetails = (code, callback) => {
  return dispatch => {
    axios
      .get(`/get_signup_code_details/` + code)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidatePwd = (pData, callback) => {
  return dispatch => {
    axios
      .post(`/candidate/change_password`, pData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getHiringTestResult = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/hiring_test_result", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getHomeFeedback = (filter, callback) => {
  return dispatch => {
    axios
      .post("/home_feedback", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HOME_FEEDBACKS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidatePricing = (pData, callback) => {
  return dispatch => {
    axios
      .post("/candidate_pricing", pData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CANDIDATE_PRICING, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidatePricing = (pData, callback) => {
  return dispatch => {
    axios
      .post("/candidate_pricing_update", pData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const candidateSubscribe = (pData, callback) => {
  return dispatch => {
    axios
      .post("/candidate_sub", pData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidateList = (pData, callback) => {
  return dispatch => {
    axios
      .get("/test_candidate_list")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
// export const getMirrorRoomPageDetails = (pData,callback) => {
//     return (dispatch) => {
//         axios.post('/mirror-room-page',pData).then(({data}) => {
//             if(callback){
//                 callback(null,data)
//             }
//         }).catch(function (error) {
//         });
//     }
// };
export const getMirrorRoomPageDetails = data => async dispatch => {
  const res = await axios.post(`/mirror-room-page`, data);
  return dispatch({
    type: CommonAction.GET_MIRROR_ROOM_PDATA,
    payload: res.data
  });
};
export const getSearchMirrorRooms = (pData, callback) => {
  return dispatch => {
    axios
      .post("/search-mirror-rooms", pData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCommunityUserStats = (data, callback) => {
  return dispatch => {
    axios
      .post("/get_community_user_stats", data)
      .then(({ data }) => {
        // dispatch({type: CommonAction.GET_COMMUNITY_POSTS, payload: data});
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getHiringRooms = (data, callback) => {
  return dispatch => {
    axios
      .post("/get_hiring_rooms", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HIRING_ROOMS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getCandidatePackages = callback => {
  return dispatch => {
    axios
      .get("/get_candidate_packages")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const cancelCandidatePackages = callback => {
  return dispatch => {
    axios
      .get("/cancel_subscription")
      .then(({ data }) => {
        dispatch(getCandidateProfile());
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export function getGoogleContacts(accessToken, callback) {
  return function(dispatch) {
    axios_origin
      .get(
        `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&access_token=` +
          accessToken
      )
      .then(response => {
        if (response.data && response.data.connections) {
          var contacts = response.data.connections;
          var googleContacts = [],
            gmailSelectedContacts = [];
          for (let i in contacts) {
            if (contacts[i].emailAddresses) {
              googleContacts.push({
                name: contacts[i].names ? contacts[i].names[0].displayName : "",
                email: contacts[i].emailAddresses[0].value,
                id: contacts[i].emailAddresses[0].value
              });
              //   gmailSelectedContacts.push({
              //     id:contacts[i].emailAddresses[0].value,
              //     text:contacts[i].emailAddresses[0].value
              //   });
            }
          }
          callback(null, googleContacts);
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 401) {
          callback(error.response, null);
        }
      });
  };
}
export const inviteContacts = (cData, callback) => {
  return dispatch => {
    axios
      .post("/invite_contacts", cData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const createReferralCode = callback => {
  return dispatch => {
    axios
      .get("/create_referral")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const checkCouponCode = (cData, callback) => {
  return dispatch => {
    axios
      .post("/check_coupon", cData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMRFeedBackQuestion = callback => {
  return dispatch => {
    axios
      .get("/mirror_room_feedback_questions")
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getMirrorRoomScore = (cData, callback) => {
  // return (dispatch) => {
  axios
    .post("/get_mirror_room_score", cData)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
  // }
};

export const getHiringMirrorRoomScore = (cData, callback) => {
  // return (dispatch) => {
  axios
    .post("/get_hiring_mirror_room_score", cData)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
  // }
};

export const getCandidateNextFrooms = (cData, callback) => {
  // return (dispatch) => {
  axios
    .post("/candidate_next_frooms", cData)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
  // }
};

export const getPracticeCount = (cData, callback) => {
  return dispatch => {
    axios
      .post("/get_practice_count", cData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getTournaments = (pData, callback) => {
  return dispatch => {
    axios
      .post("/tournaments", pData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_TOURNAMENTS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getPageTournaments = data => async dispatch => {
  const res = await axios.post(`/tournaments`, data);
  return dispatch({ type: CommonAction.GET_TOURNAMENTS, payload: res.data });
};
export const getTournamentInfo = (pData, callback) => {
  return dispatch => {
    axios
      .post("/tournament_info", pData)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_TOURNAMENT_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const notifyTournamentUser = (data, callback) => {
  return dispatch => {
    axios
      .post("/tour_notification", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getHiringColumns = (filter, callback) => {
  return dispatch => {
    axios
      .post("/hiring/get_unique_values/" + filter.column, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getTournamentLeaderBoard = (fData, callback) => {
  return dispatch => {
    axios
      .post("/tournament_leaderboard", fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getTournamentCountries = (fData, callback) => {
  return dispatch => {
    axios
      .post("/tournament_countries", fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addHiringMirrorRoomCandidate = (data, callback) => {
  return dispatch => {
    axios
      .post(`/add_hiring_mirror_room_candidate`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getProfileViews = callback => {
  return dispatch => {
    axios
      .get(`/profile-views`)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_PROFILE_VIEWS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const shareRoomWithFriends = (bdata, callback) => {
  axios
    .post("/share_room", bdata)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};
export const getMirroRoomShared = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/mirror_room_shared", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.MIRROR_ROOM_SHARED, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateSharedRooms = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_shared_mirror_room", bdata)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.CANDIDATE_SHARED_MIRROR_ROOM,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addSharedMirrorFeedBack = (fData, callback) => {
  return dispatch => {
    axios.post("/add_shared_room_feedback", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const addRoomReviewer = (bdata, callback) => {
  axios
    .post("/add_room_reviewer", bdata)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};
export const getMirroRoomReviewers = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/mirror_room_reviewers", bdata)
      .then(({ data }) => {
        dispatch({ type: CommonAction.MIRROR_ROOM_REVIEWER, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCandidateReviewRooms = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/candidate_review_mirror_room", bdata)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.CANDIDATE_REVIEW_MIRROR_ROOM,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getReviewRoomInfo = (bdata, callback) => {
  axios
    .post("/candidate_review_mirror_room_info", bdata)
    .then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    })
    .catch(function(error) {});
};

export const addSharedMirrorReview = (fData, callback) => {
  return dispatch => {
    axios.post("/add_review_room_feedback", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getReviewCandidateInfo = (fData, callback) => {
  axios.post("/review_mirror_room_candidatewise", fData).then(({ data }) => {
    if (callback) {
      callback(null, data);
    }
  });
};
export const getExpertsData = callback => {
  axios.get("/get_experts").then(({ data }) => {
    if (callback) {
      callback(null, data);
    }
  });
};
export const getCultureAssessMentData = callback => {
  return dispatch => {
    axios.get("/get_culture_assessement").then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getPositionQuestions = (cData, callback) => {
  return dispatch => {
    axios.post("/position_questions", cData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const getPracticePages = data => async dispatch => {
  const res = await axios.get(`/get_practice_pages`);
  return dispatch({ type: CommonAction.GET_PRACTICE_PAGES, payload: res.data });
};

export const getPracticePageInfo = data => async dispatch => {
  const res = await axios.post(`/get_practice_page`, data);
  return dispatch({
    type: CommonAction.GET_PRACTICE_PAGE_INFO,
    payload: res.data
  });
};
export const getRoomCompany = callback => {
  return dispatch => {
    axios.get("/get_room_company").then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};
export const getCompaniesRoom = (rData, callback) => {
  return dispatch => {
    axios.post("/get_company_rooms", rData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const getDomainData = (data, callback) => {
  return dispatch => {
    axios
      .post(`/check_company_domain`, data)
      .then(({ data }) => {
        if (!data.error) {
          dispatch({ type: CommonAction.GET_COMPANY_DOMAIN, payload: data });
        }
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getSchoolCountries = (fData, callback) => {
  return dispatch => {
    axios.post("/get_school_country", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
      dispatch({ type: CommonAction.GET_SCHOOL_COUNTRIES, payload: data });
    });
  };
};
export const getSchoolGrades = (fData, callback) => {
  return dispatch => {
    axios.post("/get_school_grades", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
      dispatch({ type: CommonAction.GET_SCHOOL_GRADES, payload: data });
    });
  };
};
export const getSchoolSubjects = (fData, callback) => {
  return dispatch => {
    axios.post("/get_school_subjects", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
      dispatch({ type: CommonAction.GET_SCHOOL_SUBJECTS, payload: data });
    });
  };
};
export const getSchoolTopics = (fData, callback) => {
  return dispatch => {
    axios.post("/get_school_topics", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
      dispatch({ type: CommonAction.GET_SCHOOL_TOPICS, payload: data });
    });
  };
};
export const getSchoolTopicsParagraph = (fData, callback) => {
  return dispatch => {
    axios.post("/get_topic_paragraph", fData).then(({ data }) => {
      if (callback) {
        callback(null, data);
      }
    });
  };
};

export const getSchoolPracticeInfo = fData => async dispatch => {
  // dispatch({ type: CommonAction.GET_SCHOOL_PRACTICE_INFO, payload: {} });
  const res = await axios.post(`/get_school_practice_info`, fData);
  return dispatch({
    type: CommonAction.GET_SCHOOL_PRACTICE_INFO,
    payload: res.data
  });
};
// export const getSchoolPracticeInfo = (fData,callback) => {

//         axios.post('/get_school_practice_info',fData).then(({data}) => {
//             if(callback){
//                 callback(null,data)
//             }
//             dispatch({ type: CommonAction.GET_SCHOOL_PRACTICE_INFO, payload: data });
//         })
//     }
// };
export const getHomeVideos = callback => {
  return dispatch => {
    axios
      .get("/home_videos")
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HOME_VIDEOS, payload: data });
      })
      .catch(function(error) {});
  };
};

export function CreateCheckoutSession(data, callback) {
  axios
    .post(`/user/create-checkout-session`, data)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}

export function CreateCourseCheckoutSession(data, callback) {
  return dispatch => {
    axios
      .post(`/course/create-checkout-session`, data)
      .then(({ data }) => {
        callback(null, data);
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}

export function PricingPaymentSuccess(sessionId, callback) {
  axios
    .get(`/pricing/payment-success-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}
export function PricingPaymentCancel(sessionId, callback) {
  axios
    .get(`/pricing/payment-cancel-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}

export const getCCourses = data => async dispatch => {
  const res = await axios.post(`/c_courses`, data);
  return dispatch({ type: CommonAction.FETCH_COURSES, payload: res.data });
};

export const getCourses = data => async dispatch => {
  const res = await axios.post(`/courses`, data);
  return dispatch({ type: CommonAction.FETCH_COURSES, payload: res.data });
};

export const getCourseInfo = type => async dispatch => {
  const res = await axios.get(`/course_info/${type}`);
  var course_info = res.data;
  if (course_info && course_info.id) {
    dispatch(getCourseItems({ pagetitle: course_info.pagetitle }));
    dispatch(getCourseReviews({ course_id: course_info.id }));
  }
  return dispatch({ type: CommonAction.COURSE_INFO, payload: res.data });
};

export const getCourseItems = data => async dispatch => {
  const res = await axios.post(`/course_items`, data);
  return dispatch({ type: CommonAction.COURSE_ITEMS, payload: res.data });
};

export const getCourseItemInfo = data => async dispatch => {
  const res = await axios.post(`/course_item_info`, data);
  if (res && res.data && res.data.item) {
    dispatch(
      getCourseItemComments({
        course_id: res.data.id,
        item_id: res.data.item.id
      })
    );
  }
  return dispatch({ type: CommonAction.COURSE_ITEM_INFO, payload: res.data });
};

export const addCourseReview = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_course_review", bdata)
      .then(({ data }) => {
        dispatch(getCourseReviews(bdata));
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCourseReviews = (data, callback) => {
  return dispatch => {
    console.log("<<<<<<<<<<<<<<<<<<< fetch review >>>>>>>>>>>>>");
    axios
      .post("/get_course_review", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COURSE_REVIEWS, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const addCourseItemComment = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_course_item_comment", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getCourseItemComments = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/get_course_item_comments", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({
          type: CommonAction.GET_COURSE_ITEM_COMMENTS,
          payload: data
        });
      })
      .catch(function(error) {});
  };
};

export const addRoomQuestionFeedback = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/add_question_feedback", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getHomeDomains = (bdata, callback) => {
  return dispatch => {
    axios
      .post("/home_domains", bdata)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_HOME_DOMAINS, payload: data });
      })
      .catch(function(error) {});
  };
};
export function CoursePaymentSuccess(sessionId, callback) {
  axios
    .get(`/course/payment-success-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}
export function CoursePaymentCancel(sessionId, callback) {
  axios
    .get(`/course/payment-cancel-session/${sessionId}`)
    .then(({ data }) => {
      callback(null, data);
    })
    .catch(function(error) {
      // console.log("Error****:", error.message);
    });
}
export function getCourseBundles(fData, callback) {
  return dispatch => {
    axios
      .post(`/get_course_bundles`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COURSE_BUNDLES, payload: data });
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
export function getCourseBundleInfo(pagetitle, callback) {
  console.log(pagetitle);
  return dispatch => {
    axios
      .get(`/bundle_info/${pagetitle}`)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_COURSE_BUNDLE_INFO, payload: data });
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
export function deletePracticeVideo(fData, callback) {
  return dispatch => {
    axios
      .post(`/delete_analytics_video`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
export function getUserCourses(fData, callback) {
  return dispatch => {
    axios
      .post(`/get_user_courses`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
        dispatch({ type: CommonAction.GET_USER_COURSES, payload: data });
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
export function getFreePracticeInfo(fData, callback) {
  return dispatch => {
    axios
      .post(`/get_free_practice`, fData)
      .then(({ data }) => {
        if (fData && fData.pagetitle) {
          dispatch({
            type: CommonAction.SET_FREE_PRACTICE_INFO,
            payload: data
          });
        }
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}

export function getRoomCouterQuestions(fData, callback, cc_questionCounter) {
  return dispatch => {
    if (cc_questionCounter < 1) {
      axios
        .post(`/get_counter_questions`, fData)
        .then(({ data }) => {
          if (callback) {
            callback(null, data);
          }
        })
        .catch(function(error) {});
    } else {
      if (callback) {
        callback(null, { error: true });
      }
    }
  };
}
export function textTest(callback) {
  return dispatch => {
    axios
      .get(`/test_translate`)
      .then(({ data }) => {
        if (callback) {
          var buf = new ArrayBuffer(data.length * 2); // 2 bytes for each char
          var bufView = new Uint16Array(buf);
          for (var i = 0, strLen = data.length; i < strLen; i++) {
            bufView[i] = data.charCodeAt(i);
          }
          console.log(buf.byteLength);
          callback(null, buf);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
// export function checkAnswerGrammer(answer,language,callback) {
//     const languagetool = require("languagetool-api");
//     var params = {
//         language: language,
//         text: answer
//       };
//       languagetool.check(params, function(err, res){
//           if(err){
//             console.log(" >>>>>>>>>>>>>>>>> grammer check ",err);
//              callback(true,null)
//           } else{
//             console.log(res);
//             console.log("<<<<<<<<< res >>>>>>>>>");
//             languagetool.showMistakes(res, function(arr){
//                 languagetool.bestSuggestion(res, function(sugarr){
//                     console.log(sugarr);
//                     console.log('mistatkes >>>',arr);
//                     callback(null,arr)
//                 });
//             });

//           }
//       });
// };
export function checkGrammerMistakes(fData, language_code, callback) {
  return dispatch => {
    if (language_code && language_code == "en-US") {
      axios
        .post(`/check_grammer_mistakes`, fData)
        .then(({ data }) => {
          if (callback) {
            callback(null, data);
          }
        })
        .catch(function(error) {});
    } else {
      callback(null, { error: true });
    }
  };
}
export function createRoomCategory(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_new_room_category`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function createResumeInfo(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_resume_info`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function createCoverLetter(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_cover_letter`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export const getMicroCourses = (data, callback) => {
  //console.log(data,"3145-----------------");
  return dispatch => {
    axios
      .post("/micro_courses", data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.FETCH_MICRO_COURSES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export function createLinkedinHeadline(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_linkedin_headline`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function createLinkedinSummary(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_linkedin_summary`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function createResume(fData, callback) {
  return dispatch => {
    axios
      .post(`/create_resume`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function coverLetter(fData, callback) {
  return dispatch => {
    axios
      .post(`/cover_letter`, fData)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export const getProficiencyPageCategory = data => async dispatch => {
  const res = await axios.get(`/get_proficiency_page_category`);
  return dispatch({
    type: CommonAction.GET_PROFICIENCY_PAGE_CATEGORY,
    payload: res.data
  });
};

export const getMirrorRoomSubCategory1 = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/mirror_room_sub_category1`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_SUBCATEGORY1,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getMirrorRoomVisaCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/mirror_room_visa_category`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_MIRROR_ROOM_VISA_CATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const fetchCountry = callback => {
  return dispatch => {
    axios
      .get("/countries")
      .then(({ data }) => {
        dispatch({ type: CommonAction.FETCH_COUNTRY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export function AIChat(Data, callback) {
  console.log(Data, "3235");
  return dispatch => {
    axios
      .post(`/ai_chat`, Data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function AIResponse(Data, callback) {
  return dispatch => {
    axios
      .post(`/ai_response`, Data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function getCompany(filter, callback) {
  return dispatch => {
    axios
      .post("/company", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_COMPANY_LIST, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}

export const getDebateCategory = data => async dispatch => {
  console.log("debate skills");
  const res = await axios.get(`/get_debate_category`);
  return dispatch({
    type: CommonAction.GET_DEBATE_CATEGORY,
    payload: res.data
  });
};

export const getDebateSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_debate_sub_category`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_DEBATE_SUBCATEGORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getPresentationCategory = data => async dispatch => {
  const res = await axios.get(`/get_presentation_category`);
  return dispatch({
    type: CommonAction.GET_PRESENTATION_CATEGORY,
    payload: res.data
  });
};

export const getPresentationSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_presentation_sub_category`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_PRESENTATION_SUBCATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getMeetingCategory = data => async dispatch => {
  const res = await axios.get(`/get_meeting_category`);
  return dispatch({
    type: CommonAction.GET_MEETING_CATEGORY,
    payload: res.data
  });
};

export const getMeetingSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_meeting_sub_category`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_MEETING_SUBCATEGORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export const getReadingCategory = data => async dispatch => {
  const res = await axios.get(`/get_reading_category`);
  return dispatch({
    type: CommonAction.GET_READING_CATEGORY,
    payload: res.data
  });
};

export const getReadingSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_reading_sub_category`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_READING_SUBCATEGORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export function fetchReadParagraph(Data, callback) {
  return dispatch => {
    axios
      .post(`/fetch_read_paragraph`, Data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function matchParagraphText(Data, callback) {
  return dispatch => {
    axios
      .post(`/match_read_paragraph`, Data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getHomeHirings(callback) {
  return dispatch => {
    axios
      .get(`/home_hiring`)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export const getPracticeTypes = (filter, callback) => {
  return dispatch => {
    axios
      .get(`/get_pracice_types`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_PRACTICE_TYPES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export function fetchMirrorRoomCategory(filter, callback) {
  return dispatch => {
    axios
      .post("/get_mirror_room_category", filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.FETCH_MIRROR_ROOM_CATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {
        // console.log("Error****:", error.message);
      });
  };
}
export function uploadResume(data, callback) {
  return dispatch => {
    axios
      .post(`/upload_resume`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getOtherQustions(filter, callback) {
  return dispatch => {
    axios
      .post(`/get_other_questions`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getRoleDiscoveryInfo(data, callback) {
  return dispatch => {
    axios
      .post(`/role_discovery`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getMCQResult(data, callback) {
  return dispatch => {
    axios
      .post(`/role_discovery_result`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function addCustomQustions(filter, callback) {
  return dispatch => {
    axios
      .post(`/add_custom_questions`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function generateAIQuestions(filter, callback) {
  return dispatch => {
    axios
      .post(`/generate_ai_questions`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function generateAnswerByGpt(filter, callback) {
  return dispatch => {
    axios
      .post(`/get_answer_gpt`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function getFillerFromPython(filter, callback) {
  return dispatch => {
    // axios_origin.post(`http://35.87.220.86:5000/video_filler_words`,filter).then(({data}) => {
    //     if(callback){
    //         callback(null,data)
    //     }
    // }).catch(function (error) {});
  };
}
export function getAIMCQUserAnswer(data, callback) {
  console.log(data, "data");
  return dispatch => {
    axios
      .get(`/get_ai_mcq_user_answer/${data.id}`)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_AI_MCQ_USER_ANSWER, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getAnalayzeResumeHistory(data, callback) {
  console.log(data, "data");
  return dispatch => {
    axios
      .get(`/get_analyse_resume_history/${data.id}`)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_ANALYZE_RESUME_HISTORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getResumeAnalysysInfo(data, callback) {
  return dispatch => {
    axios
      .post(`/get_resume_analysis_info/${data.id}`)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_RESUME_ANALYSIS_INFO,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getRoleDiscoveryDetailInfo(data, callback) {
  return dispatch => {
    axios
      .post(`/get_role_discovery_detail_info/${data.id}`)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_ROLE_DISCOVERY_DETAIL_INFO,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function getFAQ(data, callback) {
  return dispatch => {
    axios
      .post(`/get_faq`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_FAQ, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getFAQQuestionList(data, callback) {
  console.log(data, "data");
  return dispatch => {
    axios
      .post(`/get_faq_question_list`, data)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_FAQ_QUESTION_LIST, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getFAQAnswer(data, callback) {
  console.log(data, "data");
  return dispatch => {
    axios
      .post(`/get_faq_answer`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getFAQQuestion(data, callback) {
  console.log(data, "data");
  return dispatch => {
    axios
      .post(`/get_faq_question`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export const addFeedback = (data, callback) => {
  return dispatch => {
    axios
      .post(`/add_feedback`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getIntroductionCategory = data => async dispatch => {
  const res = await axios.get(`/get_introduction_category`);
  return dispatch({
    type: CommonAction.GET_INTRODUCTION_CATEGORY,
    payload: res.data
  });
};
export const getIntroductionSubCategory = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_introduction_sub_category`, filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_INTRODUCTION_SUBCATEGORY,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const getUniversityCourses = (filter, callback) => {
  return dispatch => {
    axios
      .post(`/get_university_courses`, filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_UNIVERSITY_COURSES, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export function createResumeTemplate(data, callback) {
  return dispatch => {
    axios
      .post(`/create_resume_template`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getFlowChartData(data, callback) {
  return dispatch => {
    axios
      .post(`/get_flow_chart_data`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getAptitudeTestInfo(data, callback) {
  return dispatch => {
    axios
      .post(`/aptitude_test`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getAptitudeMCQResult(data, callback) {
  return dispatch => {
    axios
      .post(`/aptitude_test_result`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getAIApptitudeMCQAnswer(data, callback) {
  return dispatch => {
    axios
      .get(`/get_aptitude_user_answer/${data.id}`)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_APTITUDE_USER_ANSWER,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getAptitudeTestDetailInfo(data, callback) {
  return dispatch => {
    axios
      .post(`/get_aptitude_test_detail_info/${data.id}`)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_APTITUDE_TEST_DETAIL_INFO,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function AIMirrorRoomCategory(filter, callback) {
  return dispatch => {
    axios
      .post("/ai_mirror_room_category", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.AI_MIRRORROOM_CATEGORY, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
// axios.post("http://35.87.220.86:5000/video_filler_words",{videoData : {questionId: req.body.questionId, videoUrl: "./videos/RecordRTC-1687882292233-blob.mp4"}})
export function requestUserVideo(filter, callback) {
  return dispatch => {
    axios
      .post(`/request_user_video`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export function getCompaniesCategoryAndValues(filter, callback) {
  return dispatch => {
    axios
      .post("/get_companies_category_and_values", filter)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.COMPANIES_CATEGORY_VALUES,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getCompaniesByValues(filter, callback) {
  return dispatch => {
    axios
      .post(`/get_companies_by_values`, filter)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getUniversityPractice(filter, callback) {
  return dispatch => {
    axios
      .post("/get_university_practice", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_UNIVERSITY_PRACTICE, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getVisaPractice(filter, callback) {
  return dispatch => {
    axios
      .post("/get_visa_practice", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_VISA_PRACTICE, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getHomePractice(filter, callback) {
  return dispatch => {
    axios
      .post("/get_home_practice", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_HOME_PRACTICE, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export const updateCandidateDetails2 = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details2", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateDetails3 = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details3", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateDetails4 = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details4", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateDetails5 = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details5", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export const updateCandidateDetails6 = (data, callback) => {
  return dispatch => {
    axios
      .post("/candidate/update_details6", data)
      .then(({ data }) => {
        // console.log(data);
        // dispatch( getCandidateProfile() );
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};
export function getFlowChartDataGPTData(data, callback) {
  return dispatch => {
    axios
      .post(`/get_flow_chart_gpt_data`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getSignedData(filter, callback) {
  return dispatch => {
    axios
      .post("/get_signed_data", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_SIGNED_DATA, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getJobPosition(filter, callback) {
  return dispatch => {
    axios
      .post("/get_job_position", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_JOB_POSITION, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export const getJobPostionInfo = (data, cb) => async dispatch => {
  // console.log(data,"data");
  const res = await axios.get(`/get_position_info/${data.key}`);
  // console.log('RESOLVED');
  if (cb) {
    cb(null, res.data);
  }
  return dispatch({ type: CommonAction.GET_POSITION_INFO, payload: res.data });
};

export function getJobPositionRandom(filter, callback) {
  return dispatch => {
    axios
      .post("/get_job_position_random", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_JOB_POSITION_RANDOM, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function storeCookiesInfo(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/accept_cookies", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function checkIPAddress(data, callback) {
  // console.log(data,"ip address");
  return dispatch => {
    axios
      .post("/check_ip_address", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getCareer(filter, callback) {
  return dispatch => {
    axios
      .post("/get_career", filter)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CAREER, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getCareerInfo(data, callback) {
  // console.log(data,"data 3843");
  return dispatch => {
    axios
      .get(`/get_career_info/${data.pagetitle}`)
      .then(({ data }) => {
        dispatch({ type: CommonAction.GET_CAREER_INFO, payload: data });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getCurrenciesForPayment(data, callback) {
  // console.log(data,"data 3843");
  return dispatch => {
    axios
      .get(`/getCurrencies`)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}

export const updateUserPassword = (data, callback) => {
  return dispatch => {
    axios
      .post(`/account/update_password`, data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
};

export function reedemCouponCode(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/reedem_coupon", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function unLockPremiumCode(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/unlock-premium", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getClientPractice(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/get_client_practice", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function addSRCCCandidate(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/add_srcc_user", data)
      .then(({ data }) => {
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
export function getSRCCCandidateDetail(data, callback) {
  // console.log(data,"data");
  return dispatch => {
    axios
      .post("/get_srcc_candidate_detail", data)
      .then(({ data }) => {
        dispatch({
          type: CommonAction.GET_SRCC_CANDIDATE_DETAIL,
          payload: data
        });
        if (callback) {
          callback(null, data);
        }
      })
      .catch(function(error) {});
  };
}
