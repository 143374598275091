import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import CompanyLoader from "../../components/Loader/companyLoader";
// import AuthenticatedRoute from '../components/authenticated-route';
// import UnauthenticatedRoute from '../components/unauthenticated-route';

import Loadable from "react-loadable";
import NotFound from "./404";

import ScrollToTop from "./scrollToTop";
// import NotFound from './not-found';

var allRoutes = [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/index"),
      loading: () => null,
      modules: ["homepage"],
      webpack: () => [require.resolveWeak("./homepage")]
    })
  },
  // {
  //   path:'/candidate/companies_values',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/candidate/company_key_values'),
  //     loading: () => null,
  //     modules: ['company_key_values'],
  //     webpack: () => [require.resolveWeak('../../pages/candidate/company_key_values')]
  //   }),
  // },
  {
    path: "/career",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/career"),
      loading: () => null,
      modules: ["career"],
      webpack: () => [require.resolveWeak("../../pages/career")]
    })
  },
  {
    path: "/careers/:pagetitle",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/careers/"),
      loading: () => null,
      modules: ["career"],
      webpack: () => [require.resolveWeak("../../pages/careers/")]
    })
  },
  {
    path: "/job-positions",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/job-positions"),
      loading: () => null,
      modules: ["job-position"],
      webpack: () => [require.resolveWeak("../../pages/job-positions")]
    })
  },
  {
    path: "/job-position/:key",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/job-position/"),
      loading: () => null,
      modules: ["job-position/position_detail"],
      webpack: () => [require.resolveWeak("../../pages/job-position/")]
    })
  },
  {
    path: "/candidate/role_discovery/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/role_discovery/role_discovery_history"),
      loading: () => null,
      modules: ["candidate/role_discovery"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/role_discovery/role_discovery_history"
        )
      ]
    })
  },

  {
    path: "/candidate/role_discovery/detail_history/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/role_discovery/detail_history/role_discovery_history_details"),
      loading: () => null,
      modules: ["candidate/role_discovery/detail_history"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/role_discovery/detail_history/role_discovery_history_details"
        )
      ]
    })
  },

  {
    path: "/candidate/resume_analyze/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/resume_analyze/analysis/analysis"),
      loading: () => null,
      modules: ["candidate/resume_analyze"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/resume_analyze/analysis/analysis"
        )
      ]
    })
  },

  {
    path: "/candidate/aptitude_test/detail_history/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/aptitude_test/detail_history/aptitude_test_history_details"),
      loading: () => null,
      modules: ["candidate/aptitude_test/detail_history"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/aptitude_test/detail_history/aptitude_test_history_details"
        )
      ]
    })
  },

  {
    path: "/candidate/aptitude_test/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/aptitude_test/aptitude_test_history"),
      loading: () => null,
      modules: ["candidate/aptitude_test"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/aptitude_test/aptitude_test_history"
        )
      ]
    })
  },

  {
    path: "/candidate/resume_scorecard/:id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/candidate/resume_analyze/resume_analyze"),
      loading: () => null,
      modules: ["candidate/resume_analyze"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/candidate/resume_analyze/resume_analyze"
        )
      ]
    })
  },

  {
    path: "/interview/visa_preparation",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/visa_practice"),
      loading: () => null,
      modules: ["visa_practice"],
      webpack: () => [require.resolveWeak("../../pages/visa_practice")]
    })
  },

  {
    path: "/proficiency_test",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/proficiency_test"),
      loading: () => null,
      modules: ["proficiency_test"],
      webpack: () => [require.resolveWeak("../../pages/proficiency_test")]
    })
  },

  {
    path: "/debate_skills",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/debate_skills"),
      loading: () => null,
      modules: ["debate_skills"],
      webpack: () => [require.resolveWeak("../../pages/debate_skills")]
    })
  },

  {
    path: "/presentation_skills",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/presentation_skills"),
      loading: () => null,
      modules: ["presentation_skills"],
      webpack: () => [require.resolveWeak("../../pages/presentation_skills")]
    })
  },

  {
    path: "/meeting_skills",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/meeting_skills"),
      loading: () => null,
      modules: ["meeting_skills"],
      webpack: () => [require.resolveWeak("../../pages/meeting_skills")]
    })
  },

  {
    path: "/reading_skills",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "homepage" */ "../../pages/reading_skills"),
      loading: () => null,
      modules: ["reading_skills"],
      webpack: () => [require.resolveWeak("../../pages/reading_skills")]
    })
  },

  {
    path: "/success_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "success_payment" */ "../../pages/payment/success"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/payment/success")]
    })
  },
  {
    path: "/cancel_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "cancel_payment" */ "../../pages/payment/cancel"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/payment/cancel")]
    })
  },
  {
    path: "/pricing/success_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "success_payment" */ "../../pages/pricing/success"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/pricing/success")]
    })
  },
  {
    path: "/pricing/cancel_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "cancel_payment" */ "../../pages/pricing/cancel"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/pricing/cancel")]
    })
  },
  {
    path: "/course/pricing/success_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "success_payment" */ "../../pages/courses/payments/success"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/pricing/success")]
    })
  },
  {
    path: "/course/pricing/cancel_payment/:session_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "cancel_payment" */ "../../pages/courses/payments/cancel"),
      loading: () => null,
      modules: ["payment"],
      webpack: () => [require.resolveWeak("../../pages/pricing/cancel")]
    })
  },
  {
    path: "/about-us",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "about-" */ "../../pages/aboutus"),
      loading: () => null,
      modules: ["about"],
      webpack: () => [require.resolveWeak("../../pages/aboutus")]
    })
  },
  {
    path: "/terms",
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "terms" */ "../../pages/terms"),
      loading: () => null,
      modules: ["terms"],
      webpack: () => [require.resolveWeak("../../pages/terms")]
    })
  },
  {
    path: "/how-it-works",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "terms" */ "../../pages/how_it_works"),
      loading: () => null,
      modules: ["terms"],
      webpack: () => [require.resolveWeak("../../pages/how_it_works")]
    })
  },
  {
    path: "/privacy_policy",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy_policy" */ "../../pages/privacy_policy"),
      loading: () => null,
      modules: ["privacy_policy"],
      webpack: () => [require.resolveWeak("../../pages/privacy_policy")]
    })
  },
  {
    path: "/what_is_shadowing",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "what_is_shadowing" */ "../../pages/what_is_shadowing"),
      loading: () => null,
      modules: ["what_is_shadowing"],
      webpack: () => [require.resolveWeak("../../pages/what_is_shadowing")]
    })
  },
  {
    path: "/how_shadowing_works",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "how_shadowing_works" */ "../../pages/how_shadowing_works"),
      loading: () => null,
      modules: ["how_shadowing_works"],
      webpack: () => [require.resolveWeak("../../pages/how_shadowing_works")]
    })
  },
  {
    path: "/login",
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "login" */ "../../pages/login"),
      loading: () => null,
      modules: ["login"],
      webpack: () => [require.resolveWeak("../../pages/login")]
    })
  },
  {
    path: "/company-login",
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "login" */ "../../pages/login"),
      loading: () => null,
      modules: ["company-login"],
      webpack: () => [require.resolveWeak("../../pages/login")]
    })
  },
  {
    path: "/mentor-login",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "mentor-login" */ "../../pages/login/mentor"),
      loading: () => null,
      modules: ["mentor-login"],
      webpack: () => [require.resolveWeak("../../pages/login/mentor")]
    })
  },
  {
    path: "/signup",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "signup" */ "../../pages/signup"),
      loading: () => null,
      modules: ["signup"],
      webpack: () => [require.resolveWeak("../../pages/signup")]
    })
  },
  {
    path: "/company/view/:code",
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "company_info" */ "../../pages/company_info"),
      loading: () => null,
      modules: ["company_info"],
      webpack: () => [require.resolveWeak("../../pages/company_info")]
    })
  },
  {
    path: "/hiring/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiring_info" */ "../../pages/company_info/hiring_info"),
      loading: () => null,
      modules: ["company_info/hiring_info"],
      webpack: () => [
        require.resolveWeak("../../pages/company_info/hiring_info")
      ]
    })
  },
  {
    path: "/hiring/:code/test/:key",
    exact: false,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "test" */ "../../pages/test"),
      loading: () => null,
      modules: ["pages/test"],
      webpack: () => [require.resolveWeak("../../pages/test")]
    })
  },
  {
    path: "/candidate-verification/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "candidate-verification" */ "../../pages/verification/candidate"),
      loading: () => null,
      modules: ["verification/candidate"],
      webpack: () => [require.resolveWeak("../../pages/verification/candidate")]
    })
  },
  {
    path: "/coach-verification/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach-verification" */ "../../pages/verification/coach"),
      loading: () => null,
      modules: ["verification/coach"],
      webpack: () => [require.resolveWeak("../../pages/verification/coach")]
    })
  },
  {
    path: "/companies-verification/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "companies-verification" */ "../../pages/verification/company"),
      loading: () => null,
      modules: ["verification/company"],
      webpack: () => [require.resolveWeak("../../pages/verification/company")]
    })
  },
  {
    path: "/class_request/accept/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "class_request" */ "../../pages/verification/class_accept"),
      loading: () => null,
      modules: ["verification/class_accept"],
      webpack: () => [
        require.resolveWeak("../../pages/verification/class_accept")
      ]
    })
  },
  {
    path: "/class_request/reject/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "class_request" */ "../../pages/verification/class_reject"),
      loading: () => null,
      modules: ["verification/class_reject"],
      webpack: () => [
        require.resolveWeak("../../pages/verification/class_reject")
      ]
    })
  },
  {
    path: "/videocall/:roomid",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "videocall" */ "../../pages/videocall/Video"),
      loading: () => null,
      modules: ["pages/videocall/Video"],
      webpack: () => [require.resolveWeak("../../pages/videocall/Video")]
    })
  },
  {
    path: "/hiring/:hiring_key/videocall/:roomid",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hiringvideocall" */ "../../pages/videocall/hiring/Video"),
      loading: () => null,
      modules: ["pages/videocall/hiring/Video"],
      webpack: () => [require.resolveWeak("../../pages/videocall/hiring/Video")]
    })
  },
  {
    path: "/signup/company",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "signup/company" */ "../../pages/signup/company"),
      loading: () => null,
      modules: ["signup/company"],
      webpack: () => [require.resolveWeak("../../pages/signup/company")]
    })
  },
  {
    path: "/blogs",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/blogs"),
      loading: () => null,
      modules: ["blogs"],
      webpack: () => [require.resolveWeak("../../pages/blogs")]
    })
  },
  {
    path: "/client-practice",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/client-practice"),
      loading: () => null,
      modules: ["client-practice"],
      webpack: () => [require.resolveWeak("../../pages/client-practice")]
    })
  },
  {
    path: "/client-practice/practice/:room_id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/client-practice/mirror_room_info"),
      loading: () => null,
      modules: ["client-practice"],
      webpack: () => [
        require.resolveWeak("../../pages/client-practice/mirror_room_info")
      ]
    })
  },
  {
    path: "/blog/:id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/blogs/blog_info"),
      loading: () => null,
      modules: ["blog_info"],
      webpack: () => [require.resolveWeak("../../pages/blogs/blog_info")]
    })
  },
  {
    path: "/newsletter",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/newsletter"),
      loading: () => null,
      modules: ["newsletter"],
      webpack: () => [require.resolveWeak("../../pages/newsletter")]
    })
  },
  {
    path: "/newsletter/:id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/newsletter/blog_info"),
      loading: () => null,
      modules: ["blog_info"],
      webpack: () => [require.resolveWeak("../../pages/newsletter/blog_info")]
    })
  },
  {
    path: "/candidate/mentor/:key",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "candidate mentor" */ "../../pages/candidate/coach_info"),
      loading: () => null,
      modules: ["coach_info"],
      webpack: () => [require.resolveWeak("../../pages/candidate/coach_info")]
    })
  },
  // {
  //   path:'/test/:key',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "test" */ '../../pages/test/index'),
  //     loading: () => null,
  //     modules: ['test_info'],
  //     webpack: () => [require.resolveWeak('../../pages/test/index')]
  //   }),
  // },
  {
    path: "/company-signup/:code",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "company-signup" */ "../../pages/signup/manager"),
      loading: () => null,
      modules: ["signup"],
      webpack: () => [require.resolveWeak("../../pages/signup/manager")]
    })
  },
  {
    path: "/candidate-signup/:code",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/signup/candidate-password"),
      loading: () => null,
      modules: ["signup"],
      webpack: () => [
        require.resolveWeak("../../pages/signup/candidate-password")
      ]
    })
  },
  // {
  //   path:'/companies',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "companies" */  '../../pages/candidate/companies'),
  //     loading: () => null,
  //     modules: ['companies'],
  //     webpack: () => [require.resolveWeak('../../pages/candidate/companies')]
  //   }),
  // },
  {
    path: "/universities",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "companies" */ "../../pages/universities/"),
      loading: () => null,
      modules: ["universities"],
      webpack: () => [require.resolveWeak("../../pages/universities/")]
    })
  },
  // {
  //   path:'/mentors',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/candidate/more_coaches'),
  //     loading: () => null,
  //     modules: ['more_coaches'],
  //     webpack: () => [require.resolveWeak('../../pages/candidate/more_coaches')]
  //   }),
  // },
  {
    path: "/for_mentors",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_mentors" */ "../../pages/for_mentors"),
      loading: () => null,
      modules: ["for_mentors"],
      webpack: () => [require.resolveWeak("../../pages/for_mentors")]
    })
  },
  {
    path: "/for_companies",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_companies" */ "../../pages/for_companies"),
      loading: () => null,
      modules: ["for_companies"],
      webpack: () => [require.resolveWeak("../../pages/for_companies")]
    })
  },
  {
    path: "/hirings",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "hirings" */ "../../pages/hirings"),
      loading: () => null,
      modules: ["companies"],
      webpack: () => [require.resolveWeak("../../pages/hirings")]
    })
  },
  {
    path: "/forgot-password",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/login/forgot-password"),
      loading: () => null,
      modules: ["account"],
      webpack: () => [require.resolveWeak("../../pages/login/forgot-password")]
    })
  },
  {
    path: "/reset-password/:type/:code",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/login/reset-password"),
      loading: () => null,
      modules: ["account"],
      webpack: () => [require.resolveWeak("../../pages/login/reset-password")]
    })
  },
  {
    path: "/interview-discussion/:code",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "event_info" */ "../../pages/event"),
      loading: () => null,
      modules: ["event_info"],
      webpack: () => [require.resolveWeak("../../pages/event")]
    })
  },
  // {
  //   path:'/video-room/:roomid',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "video-room" */ '../../pages/video_room/Video'),
  //     loading: () => null,
  //     modules: ['pages/video_room/Video'],
  //     webpack: () => [require.resolveWeak('../../pages/video_room/Video')]
  //   }),
  // },
  {
    path: "/one-to-one-practice-rooms",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/coach_rooms"),
      loading: () => null,
      modules: ["coach_rooms"],
      webpack: () => [require.resolveWeak("../../pages/coach_rooms")]
    })
  },
  {
    path: "/one-to-one-practice-room/:roomid",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "one-to-one-practice" */ "../../pages/coach_rooms/room_video"),
      loading: () => null,
      modules: ["pages/coach_rooms/room_video"],
      webpack: () => [require.resolveWeak("../../pages/coach_rooms/room_video")]
    })
  },
  // {
  //   path:'/one-to-one-practice-room/:roomid/:dateid',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "videocall" */ '../../pages/coach_rooms/room_video'),
  //     loading: () => null,
  //     modules: ['pages/coach_rooms/room_video'],
  //     webpack: () => [require.resolveWeak('../../pages/coach_rooms/room_video')]
  //   }),
  // },
  // {
  //   path:'/live-rooms',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/live_rooms'),
  //     loading: () => null,
  //     modules: ['live_rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/live_rooms')]
  //   }),
  // },
  // {
  //   path:'/live-room/:roomid',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/live_rooms/room_video'),
  //     loading: () => null,
  //     modules: ['live_room_video'],
  //     webpack: () => [require.resolveWeak('../../pages/live_rooms/room_video')]
  //   }),
  // },
  {
    path: "/update-details",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/update/candidate"),
      loading: () => null,
      modules: ["update-details"],
      webpack: () => [require.resolveWeak("../../pages/update/candidate")]
    })
  },
  {
    path: "/update-password",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/update/changePassword"),
      loading: () => null,
      modules: ["changePassword"],
      webpack: () => [require.resolveWeak("../../pages/update/changePassword")]
    })
  },
  // {
  //   path:'/mirror-rooms',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms'),
  //     loading: () => null,
  //     modules: ['mirror-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms')]
  //   }),
  // },
  // {
  //   path:'/hiring/:code/mirror-rooms',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "hiring mirror-rooms" */ '../../pages/mirror-rooms'),
  //     loading: () => null,
  //     modules: ['pages/mirror-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms')]
  //   }),
  // },
  // {
  //   path:'/mirror-rooms/instruction/:room_key',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/room-instruction'),
  //     loading: () => null,
  //     modules: ['mirror-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/room-instruction')]
  //   }),
  // },
  {
    path: "/client-practice/mirror-room/:room_key/:candidate_id",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/client-practice/room-video"),
      loading: () => null,
      modules: ["mirror-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/client-practice/room-video")
      ]
    })
  },
  {
    path: "/mirror-room/:room_key",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/mirror-rooms/room-video"),
      loading: () => null,
      modules: ["mirror-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/mirror-rooms/room-video")
      ]
    })
  },
  {
    path: "/contact-us",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/contactus"),
      loading: () => null,
      modules: ["contactus"],
      webpack: () => [require.resolveWeak("../../pages/contactus")]
    })
  },

  {
    path: "/interview/question_bank",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/room_questions"),
      loading: () => null,
      modules: ["room_questions"],
      webpack: () => [require.resolveWeak("../../pages/room_questions")]
    })
  },
  {
    path: "/mirror-room-question/:id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/room_questions/info"),
      loading: () => null,
      modules: ["blog_info"],
      webpack: () => [require.resolveWeak("../../pages/room_questions/info")]
    })
  },
  // {
  //   path:'/pre-defined-ai-mirror-rooms',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/predefined_rooms'),
  //     loading: () => null,
  //     modules: ['pre-defined-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/predefined_rooms')]
  //   }),
  // },
  {
    path: "/interview/university_admission",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "pre-defined-uni-mirror-rooms" */ "../../pages/mirror-rooms/predefined_uni_rooms"),
      loading: () => null,
      modules: ["pre-defined-uni-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/mirror-rooms/predefined_uni_rooms")
      ]
    })
  },
  {
    path: "/interview/university_admission/:id",
    // exact:true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "pre-defined-uni-mirror-room-info" */ "../../pages/mirror-rooms/predefined_uni_room_info"),
      loading: () => null,
      modules: ["pre-defined-uni-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/mirror-rooms/predefined_uni_room_info")
      ]
    })
  },
  {
    path: "/university-admission-mirror-room/:key",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "pre-defined-uni-mirror-rooms" */ "../../pages/mirror-rooms/university-courses"),
      loading: () => null,
      modules: ["pre-defined-uni-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/mirror-rooms/university-courses")
      ]
    })
  },
  // {
  //   path:'/candidate/create_resume',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "hiring_info" */ '../../pages/candidate/create_resume'),
  //     loading: () => null,
  //     modules: ['candidate/resume_analyze'],
  //     webpack: () => [require.resolveWeak('../../pages/candidate/create_resume')]
  //   }),
  // },
  {
    path: "/developer-access",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/api"),
      loading: () => null,
      modules: ["api"],
      webpack: () => [require.resolveWeak("../../pages/api")]
    })
  },
  {
    path: "/candidate/feedback",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/candidate/feedback"),
      loading: () => null,
      modules: ["candidate/feedback"],
      webpack: () => [require.resolveWeak("../../pages/candidate/feedback")]
    })
  },
  // {
  //   path:'/candidate/flowchart/flow-chart',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import( '../../pages/candidate/flowchart/flow-chart'),
  //     loading: () => null,
  //     modules: ['flow-chart'],
  //     webpack: () => [require.resolveWeak('../../pages/candidate/flowchart/flow-chart')]
  //   }),
  // },
  // {
  //   path:'/flowchart/flow-chart',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "pre-defined-uni-mirror-rooms" */ '../../pages/candidate/flowchart/flow-chart'),
  //     loading: () => null,
  //     modules: ['pre-defined-uni-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/flowchart/flow-chart')]
  //   }),
  // },
  {
    path: "/college_admissions",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_university_admission" */ "../../pages/for_university_admission"),
      loading: () => null,
      modules: ["for_university_admission"],
      webpack: () => [
        require.resolveWeak("../../pages/for_university_admission")
      ]
    })
  },
  {
    path: "/role_discovery_info",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_university_admission" */ "../../pages/role_discovery_info"),
      loading: () => null,
      modules: ["role_discovery_info"],
      webpack: () => [require.resolveWeak("../../pages/role_discovery_info")]
    })
  },
  {
    path: "/resume_enhancer_info",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_university_admission" */ "../../pages/resume_enhancer_info"),
      loading: () => null,
      modules: ["resume_enhancer_info"],
      webpack: () => [require.resolveWeak("../../pages/resume_enhancer_info")]
    })
  },
  {
    path: "/recruiter_chatbot_info",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_university_admission" */ "../../pages/recruiter_chatbot_info"),
      loading: () => null,
      modules: ["recruiter_chatbot_info"],
      webpack: () => [require.resolveWeak("../../pages/recruiter_chatbot_info")]
    })
  },
  {
    path: "/questions_bank_info",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "for_university_admission" */ "../../pages/questions_bank_info"),
      loading: () => null,
      modules: ["questions_bank_info"],
      webpack: () => [require.resolveWeak("../../pages/questions_bank_info")]
    })
  },
  {
    path: "/pricing",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/pricing"),
      loading: () => null,
      modules: ["pricing"],
      webpack: () => [require.resolveWeak("../../pages/pricing")]
    })
  },
  // {
  //   path:'/mirror-room-info/:room_key',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/mirror-room-company-info'),
  //     loading: () => null,
  //     modules: ['mirror-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/mirror-room-company-info')]
  //   }),
  // },
  {
    path: "/faqs/topic/:pagetitle",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/faqs/faq_topic"),
      loading: () => null,
      modules: ["faqs/faq_topic"],
      webpack: () => [require.resolveWeak("../../pages/faqs/faq_topic")]
    })
  },
  {
    path: "/faqs/question/:pagetitle",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/faqs/components/faq_answer"),
      loading: () => null,
      modules: ["faqs/components/faq_answer"],
      webpack: () => [
        require.resolveWeak("../../pages/faqs/components/faq_answer")
      ]
    })
  },
  {
    path: "/faqs",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/faqs"),
      loading: () => null,
      modules: ["faqs"],
      webpack: () => [require.resolveWeak("../../pages/faqs")]
    })
  },
  // {
  //   path:'/mirror-room-position-info/:room_key',
  //   exact:false,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/mirror-room-position-info'),
  //     loading: () => null,
  //     modules: ['mirror-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/mirror-room-position-info')]
  //   }),
  // },
  // {
  //   path:'/mirror-room-company',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/mirror-room-company'),
  //     loading: () => null,
  //     modules: ['search-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/mirror-room-company')]
  //   }),
  // },
  // {
  //   path:'/mirror-room-position',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import( '../../pages/mirror-rooms/mirror-room-position'),
  //     loading: () => null,
  //     modules: ['search-rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/mirror-room-position')]
  //   }),
  // },
  {
    path: "/interview-practice",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/interview-practice"),
      loading: () => null,
      modules: ["interview-practice"],
      webpack: () => [require.resolveWeak("../../pages/interview-practice")]
    })
  },
  {
    path: "/mock-interview",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/mock-interview"),
      loading: () => null,
      modules: ["mock-interview"],
      webpack: () => [require.resolveWeak("../../pages/mock-interview")]
    })
  },
  {
    path: "/interview-preparations",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/interview-preparations"),
      loading: () => null,
      modules: ["interview-preparations"],
      webpack: () => [require.resolveWeak("../../pages/interview-preparations")]
    })
  },
  {
    path: "/campus-ambassadors",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/campus_ambassadors"),
      loading: () => null,
      modules: ["campus_ambassadors"],
      webpack: () => [require.resolveWeak("../../pages/campus_ambassadors")]
    })
  },
  {
    path: "/tournaments",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/tournaments"),
      loading: () => null,
      modules: ["tournaments"],
      webpack: () => [require.resolveWeak("../../pages/tournaments")]
    })
  },
  {
    path: "/tournament/:pagetitle",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/tournaments/info"),
      loading: () => null,
      modules: ["tournament_info"],
      webpack: () => [require.resolveWeak("../../pages/tournaments/info")]
    })
  },
  {
    path: "/tournament-leaderboard",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/tournaments/leaderboard"),
      loading: () => null,
      modules: ["tournament_leaderboard"],
      webpack: () => [
        require.resolveWeak("../../pages/tournaments/leaderboard")
      ]
    })
  },
  {
    path: "/for_students",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/for_students"),
      loading: () => null,
      modules: ["tournament_leaderboard"],
      webpack: () => [require.resolveWeak("../../pages/for_students")]
    })
  },
  {
    path: "/culture-assessment",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/mirror-rooms/cultural-assessment"),
      loading: () => null,
      modules: ["culture_practice"],
      webpack: () => [
        require.resolveWeak("../../pages/mirror-rooms/cultural-assessment")
      ]
    })
  },

  {
    path: "/interview",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice"),
      loading: () => null,
      modules: ["interview_practice"],
      webpack: () => [require.resolveWeak("../../pages/practice")]
    })
  },
  {
    path: "/interview/:pagetitle",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice/info"),
      loading: () => null,
      modules: ["interview_practice/info"],
      webpack: () => [require.resolveWeak("../../pages/practice/info")]
    })
  },
  {
    path: "/interview/:pagetitle/:category1",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice/info"),
      loading: () => null,
      modules: ["interview_practice/info"],
      webpack: () => [require.resolveWeak("../../pages/practice/info")]
    })
  },
  {
    path: "/interview/:pagetitle/:category1/:category2",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice/info"),
      loading: () => null,
      modules: ["interview_practice/info"],
      webpack: () => [require.resolveWeak("../../pages/practice/info")]
    })
  },
  {
    path: "/interview/:pagetitle/:category1/:category2/:category3",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice/info"),
      loading: () => null,
      modules: ["interview_practice/info"],
      webpack: () => [require.resolveWeak("../../pages/practice/info")]
    })
  },
  {
    path: "/school",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/elementry_school/index"),
      loading: () => null,
      modules: ["school_practice/index"],
      webpack: () => [require.resolveWeak("../../pages/elementry_school/index")]
    })
  },
  {
    path: "/school/:country_id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/elementry_school/index"),
      loading: () => null,
      modules: ["school_practice/index"],
      webpack: () => [require.resolveWeak("../../pages/elementry_school/index")]
    })
  },
  {
    path: "/school/:country_id/:grade_id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/elementry_school/index"),
      loading: () => null,
      modules: ["school_practice/index"],
      webpack: () => [require.resolveWeak("../../pages/elementry_school/index")]
    })
  },
  {
    path: "/school/:country_id/:grade_id/:subject_id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/elementry_school/index"),
      loading: () => null,
      modules: ["school_practice/index"],
      webpack: () => [require.resolveWeak("../../pages/elementry_school/index")]
    })
  },
  {
    path: "/school/:country_id/:grade_id/:subject_id/:topic_id",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/elementry_school/index"),
      loading: () => null,
      modules: ["school_practice/index"],
      webpack: () => [require.resolveWeak("../../pages/elementry_school/index")]
    })
  },
  // {
  //   path:'/courses',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses'),
  //     loading: () => null,
  //     modules: ['courses'],
  //     webpack: () => [require.resolveWeak('../../pages/courses')]
  //   }),
  // },
  // {
  //   path:'/courses/all',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses/course_all'),
  //     loading: () => null,
  //     modules: ['course_all'],
  //     webpack: () => [require.resolveWeak('../../pages/courses/course_all')]
  //   }),
  // },
  // {
  //   path:'/bundles/all',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses/bundle/'),
  //     loading: () => null,
  //     modules: ['course_all'],
  //     webpack: () => [require.resolveWeak('../../pages/courses/bundle/')]
  //   }),
  // },
  // {
  //   path:'/course/bundle/:pagetitle',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses/bundle/info'),
  //     loading: () => null,
  //     modules: ['courses/bundle_info'],
  //     webpack: () => [require.resolveWeak('../../pages/courses/bundle/info')]
  //   }),
  // },
  // {
  //   path:'/course/:key',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses/info'),
  //     loading: () => null,
  //     modules: ['courses/info'],
  //     webpack: () => [require.resolveWeak('../../pages/courses/info')]
  //   }),
  // },
  // {
  //   path:'/course/:key/:itemkey',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/courses/item_info'),
  //     loading: () => null,
  //     modules: ['courses/item_info'],
  //     webpack: () => [require.resolveWeak('../../pages/courses/item_info')]
  //   }),
  // },
  {
    path: "/practice",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/free-practice"),
      loading: () => null,
      modules: ["free/practice"],
      webpack: () => [require.resolveWeak("../../pages/free-practice")]
    })
  },
  {
    path: "/practice/:pagetitle",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/free-practice/room-video"),
      loading: () => null,
      modules: ["free/practice_info"],
      webpack: () => [
        require.resolveWeak("../../pages/free-practice/room-video")
      ]
    })
  },
  {
    path: "/enterprise",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise"),
      loading: () => null,
      modules: ["enterprise"],
      webpack: () => [require.resolveWeak("../../pages/enterprise")]
    })
  },
  {
    path: "/dei",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/dei"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [require.resolveWeak("../../pages/enterprise/dei")]
    })
  },
  {
    path: "/sales",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/sales"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [require.resolveWeak("../../pages/enterprise/sales")]
    })
  },
  {
    path: "/negotiation",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/negotiation"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [require.resolveWeak("../../pages/enterprise/negotiation")]
    })
  },
  {
    path: "/women-leader-ship",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/women-leader-ship"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [
        require.resolveWeak("../../pages/enterprise/women-leader-ship")
      ]
    })
  },
  {
    path: "/reinforce-learning",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/reinforce-learning"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [
        require.resolveWeak("../../pages/enterprise/reinforce-learning")
      ]
    })
  },
  {
    path: "/culture-values",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/culture-values"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [
        require.resolveWeak("../../pages/enterprise/culture-values")
      ]
    })
  },
  {
    path: "/cross-cultural-norms",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/cross-cultural-norms"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [
        require.resolveWeak("../../pages/enterprise/cross-cultural-norms")
      ]
    })
  },
  {
    path: "/compliance",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/enterprise/compliance"),
      loading: () => null,
      modules: ["dei"],
      webpack: () => [require.resolveWeak("../../pages/enterprise/compliance")]
    })
  },
  {
    path: "/mentor/training/:pagetitle",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/coach/trainings/training_form"),
      loading: () => null,
      modules: ["training_form"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/trainings/training_form")
      ]
    })
  },
  {
    path: "/question-practice",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/room_questions/custom_questions"),
      loading: () => null,
      modules: ["training_form"],
      webpack: () => [
        require.resolveWeak("../../pages/room_questions/custom_questions")
      ]
    })
  },
  {
    path: "/demo-analytics",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/demo/mirror_room_info"),
      loading: () => null,
      modules: ["demo_analytics"],
      webpack: () => [require.resolveWeak("../../pages/demo/mirror_room_info")]
    })
  },
  {
    path: "/unlock-premium/:code",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/verification/premium_analytics"),
      loading: () => null,
      modules: ["demo_analytics"],
      webpack: () => [
        require.resolveWeak("../../pages/verification/premium_analytics")
      ]
    })
  }
];

// var domainRoutes = [
//   {
//     path: "/",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "company_page" */ "../../pages/company_page"),
//       loading: () => null,
//       modules: ["company_page"],
//       webpack: () => [require.resolveWeak("../../pages/company_page")]
//     })
//   },
//   {
//     path: "/how-it-works",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "terms" */ "../../pages/how_it_works"),
//       loading: () => null,
//       modules: ["terms"],
//       webpack: () => [require.resolveWeak("../../pages/how_it_works")]
//     })
//   },

//   {
//     path: "/about-us",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "about-" */ "../../pages/aboutus"),
//       loading: () => null,
//       modules: ["about"],
//       webpack: () => [require.resolveWeak("../../pages/aboutus")]
//     })
//   },
//   {
//     path: "/terms",
//     exact: true,
//     component: Loadable({
//       loader: () => import(/* webpackChunkName: "terms" */ "../../pages/terms"),
//       loading: () => null,
//       modules: ["terms"],
//       webpack: () => [require.resolveWeak("../../pages/terms")]
//     })
//   },
//   {
//     path: "/privacy_policy",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "privacy_policy" */ "../../pages/privacy_policy"),
//       loading: () => null,
//       modules: ["privacy_policy"],
//       webpack: () => [require.resolveWeak("../../pages/privacy_policy")]
//     })
//   },
//   {
//     path: "/what_is_shadowing",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "what_is_shadowing" */ "../../pages/what_is_shadowing"),
//       loading: () => null,
//       modules: ["what_is_shadowing"],
//       webpack: () => [require.resolveWeak("../../pages/what_is_shadowing")]
//     })
//   },
//   {
//     path: "/how_shadowing_works",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "how_shadowing_works" */ "../../pages/how_shadowing_works"),
//       loading: () => null,
//       modules: ["how_shadowing_works"],
//       webpack: () => [require.resolveWeak("../../pages/how_shadowing_works")]
//     })
//   },
//   {
//     path: "/company-login",
//     exact: true,
//     component: Loadable({
//       loader: () => import(/* webpackChunkName: "login" */ "../../pages/login"),
//       loading: () => null,
//       modules: ["company-login"],
//       webpack: () => [require.resolveWeak("../../pages/login")]
//     })
//   },
//   {
//     path: "/signup",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "signup" */ "../../pages/signup"),
//       loading: () => null,
//       modules: ["signup"],
//       webpack: () => [require.resolveWeak("../../pages/signup")]
//     })
//   },
//   {
//     path: "/company/view/:code",
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "company_info" */ "../../pages/company_info"),
//       loading: () => null,
//       modules: ["company_info"],
//       webpack: () => [require.resolveWeak("../../pages/company_info")]
//     })
//   },
//   {
//     path: "/hiring/:code",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "hiring_info" */ "../../pages/company_info/hiring_info"),
//       loading: () => null,
//       modules: ["company_info/hiring_info"],
//       webpack: () => [
//         require.resolveWeak("../../pages/company_info/hiring_info")
//       ]
//     })
//   },
//   ,
//   {
//     path: "/companies-verification/:code",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "companies-verification" */ "../../pages/verification/company"),
//       loading: () => null,
//       modules: ["verification/company"],
//       webpack: () => [require.resolveWeak("../../pages/verification/company")]
//     })
//   },
//   {
//     path: "/signup/company",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "signup/company" */ "../../pages/signup/company"),
//       loading: () => null,
//       modules: ["signup/company"],
//       webpack: () => [require.resolveWeak("../../pages/signup/company")]
//     })
//   },
//   {
//     path: "/blogs",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/blogs"),
//       loading: () => null,
//       modules: ["blogs"],
//       webpack: () => [require.resolveWeak("../../pages/blogs")]
//     })
//   },
//   {
//     path: "/blog/:id",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/blogs/blog_info"),
//       loading: () => null,
//       modules: ["blog_info"],
//       webpack: () => [require.resolveWeak("../../pages/blogs/blog_info")]
//     })
//   },
//   {
//     path: "/newsletter",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/newsletter"),
//       loading: () => null,
//       modules: ["newsletter"],
//       webpack: () => [require.resolveWeak("../../pages/newsletter")]
//     })
//   },
//   {
//     path: "/newsletter/:id",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/newsletter/blog_info"),
//       loading: () => null,
//       modules: ["blog_info"],
//       webpack: () => [require.resolveWeak("../../pages/newsletter/blog_info")]
//     })
//   },

//   {
//     path: "/company-signup/:code",
//     exact: false,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "company-signup" */ "../../pages/signup/manager"),
//       loading: () => null,
//       modules: ["signup"],
//       webpack: () => [require.resolveWeak("../../pages/signup/manager")]
//     })
//   },

//   {
//     path: "/universities",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "companies" */ "../../pages/universities/"),
//       loading: () => null,
//       modules: ["universities"],
//       webpack: () => [require.resolveWeak("../../pages/universities/")]
//     })
//   },

//   {
//     path: "/for_mentors",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "for_mentors" */ "../../pages/for_mentors"),
//       loading: () => null,
//       modules: ["for_mentors"],
//       webpack: () => [require.resolveWeak("../../pages/for_mentors")]
//     })
//   },
//   {
//     path: "/for_companies",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "for_companies" */ "../../pages/for_companies"),
//       loading: () => null,
//       modules: ["for_companies"],
//       webpack: () => [require.resolveWeak("../../pages/for_companies")]
//     })
//   },
//   {
//     path: "/hirings",
//     exact: false,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "hirings" */ "../../pages/hirings"),
//       loading: () => null,
//       modules: ["companies"],
//       webpack: () => [require.resolveWeak("../../pages/hirings")]
//     })
//   },
//   {
//     path: "/forgot-password",
//     exact: false,
//     component: Loadable({
//       loader: () => import("../../pages/login/forgot-password"),
//       loading: () => null,
//       modules: ["account"],
//       webpack: () => [require.resolveWeak("../../pages/login/forgot-password")]
//     })
//   },
//   {
//     path: "/reset-password/:type/:code",
//     exact: false,
//     component: Loadable({
//       loader: () => import("../../pages/login/reset-password"),
//       loading: () => null,
//       modules: ["account"],
//       webpack: () => [require.resolveWeak("../../pages/login/reset-password")]
//     })
//   },
//   // {
//   //   path:'/mirror-room/:room_key',
//   //   exact:false,
//   //   component :Loadable({
//   //     loader: () => import( '../../pages/mirror-rooms/room-video'),
//   //     loading: () => null,
//   //     modules: ['mirror-rooms'],
//   //     webpack: () => [require.resolveWeak('../../pages/mirror-rooms/room-video')]
//   //   }),
//   // },
//   {
//     path: "/contact-us",
//     exact: false,
//     component: Loadable({
//       loader: () => import("../../pages/contactus"),
//       loading: () => null,
//       modules: ["contactus"],
//       webpack: () => [require.resolveWeak("../../pages/contactus")]
//     })
//   },

//   {
//     path: "/mirror-room-questions",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/room_questions"),
//       loading: () => null,
//       modules: ["room_questions"],
//       webpack: () => [require.resolveWeak("../../pages/room_questions")]
//     })
//   },
//   {
//     path: "/mirror-room-question/:id",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/room_questions/info"),
//       loading: () => null,
//       modules: ["blog_info"],
//       webpack: () => [require.resolveWeak("../../pages/room_questions/info")]
//     })
//   },

//   {
//     path: "/pricing",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/pricing"),
//       loading: () => null,
//       modules: ["pricing"],
//       webpack: () => [require.resolveWeak("../../pages/pricing")]
//     })
//   },

//   {
//     path: "/interview-practice",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/interview-practice"),
//       loading: () => null,
//       modules: ["interview-practice"],
//       webpack: () => [require.resolveWeak("../../pages/interview-practice")]
//     })
//   },
//   {
//     path: "/mock-interview",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/mock-interview"),
//       loading: () => null,
//       modules: ["mock-interview"],
//       webpack: () => [require.resolveWeak("../../pages/mock-interview")]
//     })
//   },
//   {
//     path: "/interview-preparations",
//     exact: true,
//     component: Loadable({
//       loader: () => import("../../pages/interview-preparations"),
//       loading: () => null,
//       modules: ["interview-preparations"],
//       webpack: () => [require.resolveWeak("../../pages/interview-preparations")]
//     })
//   },
//   {
//     path: "/for_students",
//     exact: true,
//     component: Loadable({
//       loader: () =>
//         import(/* webpackChunkName: "campus_ambassadors" */ "../../pages/for_students"),
//       loading: () => null,
//       modules: ["tournament_leaderboard"],
//       webpack: () => [require.resolveWeak("../../pages/for_students")]
//     })
//   }
// ];

var coachRoutes = [
  {
    path: "/mentor/dashboard",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_dashboard" */ "../../pages/coach/dashboard"),
      loading: () => null,
      modules: ["coach/dashboard"],
      webpack: () => [require.resolveWeak("../../pages/coach/dashboard")]
    })
  },
  {
    path: "/mentor/connections",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "invitations" */ "../../pages/coach/invitations"),
      loading: () => null,
      modules: ["coach/invitations"],
      webpack: () => [require.resolveWeak("../../pages/coach/invitations")]
    })
  },
  {
    path: "/mentor/candidates",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/members"),
      loading: () => null,
      modules: ["coach/members"],
      webpack: () => [require.resolveWeak("../../pages/coach/members")]
    })
  },
  {
    path: "/mentor/candidate/:id",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "member_info" */ "../../pages/coach/member_info"),
      loading: () => null,
      modules: ["coach/member_info"],
      webpack: () => [require.resolveWeak("../../pages/coach/member_info")]
    })
  },
  {
    path: "/mentor/candidate_info/:key",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "candidate_info" */ "../../pages/coach/candidates/candidate_info"),
      loading: () => null,
      modules: ["coach/member_info"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/candidates/candidate_info")
      ]
    })
  },
  {
    path: "/mentor/member/:id/task-form",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "member_info_task_form" */ "../../pages/coach/member_info/task_form"),
      loading: () => null,
      modules: ["member_info/task_form"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/member_info/task_form")
      ]
    })
  },
  {
    path: "/mentor/cohorts",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_classes" */ "../../pages/coach/classes"),
      loading: () => null,
      modules: ["coach/classes"],
      webpack: () => [require.resolveWeak("../../pages/coach/classes")]
    })
  },
  {
    path: "/mentor/class/:id",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/coach/class_info"),
      loading: () => null,
      modules: ["coach/class_info"],
      webpack: () => [require.resolveWeak("../../pages/coach/class_info")]
    })
  },
  {
    path: "/mentor/profile",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "mentorprofile" */ "../../pages/coach/profile"),
      loading: () => null,
      modules: ["coach/profile"],
      webpack: () => [require.resolveWeak("../../pages/coach/profile")]
    })
  },
  {
    path: "/mentor/search/candidates",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_candidates" */ "../../pages/coach/candidates"),
      loading: () => null,
      modules: ["coach/candidates"],
      webpack: () => [require.resolveWeak("../../pages/coach/candidates")]
    })
  },
  {
    path: "/mentor/hirings",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/coach/hirings"),
      loading: () => null,
      modules: ["coach/hirings"],
      webpack: () => [require.resolveWeak("../../pages/coach/hirings")]
    })
  },
  {
    path: "/mentor/feedback/:feedback_id",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_feedback" */ "../../pages/coach/class_info/feedback_info"),
      loading: () => null,
      modules: ["coach/feedback"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/class_info/feedback_info")
      ]
    })
  },
  {
    path: "/mentor/create-test",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_test" */ "../../pages/coach/test/create-new"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/coach/test/create-new")]
    })
  },
  // {
  //   path:'/mentor/create-test/:key',
  //   component :Loadable({
  //     loader: () => import( '../../pages/coach/test/test_form'),
  //     loading: () => null,
  //     modules: ['coach/test'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/test/test_form')]
  //   }),
  // },
  {
    path: "/mentor/tests",
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_tests" */ "../../pages/coach/tests"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/coach/tests")]
    })
  },
  {
    path: "/mentor/test/:key/edit",
    component: Loadable({
      loader: () => import("../../pages/coach/test/test_edit"),
      loading: () => null,
      modules: ["coach/test_edit"],
      webpack: () => [require.resolveWeak("../../pages/coach/test/test_edit")]
    })
  },
  {
    path: "/mentor/test/:key",
    component: Loadable({
      loader: () => import("../../pages/coach/test/test_info"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/coach/test/test_info")]
    })
  },
  {
    path: "/mentor/prejoin/candidates",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coachprejoin" */ "../../pages/coach/prejoining/candidates"),
      loading: () => null,
      modules: ["company/coach/candidates"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/prejoining/candidates")
      ]
    })
  },
  {
    path: "/mentor/prejoin/class/:id",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/coach/prejoining/class_info"),
      loading: () => null,
      modules: ["coach/prejoining/class_info"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/prejoining/class_info")
      ]
    })
  },
  {
    path: "/mentor/prejoin/documents",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/coach/prejoining/documents"),
      loading: () => null,
      modules: ["coach/prejoining/documents"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/prejoining/documents")
      ]
    })
  },
  {
    path: "/mentor/feedback",
    component: Loadable({
      loader: () => import("../../pages/coach/feedbacks"),
      loading: () => null,
      modules: ["coach/feedbacks"],
      webpack: () => [require.resolveWeak("../../pages/coach/feedbacks")]
    })
  },
  {
    path: "/mentor/meetings",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/meetings"),
      loading: () => null,
      modules: ["coach/meetings"],
      webpack: () => [require.resolveWeak("../../pages/coach/meetings")]
    })
  },
  {
    path: "/mentor/notes",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/notes"),
      loading: () => null,
      modules: ["coach/notes"],
      webpack: () => [require.resolveWeak("../../pages/coach/notes")]
    })
  },
  {
    path: "/mentor/rooms",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/rooms"),
      loading: () => null,
      modules: ["coach/rooms"],
      webpack: () => [require.resolveWeak("../../pages/coach/rooms")]
    })
  },
  {
    path: "/mentor/messages",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/messages"),
      loading: () => null,
      modules: ["coach/messages"],
      webpack: () => [require.resolveWeak("../../pages/coach/messages")]
    })
  },
  {
    path: "/mentor/managers",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/managers"),
      loading: () => null,
      modules: ["coach/managers"],
      webpack: () => [require.resolveWeak("../../pages/coach/managers")]
    })
  },
  {
    path: "/mentor/staffs",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/managers"),
      loading: () => null,
      modules: ["coach/managers"],
      webpack: () => [require.resolveWeak("../../pages/coach/managers")]
    })
  },
  {
    path: "/mentor/departments",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "notes" */ "../../pages/coach/departments"),
      loading: () => null,
      modules: ["coach/departments"],
      webpack: () => [require.resolveWeak("../../pages/coach/departments")]
    })
  },
  {
    path: "/mentor/students",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/students"),
      loading: () => null,
      modules: ["coach/students"],
      webpack: () => [require.resolveWeak("../../pages/coach/students")]
    })
  },
  {
    path: "/mentor/job_board",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/job_board"),
      loading: () => null,
      modules: ["coach/job_board"],
      webpack: () => [require.resolveWeak("../../pages/coach/job_board")]
    })
  },
  {
    path: "/mentor/university/tests",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/university_tests"),
      loading: () => null,
      modules: ["coach/university_tests"],
      webpack: () => [require.resolveWeak("../../pages/coach/university_tests")]
    })
  },
  // {
  //   path:'/mentor/mirror-rooms',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "members" */ '../../pages/coach/university_rooms'),
  //     loading: () => null,
  //     modules: ['coach/university_rooms'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/university_rooms')]
  //   }),
  // },
  {
    path: "/mentor/mirror-rooms",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/mirror-rooms/index"),
      loading: () => null,
      modules: ["coach/mirror_rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/mirror-rooms/index")
      ]
    })
  },
  {
    path: "/mentor/create-mirror-room",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/mirror-rooms/form"),
      loading: () => null,
      modules: ["coach/mirror-form"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/mirror-rooms/form")
      ]
    })
  },
  {
    path: "/mentor/room/:key",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/coach/mirror-rooms/info"),
      loading: () => null,
      modules: ["coach/mirror-form/info"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/mirror-rooms/info")
      ]
    })
  },

  {
    path: "/mentor/hiring/:key",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/coach/hirings/hiring_info"),
      loading: () => null,
      modules: ["coach/hirings/hiring_info"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/hirings/hiring_info")
      ]
    })
  },
  {
    path: "/mentor/hiring_candidate/:key",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "mentor/candidates" */ "../../pages/coach/candidates/candidate_info"),
      loading: () => null,
      modules: ["mentor/candidates"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/candidates/candidate_info")
      ]
    })
  },
  {
    path: "/mentor/practice-tests",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "practice_tests" */ "../../pages/practice_tests"),
      loading: () => null,
      modules: ["practice_tests"],
      webpack: () => [require.resolveWeak("../../pages/practice_tests")]
    })
  },
  // {
  //   path:'/mentor/courses',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses" */ '../../pages/coach/courses'),
  //     loading: () => null,
  //     modules: ['coach/courses'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses')]
  //   }),
  // },
  // {
  //   path:'/mentor/create-course',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses_create_course" */ '../../pages/coach/courses/create-course'),
  //     loading: () => null,
  //     modules: ['coach/courses/create-course'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/create-course')]
  //   }),
  // },
  // {
  //   path:'/mentor/update-course/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses_create_course" */ '../../pages/coach/courses/course_update'),
  //     loading: () => null,
  //     modules: ['coach/courses/course_update'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/course_update')]
  //   }),
  // },
  // {
  //   path:'/mentor/course/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses_info" */ '../../pages/coach/courses/info'),
  //     loading: () => null,
  //     modules: ['coach/courses/info'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/info')]
  //   }),
  // },
  // {
  //   path:'/mentor/create-bundle',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses_info" */ '../../pages/coach/courses/bundles/create-bundle'),
  //     loading: () => null,
  //     modules: ['coach/courses/create-bundle'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/bundles/create-bundle')]
  //   }),
  // },
  // {
  //   path:'/mentor/course_bundle/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "coach_courses" */ '../../pages/coach/courses/bundles/info'),
  //     loading: () => null,
  //     modules: ['coach/courses_bundleinfo'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/bundles/info')]
  //   }),
  // },{
  //   path:'/mentor/update-course-bundle/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "editcoach_courses" */ '../../pages/coach/courses/bundles/edit'),
  //     loading: () => null,
  //     modules: ['coach/editcourses_bundleinfo'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/bundles/edit')]
  //   }),
  // },{
  //   path:'/mentor/assigned-courses',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "editcoach_courses" */ '../../pages/coach/courses/assigned/assigned-courses'),
  //     loading: () => null,
  //     modules: ['coach/assigned-courses'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/assigned/assigned-courses')]
  //   }),
  // },{
  //   path:'/mentor/assigned-course/:key',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "editcoach_courses" */ '../../pages/coach/courses/assigned/course_info'),
  //     loading: () => null,
  //     modules: ['coach/assigned-course-info'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/assigned/course_info')]
  //   }),
  // },{
  //   path:'/mentor/assigned-course/:key/:itemkey',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import(/* webpackChunkName: "editcoach_courses" */ '../../pages/coach/courses/assigned/item_info'),
  //     loading: () => null,
  //     modules: ['coach/assigned-course-item_info'],
  //     webpack: () => [require.resolveWeak('../../pages/coach/courses/assigned/item_info')]
  //   }),
  // },
  {
    path: "/mentor/practice/info/:room_id",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/coach/practice/mirror_room_info"),
      loading: () => null,
      modules: ["coach-mirror-room-info"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/practice/mirror_room_info")
      ]
    })
  },
  {
    path: "/mentor/practice/:room_key",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/coach/practice/room-video"),
      loading: () => null,
      modules: ["coach-mirror-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/coach/practice/room-video")
      ]
    })
  },
  {
    path: "/mentor/trainings",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/coach/trainings"),
      loading: () => null,
      modules: ["coach-mirror-rooms"],
      webpack: () => [require.resolveWeak("../../pages/coach/trainings")]
    })
  }
];

const Candidate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "candidate" */ "../../pages/candidate/index"),
  loading: () => null,
  modules: ["candidate"],
  webpack: () => [require.resolveWeak("../../pages/candidate/index")]
});
var companyRoutes = [
  {
    path: "/company/widget",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/widget"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [require.resolveWeak("../../pages/company/widget")]
    })
  },
  {
    path: "/company/dashboard",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/dashboard/"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [require.resolveWeak("../../pages/company/dashboard/")]
    })
  },
  {
    path: "/company/departments",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/departments"),
      loading: () => null,
      modules: ["company/departments"],
      webpack: () => [require.resolveWeak("../../pages/company/departments")]
    })
  },
  {
    path: "/company/department/import",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/coach_import"),
      loading: () => null,
      modules: ["company/department_import"],
      webpack: () => [require.resolveWeak("../../pages/company/coach_import")]
    })
  },
  {
    path: "/company/hirings",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings"),
      loading: () => null,
      modules: ["company/hirings"],
      webpack: () => [require.resolveWeak("../../pages/company/hirings")]
    })
  },
  {
    path: "/company/hiring_info/:key",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings/hiring_info"),
      loading: () => null,
      modules: ["company/hirings/hiring_info"],
      webpack: () => [
        require.resolveWeak("../../pages/company/hirings/hiring_info")
      ]
    })
  },
  {
    path: "/company/hirings/create-hirings",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings/hiringForm"),
      loading: () => null,
      modules: ["company/hirings"],
      webpack: () => [
        require.resolveWeak("../../pages/company/hirings/hiringForm")
      ]
    })
  },
  {
    path: "/company/hirings/create-hirings/:key",
    component: Loadable({
      loader: () => import("../../pages/company/hirings/hiringForm"),
      loading: () => null,
      modules: ["company/hirings"],
      webpack: () => [
        require.resolveWeak("../../pages/company/hirings/hiringForm")
      ]
    })
  },
  {
    path: "/company/meetings",
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "members" */ "../../pages/company/meetings"),
      loading: () => null,
      modules: ["company/meetings"],
      webpack: () => [require.resolveWeak("../../pages/company/meetings")]
    })
  },
  {
    path: "/company/placement/form",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings/hiringForm"),
      loading: () => null,
      modules: ["company/hirings"],
      webpack: () => [
        require.resolveWeak("../../pages/company/hirings/hiringForm")
      ]
    })
  },
  {
    path: "/company/mapping",
    component: Loadable({
      loader: () => import("../../pages/company/company-mapping"),
      loading: () => null,
      modules: ["company/mapping"],
      webpack: () => [
        require.resolveWeak("../../pages/company/company-mapping")
      ]
    })
  },
  {
    path: "/company/mapping-form",
    component: Loadable({
      loader: () => import("../../pages/company/mappingForm"),
      loading: () => null,
      modules: ["company/mapping"],
      webpack: () => [require.resolveWeak("../../pages/company/mappingForm")]
    })
  },
  {
    path: "/company/prejoin/candidates",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/prejoining/candidates"),
      loading: () => null,
      modules: ["company/prejoining/candidates"],
      webpack: () => [
        require.resolveWeak("../../pages/company/prejoining/candidates")
      ]
    })
  },
  {
    path: "/company/onboarding/steps",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/company/onboarding"),
      loading: () => null,
      modules: ["coach/company/onboarding"],
      webpack: () => [require.resolveWeak("../../pages/company/onboarding")]
    })
  },
  {
    path: "/company/onboarding/form/:id",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/company/onboarding/form/form_info"),
      loading: () => null,
      modules: ["coach/company/onboarding/form_info"],
      webpack: () => [
        require.resolveWeak("../../pages/company/onboarding/form/form_info")
      ]
    })
  },
  {
    path: "/company/onboarding/forms",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/company/onboarding/form/forms"),
      loading: () => null,
      modules: ["coach/company/onboarding/forms"],
      webpack: () => [
        require.resolveWeak("../../pages/company/onboarding/form/forms")
      ]
    })
  },
  {
    path: "/company/onboarding/documents",
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "coach_class_info" */ "../../pages/company/documents"),
      loading: () => null,
      modules: ["company/prejoining/documents"],
      webpack: () => [require.resolveWeak("../../pages/company/documents")]
    })
  },
  {
    path: "/company/onboarding/candidates",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/onboarding/candidates"),
      loading: () => null,
      modules: ["company/onboarding/candidates"],
      webpack: () => [
        require.resolveWeak("../../pages/company/onboarding/candidates")
      ]
    })
  },
  {
    path: "/company/create-test",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/test/create-new"),
      loading: () => null,
      modules: ["coach/create-new"],
      webpack: () => [
        require.resolveWeak("../../pages/company/test/create-new")
      ]
    })
  },
  {
    path: "/company/create-test/:key",
    component: Loadable({
      loader: () => import("../../pages/company/test/test_form"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/company/test/test_form")]
    })
  },
  {
    path: "/company/tests",
    component: Loadable({
      loader: () => import("../../pages/company/tests"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/company/tests")]
    })
  },
  {
    path: "/company/test/:key/edit",
    component: Loadable({
      loader: () => import("../../pages/company/test/test_edit"),
      loading: () => null,
      modules: ["coach/test_edit"],
      webpack: () => [require.resolveWeak("../../pages/company/test/test_edit")]
    })
  },
  {
    path: "/company/test/:key",
    component: Loadable({
      loader: () => import("../../pages/company/test/test_info"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/company/test/test_info")]
    })
  },
  {
    path: "/company/profile",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/profile"),
      loading: () => null,
      modules: ["coach/test"],
      webpack: () => [require.resolveWeak("../../pages/company/profile")]
    })
  },
  {
    path: "/company/managers",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/managers"),
      loading: () => null,
      modules: ["company/managers"],
      webpack: () => [require.resolveWeak("../../pages/company/managers")]
    })
  },
  {
    path: "/company/staffs",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/managers"),
      loading: () => null,
      modules: ["company/staff"],
      webpack: () => [require.resolveWeak("../../pages/company/managers")]
    })
  },
  {
    path: "/company/mirror-rooms",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/mirror-rooms"),
      loading: () => null,
      modules: ["company/mirror-rooms"],
      webpack: () => [require.resolveWeak("../../pages/company/mirror-rooms")]
    })
  },
  {
    path: "/company/create-mirror-room",
    exact: false,
    component: Loadable({
      loader: () => import("../../pages/company/mirror-rooms/form"),
      loading: () => null,
      modules: ["company/mirror-rooms"],
      webpack: () => [
        require.resolveWeak("../../pages/company/mirror-rooms/form")
      ]
    })
  },
  {
    path: "/company/candidates",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/candidates"),
      loading: () => null,
      modules: ["company/candidates"],
      webpack: () => [require.resolveWeak("../../pages/company/candidates")]
    })
  },
  {
    path: "/company/candidate/:key",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/candidates/candidate_info"),
      loading: () => null,
      modules: ["company/candidates"],
      webpack: () => [
        require.resolveWeak("../../pages/company/candidates/candidate_info")
      ]
    })
  },
  {
    path: "/company/events",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/events"),
      loading: () => null,
      modules: ["company/events"],
      webpack: () => [require.resolveWeak("../../pages/company/events")]
    })
  },
  {
    path: "/company/job_board",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/job_board"),
      loading: () => null,
      modules: ["company/job_board"],
      webpack: () => [require.resolveWeak("../../pages/company/job_board")]
    })
  },
  {
    path: "/company/messages",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/messages"),
      loading: () => null,
      modules: ["company/messages"],
      webpack: () => [require.resolveWeak("../../pages/company/messages")]
    })
  },
  {
    path: "/company/candidate/:candidate_key/:hiring_key/:room_key",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/candidates/c_mirror_room_info"),
      loading: () => null,
      modules: ["company/hiring_room_info"],
      webpack: () => [
        require.resolveWeak("../../pages/company/candidates/c_mirror_room_info")
      ]
    })
  },
  {
    path: "/company/rooms",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/rooms"),
      loading: () => null,
      modules: ["company/rooms"],
      webpack: () => [require.resolveWeak("../../pages/company/rooms")]
    })
  },
  {
    path: "/company/hiring-leaderboard",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings/leaderboard"),
      loading: () => null,
      modules: ["company/hiring-leaderboard"],
      webpack: () => [
        require.resolveWeak("../../pages/company/hirings/leaderboard")
      ]
    })
  },
  {
    path: "/company/practice-tests",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/practice_tests"),
      loading: () => null,
      modules: ["practice_tests"],
      webpack: () => [require.resolveWeak("../../pages/practice_tests")]
    })
  },
  {
    path: "/company/hiring/:code/update",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/hirings/update"),
      loading: () => null,
      modules: ["hiring_update"],
      webpack: () => [require.resolveWeak("../../pages/company/hirings/update")]
    })
  },
  // {
  //   path:'/company/courses',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/company/courses'),
  //     loading: () => null,
  //     modules: ['courses'],
  //     webpack: () => [require.resolveWeak('../../pages/company/courses')]
  //   }),
  // },
  // {
  //   path:'/company/create-course',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/company/courses/create-course'),
  //     loading: () => null,
  //     modules: ['create_course'],
  //     webpack: () => [require.resolveWeak('../../pages/company/courses/create-course')]
  //   }),
  // },
  // {
  //   path:'/company/course/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/company/courses/info'),
  //     loading: () => null,
  //     modules: ['create_course'],
  //     webpack: () => [require.resolveWeak('../../pages/company/courses/info')]
  //   }),
  // },
  // {
  //   path:'/company/update-course/:id',
  //   exact:true,
  //   component :Loadable({
  //     loader: () => import('../../pages/company/courses/course_update'),
  //     loading: () => null,
  //     modules: ['create_course'],
  //     webpack: () => [require.resolveWeak('../../pages/company/courses/course_update')]
  //   }),
  // },
  {
    path: "/company/videos",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/video-creation"),
      loading: () => null,
      modules: ["video_list"],
      webpack: () => [require.resolveWeak("../../pages/company/video-creation")]
    })
  },
  {
    path: "/company/video-creation/form",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/video-creation/form"),
      loading: () => null,
      modules: ["video_form"],
      webpack: () => [
        require.resolveWeak("../../pages/company/video-creation/form")
      ]
    })
  },
  {
    path: "/company/trainings",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/trainings"),
      loading: () => null,
      modules: ["trainings"],
      webpack: () => [require.resolveWeak("../../pages/company/trainings")]
    })
  },
  {
    path: "/company/training/:key",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/trainings/training_info"),
      loading: () => null,
      modules: ["training_info"],
      webpack: () => [
        require.resolveWeak("../../pages/company/trainings/training_info")
      ]
    })
  },
  {
    path: "/company/create-training",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/trainings/form"),
      loading: () => null,
      modules: ["training_form"],
      webpack: () => [require.resolveWeak("../../pages/company/trainings/form")]
    })
  },
  {
    path: "/company/candidates/reports",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/candidates/reports"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [
        require.resolveWeak("../../pages/company/candidates/reports")
      ]
    })
  },
  {
    path: "/company/resume",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/resume"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [require.resolveWeak("../../pages/company/resume")]
    })
  },
  {
    path: "/company/cover-letter",
    exact: true,
    component: Loadable({
      loader: () => import("../../pages/company/cover-letter"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [require.resolveWeak("../../pages/company/cover-letter")]
    })
  },
  {
    path: "/company/candidate_room/:room_key",
    exact: true,
    component: Loadable({
      loader: () =>
        import("../../pages/company/candidates/candidate_mirror_room_info"),
      loading: () => null,
      modules: ["company"],
      webpack: () => [
        require.resolveWeak(
          "../../pages/company/candidates/candidate_mirror_room_info"
        )
      ]
    })
  }
];

// console.log(allRoutes);
const CandidateRoute = ({
  component: Component,
  candidate,
  authenticated,
  token,
  ...rest
}) => {
  var { location } = { ...rest };

  return authenticated && !candidate ? (
    <div>Loading Candidate...</div>
  ) : (
    <Route
      {...rest}
      render={props =>
        token ? (
          !candidate.salt ? (
            <Redirect
              to={{
                pathname: `/update-password`,
                state: { from: props.location }
              }}
            />
          ) : candidate.country_id ? (
            // && candidate.goal && candidate.degree && candidate.collage && candidate.experience?
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/update-details",
                state: { from: props.location }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              search: `${
                location && location.pathname
                  ? "?ref=" + encodeURIComponent(location.pathname)
                  : ""
              }`,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const CoachRoute = ({
  component: Component,
  coach,
  authenticated,
  token,
  ...rest
}) =>
  authenticated && !coach ? (
    <div>Loading mentor...</div>
  ) : (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );

const CompanyRoute = ({
  component: Component,
  authenticated,
  company,
  token,
  ...rest
}) => {
  var curr_route = [];
  if (company) {
    curr_route = company.routes.filter(o => o.page == rest.path);
  }
  return authenticated && !company ? (
    <CompanyLoader text="Fetching data" show={true} />
  ) : curr_route && curr_route[0] ? (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} authed={true} routeData={curr_route[0]} />
        ) : (
          <Redirect
            to={{
              pathname: "/company-login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  ) : (
    <Route {...rest} render={props => <NotFound permission_deny={true} />} />
  );
};

function App(props) {
  // console.log('/Routes/index.j==>Line 131',props);
  const {
    token,
    coach,
    authenticated,
    candidate,
    company,
    company_domain
  } = props;

  return (
    <ScrollToTop>
      <Switch>
        {allRoutes.map((route, i) => {
          var Component = route.component;
          // console.log(Component);
          return (
            <Route exact={route.exact} path={route.path}>
              <Component />
            </Route>
          );
        })}

        <CandidateRoute
          path="/candidate"
          token={token}
          authenticated={authenticated}
          component={Candidate}
          candidate={candidate}
        />

        {/* <CompanyRoute path="/company" token={token} component={CompanyRoutes} /> */}
        {coachRoutes.map((page, i) => {
          return (
            <CoachRoute
              key={i}
              path={page.path}
              authenticated={authenticated}
              exact={page.exact}
              token={token}
              coach={coach}
              component={page.component}
            />
          );
        })}
        {companyRoutes.map((page, i) => {
          return (
            <CompanyRoute
              key={i}
              path={page.path}
              authenticated={authenticated}
              exact={page.exact}
              token={token}
              component={page.component}
              company={company}
            />
          );
        })}
        <Route component={NotFound} />
      </Switch>
    </ScrollToTop>
  );
}

const mapStateToProp = state => {
  return {
    authenticated: state.Auth.authenticated,
    token: state.Auth.token,
    company: state.Auth.company,
    coach: state.Auth.coach,
    candidate: state.Auth.candidate,
    company_domain: state.Auth.company_domain
  };
};

export default withRouter(connect(mapStateToProp)(App));
