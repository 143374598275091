import React, {Fragment, useEffect} from "react";
import Loader from 'react-loader-advanced';
import {Loader as LoaderAnim} from 'react-loaders'
var spinner1 = <LoaderAnim color="#e03a3c" type="line-scale"/>;

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var { children,show,text } = this.props
        var message = <LoaderAnim color="#e03a3c" type="line-scale"/> 
        if(text){
            message = <div> <LoaderAnim color="#e03a3c" type="line-scale"/>  {text}</div>
        }
        return (
            <div style={{position: 'absolute',top: '50%',left: '37%'}}>
                <Loader message={message} show={show} priority={10} foregroundStyle={{color: 'white'}}
    backgroundStyle={{backgroundColor: 'rgb(255 255 255 / 50%)'}}>
                    {children}
                </Loader>
            </div>
        );
    }
}

export default Index;
